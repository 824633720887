import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { __ } from 'artsteps2-common';
import utils from '../../../utils';
import { compose, withState, withDispatch } from '../../../enhancers';
import { addMessage, setUIData } from '../../../actions';
import { getUIProperty, getAuthUser } from '../../../reducers';
import MyToolTip from '../../generic/MyToolTip';
import { Item } from '../../exhibitions/editor/ExhibitionEditStyled';
import EditItem from '../../exhibitions/editor/EditItem';

export const ArtifactView = ({
  artifact = {},
  placing = false,
  selected = false,
  disabled = false,
  controls = false,
  onPlaceArtifact = () => Promise.resolve(false),
  onStopPlacing = () => Promise.resolve(false),
  onEditorOpen = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
}) => {
  return (
    <Item
      selected={+placing}
      inactive={+disabled}
      draggable={!disabled}
      placing={+placing}
      onDragEnd={({ dataTransfer: { dropEffect = 'none' } = {} }) =>
        dropEffect !== 'none' && onPlaceArtifact()
      }
      onDoubleClick={() => controls && onEditorOpen()}
      onClick={() => (placing ? onStopPlacing() : onPlaceArtifact())}
      src={utils.artifact.getArtifactImage(artifact)}
    >
      {controls && <EditItem onEditorOpen={onEditorOpen} />}
    </Item>
  );
};

const mapState = (state, { exhibitionId, artifact = {} }) => {
  const placingArtifact = getUIProperty(state, `exhibitions/${exhibitionId}/placingArtifact`);
  const selectedArtifact = getUIProperty(state, `exhibitions/${exhibitionId}/selectedArtifact`);
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    placing: placingArtifact && (placingArtifact.artifactId || placingArtifact) === artifact._id,
    selected: selectedArtifact === artifact._id,
    disabled:
      (placingArtifact && (placingArtifact.artifactId || placingArtifact) !== artifact._id) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/painting`) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/placingCase`),
  };
};

const mapDispatch = (dispatch, { artifact = {}, exhibitionId, placing }) => ({
  onPlaceArtifact: () =>
    utils.exhibition.exportArtifact(artifact).then(exportedArtifact =>
      dispatch(
        setUIData(`exhibitions/${exhibitionId}`, {
          placingArtifact: exportedArtifact,
          placingCase: undefined,
        }),
      ),
    ),
  onStopPlacing: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingCase: undefined,
        placingArtifact: undefined,
      }),
    ),
  onEditorOpen: () =>
    dispatch(
      setUIData('artifacts', {
        editingArtifactId: artifact._id,
        editingArtifactType: artifact.type,
      }),
    ),
  onAddMessage: message => dispatch(addMessage(message, 'artifacts')),
});

const Artifact = compose(withState(mapState), withDispatch(mapDispatch))(ArtifactView);

export default Artifact;
