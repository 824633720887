import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Grid,
  withWidth,
  MenuItem,
  Select,
  InputLabel,
  Button,
  Dialog,
  Tooltip,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Dropzone from 'react-dropzone';
import publicConfig from 'artsteps2-config/public.json';

import { __ } from 'artsteps2-common';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getUIProperty,
  getAuthUser,
} from '../../../reducers';
import { compose, withLifecycle, withDispatch, withState } from '../../../enhancers';
import {
  apiPATCH,
  apiGET,
  setFormData,
  addMessage,
  clearMessages,
  setUIProperty,
} from '../../../actions';
import utils from '../../../utils';
import countryCodes from './countries.json';
import { StyledButton, StyledTextField, Text } from '../../../styles/GenericStyled';

const IMAGE_WIDTH = publicConfig.user.profile.image.imageWidth;
const IMAGE_HEIGHT = publicConfig.user.profile.image.imageHeight;
const IMAGE_QUALITY = publicConfig.user.profile.image.quality;

const FALLBACK_FILE_SIZE_LIMIT = publicConfig.uploader.fallBackUFileSizeLimit;
const fileSizeLimits = publicConfig.uploader.fileSizeLimit;

const useStyles = makeStyles({
  rootDiv: {
    margin: 'auto',
    padding: '20px',
    width: '60%',
    marginTop: '10px',
  },
  rootDivMobile: {
    marginTop: '10px',
    margin: 'auto',
    padding: '0',
    width: '90%',
  },
  grid: {
    flexGrow: 1,
  },
  input: {
    color: 'rgba(52, 58, 64, 0.5)',
    font: 'normal normal bold 18px/23px Muli',
    border: '2px solid #f3f3f3',
    height: '54px',
    padding: '0',
  },
  inputLabel: {
    font: 'normal normal bold 18px/3px Muli',
    color: '#343A40',
    paddingBottom: '15px',
  },
  avatar: {
    width: '161px',
    height: '161px',
    position: 'absolute',
    filter: 'grayscale(80%)',
  },
  photoIcon: {
    width: '52px',
    height: '52px',
    position: 'relative',
    zIndex: '4',
    color: 'white',
  },
  imageDiv: {
    paddingTop: '60px',
    paddingBottom: '60px',
    paddingLeft: '30px',
  },
  privateSpaceButton: {
    width: '100%',
    height: '74px',
  },
  dialogPaperPS: {
    minHeight: '200px',
    maxHeight: '200x',
    minWidth: '526px',
    maxWidth: '526px',
  },
  dialogPaperIM: {
    minHeight: '630px',
    maxHeight: '630px',
    minWidth: '526px',
    maxWidth: '526px',
  },
  dropzone: {
    width: '463px',
    height: '443px',
    margin: '0 auto',
    border: '6px solid #12AD8F',
  },
  dropzoneImage: {
    width: '451px',
    height: '431px',
  },
});

export const ProfileEditorView = ({
  isPrivateSpace,
  onUpdateUser = () => Promise.resolve({ response: { error: 'error' } }),
  onAddMessage = () => Promise.resolve(false),
  onFetchUser = () => Promise.resolve(false),
  onAddMessageConnectToSpace = () => Promise.resolve(false),
  onSetConnectToSpaceForm = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onClearMessagesConnectToSpace = () => Promise.resolve(false),
  onClose,
  currentUser,
  width,
}) => {
  const classes = useStyles();

  currentUser.profile.birthday = currentUser.profile.birthday
    ? currentUser.profile.birthday.split('T')[0]
    : '';

  const [data, setData] = React.useState(currentUser.profile);
  const [uploadPicOpen, setUploadPicOpen] = React.useState(false);
  const [registrationCodeProfile, setRegistrationCodeProfile] = React.useState('');
  const [disableSaveButton, setDisableSaveButton] = React.useState(true);

  React.useEffect(() => {
    setDisableSaveButton(JSON.stringify(data) === JSON.stringify(currentUser.profile));
  }, [currentUser.profile, data]);

  const handleFieldChange = e => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleRegistrationCodeChange = e => {
    setRegistrationCodeProfile(e.target.value);
  };

  const onSubmit = () => {
    if (!data.name) {
      return onAddMessage({
        type: 'error',
        title: __('the_name_field_is_empty'),
      });
    }

    return onUpdateUser({ profile: data }).then(({ response }) => {
      if (response.error) {
        return onAddMessage({ type: 'error', title: __(response.error) });
      }

      onClearMessages();
      onAddMessage({ type: 'success', title: __('update_profile_success') });
      return onClose && onClose();
    });
  };

  // for private spaces
  const onConnectFormSubmit = () => {
    if (!registrationCodeProfile) {
      onAddMessageConnectToSpace({
        type: 'error',
        title: 'please fill in registration code',
      });
      return;
    }

    // eslint-disable-next-line consistent-return
    return onUpdateUser({
      registrationCode: registrationCodeProfile,
    }).then(({ response }) => {
      if (response.error) {
        setRegistrationCodeProfile('');
        return onAddMessageConnectToSpace({ type: 'error', title: response.error });
      }

      onClearMessagesConnectToSpace();
      onSetConnectToSpaceForm({ registrationCodeProfile: '' });
      setRegistrationCodeProfile('');
      onFetchUser();
      return onAddMessageConnectToSpace({
        type: 'success',
        title: 'Successfully connected to space!',
      });
    });
  };

  // const getImageSrc = ({ uri, bin = {} }, index) => uri || bin.preview;

  const parseDataUri = (uri, filename, previewURI) =>
    utils.image
      .toDataURL({
        uri,
        height: IMAGE_HEIGHT,
        width: IMAGE_WIDTH,
        quality: IMAGE_QUALITY,
      })
      .then(dataUri => Promise.resolve(utils.buffer.fromDataURL(dataUri, filename, previewURI)));

  const parseFile = (...args) =>
    parseDataUri(...args).then(bin => {
      if (bin.name.match(/\.obj$/)) {
        const content = bin.content
          .replace(/[\r\n]+[a-z]{1}\s*[\r\n]+/g, '\n')
          .replace(/[\r\n]+([a-z]{1}){1}\s\s+/g, '\n$1 ');
        URL.revokeObjectURL(bin.preview);
        const blob = new Blob([content], { type: bin.contentType });
        const previewURI = URL.createObjectURL(blob);
        return Promise.resolve({ ...bin, content, preview: previewURI });
      } else if (bin.name.match(/\.mtl$/)) {
        const content = bin.content
          .replace(/map_Ka ([a-z]+(\\\\|\/))+/gi, 'map_Ka ')
          .replace(/map_Kd ([a-z]+(\\\\|\/))+/gi, 'map_Kd ')
          .replace(/map_Bump ([a-z]+(\\\\|\/))+/gi, 'map_Bump ');
        URL.revokeObjectURL(bin.preview);
        const blob = new Blob([content], { type: bin.contentType });
        const previewURI = URL.createObjectURL(blob);
        return Promise.resolve({ ...bin, content, preview: previewURI });
      }
      return Promise.resolve(bin);
    });

  const onDropFiles = (files = []) =>
    Promise.all(
      files.map(
        file =>
          new Promise(resolve => {
            const sizeLimit = fileSizeLimits.image || FALLBACK_FILE_SIZE_LIMIT;
            if (file.size && file.size < sizeLimit) {
              const reader = new FileReader();
              reader.onloadend = () => resolve(parseFile(reader.result, file.name, file.preview));
              reader.readAsDataURL(file);
            } else {
              onAddMessage({
                title: __('upload_size_limit_exceeded', {
                  filename: file.name,
                  fileSize: (file.size / 1024).toFixed(2),
                  sizeLimit: (sizeLimit / 1024).toFixed(2),
                }),
                type: 'warning',
              });
            }
          }),
      ),
    ).then(binaries => {
      setData({
        ...data,
        image: {
          bin: binaries[0],
          preview: binaries[0].preview,
        },
      });
    });

  return (
    <div>
      <div className={width !== 'sm' && width !== 'md' ? classes.rootDiv : classes.rootDivMobile}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Text type="h1">Edit Profile</Text>
          </Grid>
          <Grid item>
            <StyledButton
              background="#E6F7F4"
              mycolor="#00CA9F"
              style={{ marginLeft: 'auto', marginRight: '25px' }}
              onClick={() => onSubmit()}
              disabled={disableSaveButton}
            >
              Save Changes
              <CheckIcon style={{ color: '#00CA9F' }} fontSize="large" />
            </StyledButton>
            <StyledButton
              background="#F3F3F3"
              mycolor="#000000"
              style={{ marginLeft: 'auto', marginRight: '25px' }}
              onClick={() => onClose()}
            >
              Cancel
              <CloseIcon style={{ color: '#000000' }} fontSize="large" />
            </StyledButton>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          alignItems="flex-start"
          spacing={4}
          className={classes.grid}
        >
          <Grid item md={6} xs={12}>
            <Tooltip title="Click to edit profile picture" placement="left" arrow>
              <div className={classes.imageDiv}>
                <Button onClick={() => setUploadPicOpen(true)}>
                  <AddAPhotoIcon className={classes.photoIcon} />
                  <Avatar alt={__('avatar')} src={data.image.preview} className={classes.avatar} />
                </Button>
              </div>
            </Tooltip>
          </Grid>
          <Grid item md={6} xs={12} />

          <Grid item md={4} xs={12}>
            <StyledTextField
              fullWidth
              id="name"
              name="name"
              label="Your name: "
              value={data.name}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
                style: {
                  font: 'normal normal bold 24px/3px Muli',
                  color: '#343A40',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'rgba(52, 58, 64, 0.5)',
                  font: 'normal normal bold 18px/23px Muli',
                  border: '2px solid #f3f3f3',
                  height: '58px',
                  paddingLeft: '20px',
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <StyledTextField
              fullWidth
              placeholder="B"
              type="date"
              id="birthday"
              name="birthday"
              label="Date of birth: "
              value={data.birthday}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
                style: {
                  font: 'normal normal bold 24px/3px Muli',
                  color: '#343A40',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'rgba(52, 58, 64, 0.5)',
                  font: 'normal normal bold 18px/23px Muli',
                  border: '2px solid #f3f3f3',
                  height: '58px',
                  paddingLeft: '20px',
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <StyledTextField
              fullWidth
              id="website"
              name="website"
              label="Your website: "
              value={data.website}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
                style: {
                  font: 'normal normal bold 24px/3px Muli',
                  color: '#343A40',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'rgba(52, 58, 64, 0.5)',
                  font: 'normal normal bold 18px/23px Muli',
                  border: '2px solid #f3f3f3',
                  height: '58px',
                  paddingLeft: '20px',
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <StyledTextField
              fullWidth
              id="city"
              name="city"
              label="City: "
              value={data.city}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
                style: {
                  font: 'normal normal bold 24px/3px Muli',
                  color: '#343A40',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'rgba(52, 58, 64, 0.5)',
                  font: 'normal normal bold 18px/23px Muli',
                  border: '2px solid #f3f3f3',
                  height: '58px',
                  paddingLeft: '20px',
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <InputLabel id="country" className={classes.inputLabel}>
              Your Country:{' '}
            </InputLabel>
            <Select
              className={classes.input}
              fullWidth
              id="country"
              name="country"
              label="Your Country: "
              value={data.country}
              onChange={handleFieldChange}
              SelectDisplayProps={{
                disableUnderline: true,
                style: {
                  color: 'rgba(52, 58, 64, 0.5)',
                  font: 'normal normal bold 18px/23px Muli',
                  paddingLeft: '20px',
                },
              }}
            >
              {countryCodes.map(code => (
                <MenuItem value={code}>{__(`regions.country_${code}`)}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={4} xs={12} />
          <Grid item md={8} xs={12}>
            <StyledTextField
              fullWidth
              id="occupation"
              name="occupation"
              label="Occupation/Business sector:"
              value={data.occupation}
              onChange={handleFieldChange}
              InputLabelProps={{
                shrink: true,
                style: {
                  font: 'normal normal bold 24px/3px Muli',
                  color: '#343A40',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'rgba(52, 58, 64, 0.5)',
                  font: 'normal normal bold 18px/23px Muli',
                  border: '2px solid #f3f3f3',
                  height: '58px',
                  paddingLeft: '20px',
                },
              }}
            />
          </Grid>
          <Grid item md={4} xs={12} />
          <Grid item md={8} xs={12}>
            <StyledTextField
              multiline
              fullWidth
              rows={4}
              rowsMax={4}
              id="bio"
              name="bio"
              label="Bio: "
              value={data.bio}
              onChange={handleFieldChange}
              helperText={data.bio ? data.bio.length : 0}
              FormHelperTextProps={{
                style: {
                  marginLeft: 'auto',
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  font: 'normal normal bold 24px/3px Muli',
                  color: '#343A40',
                },
              }}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: 'rgba(52, 58, 64, 0.5)',
                  font: 'normal normal bold 18px/23px Muli',
                  border: '2px solid #f3f3f3',
                  height: '131px',
                  padding: '10px',
                  overflowWrap: 'normal',
                  paddingLeft: '20px',
                },
              }}
            />
          </Grid>
          {!isPrivateSpace ? (
            <Grid item md={4} xs={12}>
              <StyledTextField
                fullWidth
                type="password"
                id="registrationCodeProfile"
                name="registrationCodeProfile"
                label="Private Spaces:"
                placeholder="Enter invitation code"
                value={registrationCodeProfile}
                onChange={handleRegistrationCodeChange}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    font: 'normal normal bold 24px/3px Muli',
                    color: '#343A40',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {registrationCodeProfile.length > 0 && (
                        <IconButton onClick={onConnectFormSubmit}>
                          <CheckIcon style={{ color: '#030303' }} fontSize="large" />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                  style: {
                    color: 'rgba(52, 58, 64, 0.5)',
                    font: 'normal normal bold 18px/23px Muli',
                    border: '2px solid #f3f3f3',
                    height: '58px',
                    paddingLeft: '20px',
                  },
                }}
              />
              {currentUser && currentUser.spaceDomains && currentUser.spaceDomains.length !== 0 && (
                <>
                  {currentUser.spaceDomains.map((spaceDomain, index) => (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      key={index}
                      href={`${window.location.protocol}//${
                        spaceDomain.subdomain
                      }.${window.location.hostname
                        .replace('www2', '')
                        .replace('www', '')
                        .replace('.', '')}`}
                    >
                      <StyledButton
                        fullWidth
                        background="rgba(243, 243, 243, 1)"
                        mycolor="black"
                        style={{ font: 'normal normal 18px/23px Muli', marginTop: '20px' }}
                      >
                        {`${spaceDomain.subdomain}.${window.location.hostname
                          .replace('www2', '')
                          .replace('www', '')
                          .replace('.', '')}`}
                      </StyledButton>
                    </a>
                  ))}
                </>
              )}
            </Grid>
          ) : (
            <Grid item md={4} xs={12} />
          )}
        </Grid>
      </div>
      {/* Upload profile picture dialog */}
      <Dialog
        onClose={() => setUploadPicOpen(false)}
        aria-labelledby="upload-Dialog"
        open={uploadPicOpen}
        classes={{ paper: classes.dialogPaperIM }}
      >
        <br />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ width: '90%', margin: '0 auto' }}
        >
          <Grid item>
            <Text type="h4">Edit Picture</Text>
          </Grid>
          <Grid item>
            <Button onClick={() => setUploadPicOpen(false)}>
              <CloseIcon fontSize="large" />
            </Button>
          </Grid>
        </Grid>
        <br />
        <Tooltip title="Click or drag picture" placement="left" arrow>
          <Dropzone
            id="drop"
            name="image"
            onDrop={onDropFiles}
            multiple={false}
            accept="image/*"
            className={classes.dropzone}
          >
            {() => (
              <div>
                <img
                  alt={__('avatar')}
                  src={data.image.preview}
                  className={classes.dropzoneImage}
                />
              </div>
            )}
          </Dropzone>
        </Tooltip>
        <br />
        <StyledButton
          background="rgba(243, 243, 243, 1)"
          mycolor="black"
          style={{ marginLeft: 'auto', marginRight: '25px' }}
          onClick={() => setUploadPicOpen(false)}
        >
          OK
        </StyledButton>
      </Dialog>
    </div>
  );
};

const mapState = (state, { userId }) => ({
  ready: getApiStatus(state, `users/${userId}`) === API_STATUS.IDLE,
  user: getApiResource(state, `users/${userId}`),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  currentUser: getAuthUser(state),
});

const mapDispatch = (dispatch, { userId }) => ({
  onFetchUser: () => dispatch(apiGET(`users/${userId}`)),
  onSetForm: data => dispatch(setFormData(`profile:${userId}`, data)),
  onSetConnectToSpaceForm: data => dispatch(setFormData('connectToSpace', data)),
  onUpdateUser: data => dispatch(apiPATCH(`users/${userId}`, data)),
  onAddMessage: message => dispatch(addMessage(message, 'profile')),
  onAddMessageConnectToSpace: message => dispatch(addMessage(message, 'connectToSpace')),
  onClearMessagesConnectToSpace: () => dispatch(clearMessages('connectToSpace')),
  onClearMessages: () => dispatch(clearMessages('profile')),
  onStopEditing: () => dispatch(setUIProperty(`profiles/${userId}/editing`, false)),
});

const onInitialization = ({ onFetchUser, onSetForm }) =>
  onFetchUser().then(({ response }) => onSetForm(response.profile));

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: ({ userId }, props) => userId !== props.userId && onInitialization(),
};

const ProfileEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
  withWidth(),
)(ProfileEditorView);

export default ProfileEditor;
