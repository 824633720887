"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.i18n = exports.utils = exports["default"] = exports.__ = void 0;

var utils = _interopRequireWildcard(require("./utils"));

exports.utils = utils;

var i18n = _interopRequireWildcard(require("./i18n"));

exports.i18n = i18n;
var __ = i18n.__;
exports.__ = __;
var _default = i18n.i18next;
exports["default"] = _default;