import ReactPixel from 'react-facebook-pixel';
import publicConfig from 'artsteps2-config/public.json';
import { ToastContainer } from 'react-toastify';
import React from 'react';
import { Route, Switch } from 'react-router';
import { restoreAuth, restoreSettings, setUIProperty, apiGET } from './actions';
import { getLocation, getUIProperty, getApiResource } from '../src/reducers';
import { compose, withState, withDispatch, withLifecycle } from './enhancers';
import MessageList from './components/generic/MessageList';
import HomePage from './pages/HomePage';
import TourPage from './pages/TourPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegistrationPage';
import LogoutPage from './pages/LogoutPage';
import ProfilePage from './pages/ProfilePage';
import ViewReportsPage from './pages/ViewReportsPage';
import ContactPage from './pages/ContactPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ExhibitionListPage from './pages/ExhibitionListPage';
import ExhibitionViewPage from './pages/ExhibitionViewPage';
import PrivateSpacesPage from './pages/PrivateSpacesPage';
import ExhibitionAccessibilityPage from './pages/ExhibitionAccessibilityPage';
import ExhibitionEmbedPage from './pages/ExhibitionEmbedPage';
import ExhibitionCuratePage from './pages/ExhibitionCuratePage';
import PasswordResetPage from './pages/PasswordResetPage';
import PasswordRecoveryPage from './pages/PasswordRecoveryPage';
import PrivacySettingsPage from './pages/PrivacySettingsPage';
import ArticleCategoriesPage from './pages/ArticleCategoriesPage';
import ArticlePage from './pages/ArticlePage';
import ChatbotPage from './pages/ChatbotPage';
import UnsubscriptionPage from './pages/UnsubscriptionPage';
import NotFoundPage from './pages/NotFoundPage';
import DonateCompletedPage from './pages/DonateCompletedPage';
import WelcomePage from './pages/WelcomePage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import EmailVerificationRecoveryPage from './pages/EmailVerificationRecoveryPage';
import StyledComponentsPage from './pages/StyledComponentsPage';
import { setCookie, getCookie } from './utils/cookies';
import CreatePrivateSpace from './pages/CreatePrivateSpace';
import Faq from './pages/FaqPage';
import LoginModal from './components/modals/LoginModal';
import RegisterModal from './components/modals/RegisterModal';
import CustomSpace3DPage from './pages/CustomSpace3DPage';
import VirtualEvents from './pages/VirtualEvents';

export const AppView = ({ location, setOpenRegister }) => (
  <div className="app-root">
    <Switch location={location}>
      <Route path="/" exact render={() => <HomePage />} />
      <Route path="/viewReports" exact render={() => <ViewReportsPage />} />
      <Route path="/private-space-admin" exact render={() => <CreatePrivateSpace />} />
      <Route path="/tour" exact render={() => <TourPage setOpenRegister={setOpenRegister} />} />
      <Route path="/generic-styles" exact render={() => <StyledComponentsPage />} />
      <Route path="/donatecompleted" exact render={() => <DonateCompletedPage />} />
      <Route path="/verification-recovery" exact render={() => <EmailVerificationRecoveryPage />} />
      <Route
        path="/welcome/:userId"
        exact
        render={({
          match: {
            params: { userId },
          },
        }) => <WelcomePage userId={userId} />}
      />
      <Route
        path="/verify-email/:userId/:verificationToken"
        render={({
          match: {
            params: { userId, verificationToken },
          },
        }) => <EmailVerificationPage userId={userId} verificationToken={verificationToken} />}
      />
      <Route path="/login" render={() => <LoginPage />} />
      <Route path="/register" render={() => <RegisterPage />} />
      <Route path="/logout" render={() => <LogoutPage />} />
      <Route path="/privatespaces" render={() => <PrivateSpacesPage />} />
      <Route path="/custom-space-3d" render={() => <CustomSpace3DPage />} />
      <Route path="/virtual-events" render={() => <VirtualEvents />} />
      <Route path="/contact" render={() => <ContactPage />} />
      <Route path="/faq" render={() => <Faq />} />
      <Route
        path="/profile/:userId/:tab?"
        render={({
          match: {
            params: { userId, tab },
          },
        }) => <ProfilePage userId={userId} tab={tab} />}
      />
      <Route path="/change-password" render={() => <ChangePasswordPage />} />
      <Route
        path="/explore/:orderby?"
        render={({
          match: {
            params: { orderby },
          },
        }) => <ExhibitionListPage orderby={orderby} query={location.query} />}
      />
      <Route
        path="/view/:exhibitionId"
        render={({
          match: {
            params: { exhibitionId },
          },
        }) => <ExhibitionViewPage query={location.query} exhibitionId={exhibitionId} />}
      />
      <Route
        path="/embed/:exhibitionId/:width?/:height?"
        render={({
          match: {
            params: { exhibitionId, width, height },
          },
        }) => (
          <ExhibitionEmbedPage
            query={location.query}
            exhibitionId={exhibitionId}
            width={width}
            height={height}
          />
        )}
      />
      <Route
        path="/accessible-view/:exhibitionId"
        render={({
          match: {
            params: { exhibitionId },
          },
        }) => <ExhibitionAccessibilityPage exhibitionId={exhibitionId} />}
      />
      <Route
        path="/curate/:exhibitionId/:step?"
        render={({
          match: {
            params: { exhibitionId, step },
          },
        }) => <ExhibitionCuratePage exhibitionId={exhibitionId} step={step} />}
      />
      <Route path="/reset-password" render={() => <PasswordResetPage />} />
      <Route
        path="/recover-password/:userId/:token"
        render={({
          match: {
            params: { token, userId },
          },
        }) => <PasswordRecoveryPage userId={userId} token={token} query={location.query} />}
      />
      <Route path="/privacy-settings" render={() => <PrivacySettingsPage />} />
      <Route
        path="/embed/:exhibitionId/:width?/:height?"
        render={({
          match: {
            params: { width, height, exhibitionId },
          },
        }) => <ExhibitionEmbedPage width={width} height={height} exhibitionId={exhibitionId} />}
      />
      <Route
        path="/article/:articleId"
        render={({
          match: {
            params: { articleId },
          },
        }) => <ArticlePage articleId={articleId} />}
      />
      <Route
        path="/categories/:categoryId?"
        render={({
          match: {
            params: { categoryId },
          },
        }) => <ArticleCategoriesPage categoryId={categoryId} />}
      />
      <Route
        path="/unsubscribe/:subscriptionId"
        render={({
          match: {
            params: { subscriptionId },
          },
        }) => <UnsubscriptionPage subscriptionId={subscriptionId} query={location.query} />}
      />
      <Route path="/chatbot" render={() => <ChatbotPage />} />
      <Route render={() => <NotFoundPage />} />
    </Switch>
    <LoginModal />
    <RegisterModal />
    <ToastContainer />
    <MessageList />
  </div>
);

const mapState = state => ({
  location: getLocation(state),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  spaceName: getUIProperty(state, 'spaceName'),
});

const setVisitsCookie = () => {
  const visits = getCookie('numberOfVisits');
  if (!visits) {
    return setCookie('numberOfVisits', '1', 30);
  }
  return setCookie('numberOfVisits', (parseInt(visits, 10) + 1).toString(), 30);
};

const mapDispatch = dispatch => ({
  setSpaceName: () => setUIProperty('spaceName', window.location.hostname.split('.')[0]),
  getSpace: () => {
    dispatch(apiGET(`spaces/${window.location.hostname.split('.')[0]}`));
  },
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  setIsPrivateSpace: () => {
    const urlParts = window.location.hostname.split('.');
    const spaceName = urlParts[0];

    const isIt = !publicConfig.spaceNames.includes(spaceName);

    dispatch(setUIProperty('isPrivateSpace', isIt));

    if (isIt) dispatch(apiGET(`spaces/${spaceName}`));
  },
  onRestoreAuth: () => dispatch(restoreAuth()),
  onRestoreSettings: () => {
    dispatch(restoreSettings());
    setVisitsCookie();
  },

  setNotifications: () => {
    dispatch(setUIProperty('unverifiedNotificationOpen', true));
  },
  setUIstatus: () => {
    dispatch(setUIProperty('contributionModalOpen', false));
    dispatch(setUIProperty('contributionBannerInteraction', false));
  },
});

const lifecycleMap = {
  onWillMount: ({
    onRestoreAuth,
    onRestoreSettings,
    setIsPrivateSpace,
    setNotifications,
    setUIstatus,
  }) => {
    Promise.all([
      onRestoreAuth(),
      onRestoreSettings(),
      setIsPrivateSpace(),
      setNotifications(),
      setUIstatus(),
    ]);
  },
  onDidUpdate: ({ location }, props) => {
    // remove cookie script class for locations != /article/privacy-policy

    if (
      props.location &&
      props.location.pathname &&
      props.location.pathname.indexOf('privacy-policy') === -1
    ) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('show_cookie_settings');
    }
    location.pathname !== props.location.pathname && window.scrollTo(0, 0);
  },
  onDidMount: ({ getSpace, isPrivateSpace, setSpaceName }) => {
    isPrivateSpace && getSpace();
    isPrivateSpace && setSpaceName();
    ReactPixel.init(publicConfig.facebook_pixel.pixel_id);
    ReactPixel.track('PageView');
  },
};

const App = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(AppView);

export default App;
