import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import { __ } from 'artsteps2-common';
import { StyledPaper } from '../styles/PrivateSpacesPageStyled';
import PrivateSpaceEditor from '../components/artifacts/forms/PrivateSpaceEditor';
import { getForm, getUIProperty, getAuthUser, getAuthUserRoles, getApiResource } from '../reducers';
import { withState, withDispatch, compose, withLifecycle } from '../enhancers';
import { apiPOST, setFormData, addMessage, apiGET } from '../actions';
import DefaultLayout from '../components/layouts/DefaultLayout';
import { Text } from '../styles/GenericStyled';
import PScard from '../components/privateSpaces/PScard';

const formName = 'newSpace';

export const CreatePrivateSpaceView = ({
  form = { data: {} },
  ready = true,
  onClearFormData,
  onSave,
  isNew,
  onRemove,
  isPrivateSpace,
  currentUser,
  onUpsert,
  isAdmin,
  onAddMessage = () => Promise.resolve(false),
  spaces = [],
}) => {
  const [openCreate, setOpenCreate] = useState(false);

  const handleClose = () => {
    setOpenCreate(false);
  };

  const mapFormData = data => ({
    ...data,
    files:
      data.files &&
      data.files.map(file => ({
        bin: file.bin && file.bin.content && file.bin,
        uri: file.uri,
      })),
    covers:
      data.covers &&
      data.covers.map(cover => ({
        bin: cover.bin.content && cover.bin.content && cover.bin,
        uri: cover.uri,
      })),
    image: (data.files && data.files.length > 0 && data.files[0].image) || data.image,
    settings: {
      allowLinks: data.allowLinks,
      showTour: data.showTour,
      showArtstepsRedirect: data.showArtstepsRedirect,
      showExhibitions: data.showExhibitions,
    },
  });

  const onSubmit = () =>
    onUpsert(mapFormData(form.data)).then(({ response }) =>
      response.error
        ? onAddMessage({ title: __(response.error), type: 'error' })
        : onAddMessage({ title: 'Successfully created Private space', type: 'success' }),
    );

  return (
    <DefaultLayout>
      {isAdmin && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'middle',
          }}
        >
          <Text type="h1">Private Spaces</Text>

          <StyledPaper onClick={() => setOpenCreate(true)}>
            <Text type="h5">Create Private Space</Text>
            <AddIcon
              style={{
                marginTop: '30px',
                width: '200px',
                height: '200px',
                marginLeft: '10px',
              }}
            />
          </StyledPaper>
          {spaces.map(space => (
            <PScard key={space._id} space={space} />
          ))}
        </div>
      )}
      <Dialog
        disableScrollLock
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openCreate}
      >
        <PrivateSpaceEditor
          title="Create Private Space"
          setOpen={setOpenCreate}
          form={form}
          ready={ready}
          onSubmit={onSubmit}
          saveDisabled={false}
        />
      </Dialog>
      {!isAdmin && (
        <div style={{ width: '50%', margin: 'auto' }}>
          <h1>You are not an admin :) </h1>
        </div>
      )}
    </DefaultLayout>
  );
};
const EDITOR_ROLES = ['admin'];
const mapState = state => ({
  isNew: true,
  spaces: Object.values(getApiResource(state, 'spaces')),
  currentUser: getAuthUser(state),
  form: getForm(state, formName),
  isAdmin: getAuthUserRoles(state).some(role => EDITOR_ROLES.includes(role)),
});

const mapDispatch = (dispatch, { spaceId, type = 'image', isNew }) => {
  const onSetFormData = data => dispatch(setFormData(formName, data));
  return {
    onFetchSpaces: () => dispatch(apiGET('spaces')),
    onUpsert: data => dispatch(apiPOST('spaces', data)),
    onSetFormData,
    onAddMessage: message => dispatch(addMessage(message)),
    onGenerateCode: () => {
      fetch('/api/generateUniquePassword').then(res =>
        res.json().then(result => onSetFormData(result)),
      );
    },
  };
};

const lifecycleMap = {
  onDidMount: ({ onSetFormData, onGenerateCode, onFetchSpaces, onClearFormData }) => {
    onFetchSpaces();
    onGenerateCode();
    onSetFormData({});
  },
};

const CreatePrivateSpace = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(CreatePrivateSpaceView);

export default CreatePrivateSpace;
