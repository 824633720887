import React from 'react';
import styled, { css } from 'styled-components';
import Link from '../generic/Link';
import MenuItem from '@material-ui/core/MenuItem';
import i18nextInstance from 'artsteps2-common';
import { useEffect, useState } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { setUIProperty } from '../../actions';

const DropDownOptions = styled(MenuItem)`
  background-color: #f4f3f3;
  border-radius: 5px;
  background-color: #f4f3f3;
  border-radius: 5px;
  border-bottom: 1px solid white;
  border-top: 2px solid white;
  padding: 11px 5px 11px 10px;
  color: black;
  font-family: 'Muli', Regular;

  & > svg {
    margin-right: 9px;
    font-size: 2rem;
    fill: #999999bf;
    height: 1em;
    width: 1em;
  }
  ${props =>
    props.fillcolor &&
    css`
      & > svg {
        fill: ${props.fillcolor};
      }
      color: ${props.fillcolor};
      background-color: ${props.fillcolor}1f;
    `}
  ${props =>
    props.navlink === 'true' &&
    css`
      background-color: white;
    `}
  &:hover {
    color: #999999bf;
  }
`;

function UserDropDownLocal({
  currentUser,
  handleClose,
  onShowContributionModal = () => Promise.resolve(false),
  onToggleDonateModal = () => Promise.resolve(false),
  loggedinOptions,
  loggedOutOptions,
  setOpenLogin,
  setOpenRegister,
}) {
  const onLanguageChange = () => {
    if (i18nextInstance.language === 'en') {
      i18nextInstance.changeLanguage('fr');
      window.location.reload();
    } else {
      i18nextInstance.changeLanguage('en');
      window.location.reload();
    }
  };
  const [options, setOptions] = useState(
    loggedOutOptions([
      handleClose,
      onLanguageChange,
      onShowContributionModal,
      setOpenLogin,
      setOpenRegister,
      onToggleDonateModal,
    ]),
  );

  useEffect(() => {
    if (currentUser)
      setOptions(
        loggedinOptions(currentUser, [
          handleClose,
          onLanguageChange,
          onShowContributionModal,
          onToggleDonateModal,
        ]),
      );
    else
      setOptions(
        loggedOutOptions([
          handleClose,
          onLanguageChange,
          onShowContributionModal,
          setOpenLogin,
          setOpenRegister,
          onToggleDonateModal,
        ]),
      );

    if (
      currentUser &&
      currentUser.roles &&
      currentUser.roles.__global_roles__[0] === 'admin' &&
      loggedinOptions.length !== 9
    ) {
      setOptions([
        ...loggedinOptions(currentUser, [
          handleClose,
          onLanguageChange,
          onShowContributionModal,
          onToggleDonateModal,
        ]),
        {
          path: `/private-space-admin`,
          name: 'Private Space Admin',
          action: handleClose,
          icon: <SettingsIcon />,
        },
      ]);
    }
  }, [currentUser]);

  return (
    <>
      {options.map(
        (
          option,
          index, // PUT HERE OPEN CONTRIBUTE
        ) => {
          if (!option) return;
          const extraProps = option.path ? { component: Link, to: option.path } : {};
          return (
            <DropDownOptions
              key={'user-option-' + index}
              onClick={() => {
                if (!option.path) option.action();
              }}
              fillcolor={option.fillcolor}
              navlink={option.navlink}
              {...extraProps}
            >
              {option.icon && option.icon}
              {option.name}
            </DropDownOptions>
          );
        },
      )}
    </>
  );
}
const mapState = state => ({});

const mapDispatch = dispatch => ({
  onShowContributionModal: () => dispatch(setUIProperty('contributionModalOpen', true)),
  setOpenLogin: () => dispatch(setUIProperty('openLogin', true)),
  setOpenRegister: () => dispatch(setUIProperty('openRegister', true)),
  onToggleDonateModal: show => dispatch(setUIProperty('donateModal', show)),
});

const lifecycleMap = {};

const UserDropDownConnected = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(UserDropDownLocal);

export default UserDropDownConnected;
