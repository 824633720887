import Grid from '@material-ui/core/Grid';
import React, { useState, useEffect } from 'react';
import utils from '../utils';
import { withState, compose } from '../enhancers';
import { getApiResource, getUIProperty, getAuthUser } from '../reducers';
import DefaultLayout from '../components/layouts/DefaultLayout';
import ExhibitionViewFrame from '../components/exhibitions/viewer/ExhibitionViewFrame';
import ExhibitionInfo from '../components/exhibitions/viewer/ExhibitionInfo';
import ExhibitionMeta from '../components/exhibitions/viewer/ExhibitionMeta';
import useWindowSize from '../hooks/windowSize';

const EXHIBITION_LIMIT = 6;

const ExhibitionViewPageView = ({
  exhibitionId,
  exhibition = { _id: null, user: { _id: null }, categories: [null] },
  query,
  isPrivateSpace,
  categories,
  currentUser,
}) => {
  const [value, setValue] = useState(0);
  const [isUsers, setIsUsers] = useState(false);
  const [isSmall, setIsSmall] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    setIsSmall(size.width < 1280);
  }, [size.width]);

  useEffect(() => {
    currentUser &&
      setIsUsers(currentUser._id === exhibition.user._id || currentUser._id === exhibition.user);
  }, [currentUser, exhibition]);

  let fromCreatorFilter;
  let relatedFilter;
  if (!isPrivateSpace) {
    fromCreatorFilter = {
      published: true,
      user: exhibition.user._id || exhibition.user,
    };

    relatedFilter = {
      //_id: { $ne: exhibition._id },
      published: true,
      categories: exhibition.categories[0] || undefined, // brings all the exhibition that have the main category of this exhibition
    };
  } else {
    fromCreatorFilter = {
      publishedPrivateSpace: true,
      user: exhibition.user._id || exhibition.user,
    };

    relatedFilter = {
      published: true,
      categories: exhibition.categories[0] || undefined, // brings all the exhibition that have the main category of this exhibition
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <DefaultLayout exhibitionId={exhibitionId} exhibition={exhibition}>
      <ExhibitionMeta exhibitionId={exhibitionId} />
      <Grid
        style={{
          marginBottom: '20px',
          marginTop: `${isUsers && size.width > 720 ? '80px' : '20px'}`,
        }}
        container
        justify="space-evenly"
      >
        <Grid item xs={11}>
          {true && (
            <ExhibitionViewFrame
              exhibitionId={exhibitionId}
              webVR={utils.navigator.isIE()}
              unsupportedAgent={utils.navigator.isIE()}
              unsupportedDevice={utils.navigator.isMobile()}
            />
          )}

          <ExhibitionInfo exhibitionId={exhibitionId} exhibition={exhibition} />
        </Grid>
      </Grid>
    </DefaultLayout>
  );
};

const mapState = (state, { exhibitionId }) => ({
  exhibition: {
    _id: null,
    user: {
      _id: null,
    },
    categories: [null],
    ...getApiResource(
      state,
      `exhibitions${
        window.location.href.includes('currentUser') ? 'UserProfile' : ''
      }/${exhibitionId}`,
    ),
  },
  categories: Object.values(getApiResource(state, 'categories')),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  currentUser: getAuthUser(state),
});

const ExhibitionViewPage = compose(withState(mapState))(ExhibitionViewPageView);

export default ExhibitionViewPage;
