import { Paper } from '@material-ui/core';

import styled, { css } from 'styled-components';

export const MaterialSquare = styled(Paper)`
  padding: unset;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid black;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
  margin-left: 5px;
`;

export const ColorSquare = styled(Paper)`
  position: absolute;
  height: 100%;
  width: 100%;
  ${props =>
    props.background &&
    css`
      background: ${props.background};
      /* transform: translate(22px); */
    `}
`;

export const TextureSquare = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
`;
