import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PrivateSpaceEditor from '../artifacts/forms/PrivateSpaceEditor';
import Logo from '../privateSpaces/Logo';
import YesOrNo from '../generic/YesOrNo';
import { StyledPaper } from '../../styles/PrivateSpacesPageStyled';
import { StyledButton, Text } from '../../styles/GenericStyled';
import { apiDELETE, addMessage, setFormData, apiPATCH } from '../../actions';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { getForm } from '../../reducers';

const mapSpace = space => {
  const mappedSpace = {
    ...space,
    settings: {
      allowLinks: space.allowLinks,
      showTour: space.showTour,
      showArtstepsRedirect: space.showArtstepsRedirect,
      showExhibitions: space.showExhibitions,
    },
  };
  return mappedSpace;
};

const PScardView = ({
  form,
  ready = true,
  space,
  onDeleteSpace,
  onAddMessage = () => Promise.resolve(false),
  onUpdate = () => Promise.resolve(false),
}) => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const onRedirect = () => {
    const redirectURL = `http://${space.subdomain}.${window.location.hostname
      .replace('www2', '')
      .replace('www', '')
      .replace('.', '')}`.concat(
      window.location.hostname.split('.')[0] === 'localhost' ? ':3000' : '',
    );

    window.location.assign(redirectURL);
  };

  const handleYes = () => {
    onDeleteSpace(space._id)
      .then(() =>
        onAddMessage({
          type: 'success',
          title: __('Deleted space'),
          description: 'ʕ·͡ᴥ·ʔ',
        }),
      )
      .catch(error => {
        onAddMessage({
          type: 'error',
          title: __('couldnt delete space'),
          description: error,
        });
      });

    setOpen(false);
  };
  return (
    <>
      <Dialog
        disableScrollLock
        onClose={() => setOpenUpdate(false)}
        aria-labelledby="simple-dialog-title"
        open={openUpdate}
      >
        <PrivateSpaceEditor
          title="Update Private Space"
          setOpen={setOpenUpdate}
          form={form}
          ready={ready}
          onSubmit={() => onUpdate(space._id)}
          saveDisabled={false}
        />
      </Dialog>
      <YesOrNo
        title="Delete private Space?"
        message={`Are you sure you want to delete ${space.subdomain}`}
        optionA="No"
        optionB="Yes"
        open={open}
        setOpen={setOpen}
        handleYes={handleYes}
      />
      <StyledPaper key={space.subdomain}>
        <Text onClick={onRedirect} type="h5">
          {space.subdomain}
        </Text>
        <Text mycolor={space.isActive ? '#009900' : '#e3242b'} type="h5">
          {space.isActive ? 'Active' : 'Inactive'}
        </Text>
        <Logo
          onRedirect={onRedirect}
          space={space}
          width="300px"
          maxWidth="300px"
          maxHeight="200px"
        />

        <div style={{ width: '100%', justifyContent: 'space-evenly', display: 'flex' }}>
          <StyledButton
            onClick={() => {
              setOpenUpdate(true);
            }}
            border={1}
            mycolor="black"
            background="yellow"
            type="Primary"
          >
            <EditIcon />
          </StyledButton>
          <StyledButton onClick={() => setOpen(true)} border={1} background="red" type="Primary">
            <DeleteForeverIcon />
          </StyledButton>
        </div>
      </StyledPaper>
    </>
  );
};

const mapState = (state, { space }) => ({ form: getForm(state, space.subdomain) });

const mapDispatch = (dispatch, { form }) => ({
  onSetFormData: space => dispatch(setFormData(space.subdomain, space)),
  onUpdate: spaceId => dispatch(apiPATCH(`spaces/${spaceId}`, mapSpace(form.data))),
  onDeleteSpace: spaceId => dispatch(apiDELETE(`spaces/${spaceId}`)),
  onAddMessage: message => dispatch(addMessage(message)),
});

const lifecycleMap = {
  onDidMount: ({ onSetFormData, space }) => {
    const mappedSpace = {
      ...space,
      allowLinks: space.settings ? space.settings.allowLinks : false,
    };
    onSetFormData(mappedSpace);
  },
};

const PScard = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(PScardView);

export default PScard;
