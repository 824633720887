import React from 'react';
import { withWidth, Card, Grid, Typography, Button } from '@material-ui/core';
import { Visibility, AccessTime, FavoriteBorder, ArrowForward } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { API_KEYS, API_STATUS, getApiResource, getApiStatus } from '../../../reducers';
import { apiGET, setLocation } from '../../../actions';
import { compose, withDispatch, withLifecycle, withState } from '../../../enhancers';
import { NLinesBreak, StyledButton } from '../../../styles/GenericStyled';
import Link from '../../generic/Link';
import utils from '../../../utils';
import defaultLogo from '../../../styles/images/templates/CreateYourOwn.png';
import './ExhibitionCard.css';

const useStyles = makeStyles({
  disabled: {
    '&:disabled': {
      color: 'rgba(52, 58, 64, 1)',
      backgroundColor: 'rgba(52, 58, 64, 0.1)',
    },
  },
});

export const ExhibitionCardView = ({
  blocked = false,
  exhibition,
  categories,
  user,
  width,
  onRedirect = () => Promise.resolve(false),
  firstCategory,
}) => {
  const classes = useStyles();
  if (!blocked) {
    return <></>;
  }

  fetch(exhibition.image.preview, {
    method: 'GET',
  })
    .then(response => {
      if (response.status !== 200) {
        // eslint-disable-next-line no-param-reassign
        exhibition.image.preview = defaultLogo;
      }
    })
    .catch(error => {
      // eslint-disable-next-line no-param-reassign
      exhibition.image.preview = defaultLogo;
    });

  let categoryButtons;
  if (firstCategory !== undefined) {
    categoryButtons = []
      .concat(exhibition.categories)
      .sort((x, y) =>
        // eslint-disable-next-line no-nested-ternary
        x === firstCategory._id ? -1 : y === firstCategory._id ? 1 : 0,
      )
      .map(id => (
        <Button className="categories" classes={{ disabled: classes.disabled }} key={id} disabled>
          {categories.find(obj => obj._id === id).title}
        </Button>
      ));
  } else {
    categoryButtons = [].concat(exhibition.categories).map(id => (
      <Button className="categories" classes={{ disabled: classes.disabled }} key={id} disabled>
        {categories.find(obj => obj._id === id).title}
      </Button>
    ));
  }

  let cardFooter = (
    <div className="grid-footer">
      <div style={{ alignContent: 'center' }}>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <Visibility />
          </Grid>
          <Grid item>
            <Typography gutterBottom className="icon-legends">
              {exhibition.visits ? exhibition.visits : 0}
            </Typography>
          </Grid>
          <Grid item>
            <AccessTime />
          </Grid>
          <Grid item>
            <Typography gutterBottom className="icon-legends">
              {utils.date.fromNow(new Date(exhibition.publishedAt))}
            </Typography>
          </Grid>
          <Grid item>
            <FavoriteBorder />
          </Grid>
          <Grid item>
            <Typography gutterBottom className="icon-legends">
              {((exhibition.likes || {})[API_KEYS.META] || {}).totalCount || 0}{' '}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Link to={{ pathname: `/view/${exhibition._id}` }}>
          <StyledButton
            type="primary"
            mycolor="black"
            background="white"
            style={{ float: 'right', paddingBottom: '40px' }}
          >
            <strong>More </strong> {<ArrowForward fontSize="large" />}
          </StyledButton>
        </Link>
      </div>
    </div>
  );

  return (
    <Card className="root" key={exhibition._id}>
      <div className="exhibition-card-image">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          className="exhibition-card-image"
          src={exhibition.image.preview}
          alt={exhibition.title}
          style={{ 'pointer-events': 'all' }}
          onClick={() => {
            onRedirect(`/view/${exhibition._id}`);
          }}
        />
      </div>
      <div className="main-div">
        <Grid
          container
          direction="column"
          justify="space-evenly"
          alignItems="stretch"
          wrap="nowrap"
        >
          <Grid item>
            <Link to={{ pathname: `/view/${exhibition._id}` }}>
              <Typography gutterBottom className="title">
                {exhibition.title}
              </Typography>
            </Link>
          </Grid>
          {exhibition.description ? (
            <Grid item>
              <NLinesBreak lines={3}>
                <Typography gutterBottom className="description">
                  {exhibition.description}
                </Typography>
              </NLinesBreak>
            </Grid>
          ) : (
            <div className="white-div">
              <br />
            </div>
          )}
          <Grid item>
            <NLinesBreak lines={1}>{categoryButtons}</NLinesBreak>
          </Grid>
        </Grid>
        {cardFooter}
      </div>
    </Card>
  );
};

const mapState = (state, { exhibition }) => ({
  blocked: getApiStatus(state, `users/${exhibition?.user?._id}`) === API_STATUS.IDLE,
  user: getApiResource(state, !exhibition ? '' : `users/${exhibition?.user?._id}`),
});
const mapDispatch = (dispatch, { exhibition }) => ({
  onFetchUser: () => dispatch(apiGET(`users/${exhibition?.user?._id}`)),
  onRedirect: location => dispatch(setLocation(location)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchUser }) => {
    onFetchUser();
  },
};
const ExhibitionCard = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
  withWidth(),
)(ExhibitionCardView);
export default ExhibitionCard;
