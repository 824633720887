import React from 'react';
import { __ } from 'artsteps2-common';
import publicConfig from 'artsteps2-config/public.json';
import utils from '../../../utils';
import ArtifactInfoEditor from './ArtifactInfoEditor';

const IMAGE_WIDTH = publicConfig.artifact.text.imageWidth;
const IMAGE_HEIGHT = publicConfig.artifact.text.imageHeight;
const DEFAULT_COLOUR = publicConfig.artifact.text.defaultColor;
const DEFAULT_ALIGNMENT = publicConfig.artifact.text.defaultAlignment;

export const TextEditorView = ({
  form = { data: {} },
  ready = true,
  onSubmit,
  onRemove,
  onClose,
}) => {
  const alignments = {
    0: __('left'),
    1: __('center'),
    2: __('right'),
  };

  return (
    <ArtifactInfoEditor
      form={form}
      ready={ready}
      onSubmit={onSubmit}
      onRemove={onRemove}
      onClose={onClose}
      saveDisabled={
        !form.data.files ||
        !form.data.files.length > 0 ||
        !form.data.files[0].bin ||
        (!form.data.image && !form.data.files[0].image)
      }
      extraFields={[
        {
          files: {
            type: 'textarea',
            label: __('text'),
            required: true,
            fromFormValue: ([{ bin } = {}] = []) => utils.buffer.toUTF8String(bin),
            toFormValue: (text, { target }, onValueChange) => {
              const bin = utils.buffer.fromUTF8String(text, 'text.txt');
              utils.image
                .toDataURL({ text, width: IMAGE_WIDTH, height: IMAGE_HEIGHT })
                .then(dataUri =>
                  onValueChange({
                    target: {
                      name: target.name,
                      value: [
                        {
                          bin,
                          image: { bin: utils.buffer.fromDataURL(dataUri) },
                        },
                      ],
                    },
                  }),
                );
              return [{ bin }];
            },
          },
          'model.colourTint': {
            type: 'color',
            label: __('colour'),
            default: DEFAULT_COLOUR,
          },
          'model.textProperties.alignment': {
            label: __('alignment'),
            type: 'select',
            options: alignments,
            default: DEFAULT_ALIGNMENT,
            required: true,
          },
        },
      ]}
    />
  );
};

const TextEditor = TextEditorView;

export default TextEditor;
