import Avatar from '@material-ui/core/Avatar';
import SortIcon from '@material-ui/icons/Sort';
import TextField from '@material-ui/core/TextField';
import React, { useState, useEffect } from 'react';
import { __ } from 'artsteps2-common';
import Link from '../../generic/Link';
import utils from '../../../utils';
import Comment from './Comment';
import { Buttons, HorizontalItems } from '../../exhibitions/viewer/ExhibitionInfoStyled';
import { FullLine, Text, TextButton } from '../../../styles/GenericStyled';
import Loader from '../../generic/Loader';
import {
  API_STATUS,
  getApiStatus,
  getApiResource,
  getApiMeta,
  getForm,
  getAuthUser,
} from '../../../reducers';
import {
  handleFormPropertyChange,
  clearFormData,
  apiPOST,
  apiGET,
  addMessage,
} from '../../../actions';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import useWindowSize from '../../../hooks/windowSize';
import { setUIProperty } from '../../../actions/ui';

export const CommentListView = ({
  meta: { totalCount = 0 } = {},
  comments = [],
  exhibition = {},
  form = { data: {} },
  ready = true,
  currentUser,
  parentCommentId,
  setOpenLogin,
  setOpenRegister,
  onAddComment = () => Promise.resolve({ response: { error: 'error' } }),
  onAddMessage = () => Promise.resolve(false),
  onFormPropertyChange = () => Promise.resolve(false),
  onClearForm = () => Promise.resolve(false),
}) => {
  const size = useWindowSize();

  const [isMobile, setIsMobile] = useState(false);

  // having an effect that sets if the screen size if mobile or not
  useEffect(() => {
    setIsMobile(size.width < 650);
  }, [size.width]);

  const renderComments = () => (
    <div className="comment-list">
      {comments.map(
        comment =>
          comment._id && (
            <div key={comment._id} className="comment center aligned">
              <Comment
                comment={comment}
                exhibitionId={exhibition._id}
                repliesDisabled={!!parentCommentId}
                isReply={parentCommentId !== undefined}
              />
            </div>
          ),
      )}
    </div>
  );

  const onSubmit = () =>
    onAddComment({
      body: form.data.body,
      parent: parentCommentId,
      exhibition: exhibition._id,
    }).then(({ response }) =>
      response.error
        ? onAddMessage({
            type: 'error',
            title: __('insert_error'),
            description: __(response.error),
          })
        : onClearForm(),
    );

  if (!exhibition.published) {
    return (
      <div className="ui basic segment">
        <h3 className="ui header">{__('comments')}</h3>
        {__('comments_disabled_private')}
        {renderComments()}
      </div>
    );
  }

  if (parentCommentId) {
    return renderComments();
  }

  return (
    <div style={{ marginTop: '20px', marginBottom: '50px' }}>
      {isMobile && <FullLine />}
      <HorizontalItems style={{ marginBottom: '10px' }}>
        <span>
          <b>{`${totalCount} ${__('comments')}`}</b>
          <SortIcon />
        </span>
      </HorizontalItems>
      {isMobile && <FullLine />}
      {currentUser && (
        <>
          <HorizontalItems style={{ marginBottom: '20px', width: '100%' }}>
            <span>
              {currentUser && currentUser.profile && currentUser.profile.image && (
                <Link to={`../profile/${currentUser._id}`}>
                  <Avatar alt="Avatar" src={utils.user.getUserImage(currentUser)} />
                </Link>
              )}
            </span>
            <span style={{ width: '100%' }}>
              <TextField
                fullWidth
                multiline
                rowsMax={4}
                type="text"
                name="body"
                placeholder={__('comment_placeholder')}
                value={form.data.body || ''}
                onChange={onFormPropertyChange}
              />
            </span>
          </HorizontalItems>
          {(form.data.body || '').trim() && (
            <HorizontalItems>
              <span style={{ marginLeft: 'auto' }}>
                <Buttons onClick={onClearForm} tag={1}>
                  {__('Cancel')}
                </Buttons>
                <Buttons onClick={onSubmit} follow={1}>
                  {__('Comment')}
                </Buttons>
              </span>
            </HorizontalItems>
          )}
        </>
      )}
      {!currentUser && (
        <div className="comments-disabled">
          <Text>
            {__('comments_disabled_auth')} 
            <TextButton onClick={() => setOpenLogin(true)} className="item">
              {__('login')}
            </TextButton>
             {__('or')} 
            <TextButton onClick={() => setOpenRegister(true)} className="item">
              {__('register')}
            </TextButton>
          </Text>
        </div>
      )}
      {renderComments()}
      {!ready && <Loader />}
    </div>
  );
};

const createQuery = (exhibition, parent) => ({
  filter: { parent: parent || null, exhibition },
  populate: 'user',
  include: 'body',
});

const mapState = (state, { exhibitionId, parentCommentId }) => ({
  comments: Object.values(
    getApiResource(state, 'comments', createQuery(exhibitionId, parentCommentId)),
  ),
  // exhibition: window.location.href.includes('currentUser') ? 
  //   getApiResource(state, `exhibitionsUserProfile/${exhibitionId}`) : 
  //   getApiResource(state, `exhibitions/${exhibitionId}`),
  meta: getApiMeta(state, 'comments', createQuery(exhibitionId, parentCommentId)),
  ready:
    getApiStatus(state, 'comments', createQuery(exhibitionId, parentCommentId)) === API_STATUS.IDLE,
  currentUser: getAuthUser(state),
  form: getForm(state, `comments:${exhibitionId}:${parentCommentId || ''}`),
});

const mapDispatch = (dispatch, { exhibitionId, parentCommentId }) => ({
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  onFetchComments: () => dispatch(apiGET('comments', createQuery(exhibitionId, parentCommentId))),
  onAddComment: comment =>
    dispatch(apiPOST('comments', comment, createQuery(exhibitionId, parentCommentId))),
  onAddMessage: message => dispatch(addMessage(message)),
  onFormPropertyChange: event =>
    dispatch(handleFormPropertyChange(`comments:${exhibitionId}:${parentCommentId || ''}`, event)),
  onClearForm: () => dispatch(clearFormData(`comments:${exhibitionId}:${parentCommentId || ''}`)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchComments }) => onFetchComments(),
  onDidUpdate: (props, { onFetchComments, exhibitionId, parentCommentId }) =>
    (exhibitionId !== props.exhibitionId || parentCommentId !== props.parentCommentId) &&
    onFetchComments(),
};

const CommentList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(CommentListView);

export default CommentList;
