import React from 'react';
import { __ } from 'artsteps2-common';
import i18nextInstance from 'artsteps2-common';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import LanguageIcon from '@material-ui/icons/Language';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PaymentIcon from '@material-ui/icons/Payment';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import SettingsIcon from '@material-ui/icons/Settings';
import { ReactComponent as LoginIcon } from '../../styles/images/loginIcon.svg';
import UserDropDown from './UserDropDown';
import CuratorButtons from './DefaultButtons';

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    top: '68px!important',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '200px',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

const loggedinOptions = (currentUser, action) => [
  {
    path: `/profile/${currentUser._id}`,
    name: __('profile'),
    action: action[0],
    icon: <AccountCircleIcon />,
  },
  {
    path: `/contact`,
    name: __('support'),
    action: action[0],
    icon: <ContactSupportIcon />,
  },
  {
    path: `/change-password`,
    name: __('change_password'),
    action: action[0],
    icon: <VpnKeyIcon />,
  },
  {
    path: `/logout`,
    name: __('logout'),
    action: action[0],
    icon: <LoginIcon />,
  },
];

const loggedOutOptions = action => [
  {
    path: `/contact`,
    name: __('support'),
    action: action[0],
    icon: <ContactSupportIcon />,
  },
];

export default function SideButtons(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Grid
      item
      direction="row"
      alignItems="center"
      xl={!props.curatorView ? 2 : 6}
      lg={!props.curatorView ? 3 : 6}
      md={!props.curatorView ? 2 : 12}
      sm={!props.curatorView ? 3 : 12}
      container
      justify="flex-end"
      wrap="nowrap"
      style={{ marginLeft: 'auto', width: 'auto', minWidth: 'fit-content' }}
    >
      <CuratorButtons
        exhibitionId={props.exhibitionId}
        handleMenu={handleMenu}
        navbarView={props.navbarView}
        width={props.width}
        currentUser={props.currentUser}
        fakeCurrentUser={props.fakeCurrentUser}
      />

      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        disableScrollLock={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <UserDropDown
          loggedinOptions={loggedinOptions}
          loggedOutOptions={loggedOutOptions}
          handleClose={handleClose}
          currentUser={props.currentUser}
        />
      </StyledMenu>
    </Grid>
  );
}
