import React, { useEffect } from 'react';
import { __ } from 'artsteps2-common';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getUIProperty, getAuthUser } from '../../../reducers';
import { setUIProperty } from '../../../actions';

const MonitorProgressView = ({ src, audioRef, progress = 0, onSetEnabledAudio }) => {
  useEffect(() => {
    if (progress === 1 && audioRef.current.paused) {
      audioRef.current
        .play()
        .then(() => {})
        .catch(() => onSetEnabledAudio(audioRef, true));
    }
  }, [progress, audioRef, src]);

  return <></>;
};

const mapState = (state, { exhibitionId }) => ({
  progress: getUIProperty(state, `exhibitions/${exhibitionId}/progress`),
  currentUser: getAuthUser(state) || getUIProperty(state, 'fakeCurrentUser'),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onSetEnabledAudio: (ref, show) =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/enabledAudio`, [ref, show])),
});

const MonitorProgress = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(MonitorProgressView);

export default MonitorProgress;
