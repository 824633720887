import * as THREE from 'three';
import GLTFLoader from 'three-gltf-loader';
import { OBJLoader } from 'three-obj-mtl-loader';

export const createPreview = (loader, canvas, uri) => {
  if (!canvas || canvas.getAttribute('data-rendered') === uri) {
    return Promise.reject(canvas);
  }
  return new Promise((resolve, reject) =>
    loader.load(
      uri,
      asset => {
        const mesh = (asset && asset.scene) || asset;
        if (!mesh) {
          return;
        }
        const width = canvas.offsetWidth;
        const height = canvas.offsetHeight;
        canvas.classList.remove('rendered');
        const camera = new THREE.PerspectiveCamera(45, width / height, 1, 2000);

        const scene = new THREE.Scene();
        const directionalLight = new THREE.DirectionalLight(0xffeedd);
        directionalLight.position.set(0, 0, 1);
        scene.add(directionalLight);

        const box = new THREE.Box3().setFromObject(mesh);
        box.center(mesh.position);
        mesh.position.multiplyScalar(-1);

        const obj = new THREE.Group();
        scene.add(obj);
        obj.add(mesh);
        obj.rotation.y = -45;

        camera.position.z =
          Math.max(box.max.x - box.min.x, box.max.y - box.min.y, box.max.z - box.min.z) /
          Math.tan(camera.fov * (Math.PI / 360));

        const renderer = new THREE.WebGLRenderer({
          canvas,
          preserveDrawingBuffer: true,
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(width, height);
        renderer.render(scene, camera);
        canvas.classList.add('rendered');

        let uplistener;
        let movelistener;
        canvas.addEventListener('mousedown', event => {
          window.removeEventListener('mouseup', uplistener);
          window.removeEventListener('mousemove', movelistener);
          const cameraZ = camera.position.z;
          const objectY = obj.rotation.y;
          movelistener = e => {
            camera.position.z = cameraZ - (event.screenY - e.screenY) / 32;
            obj.rotation.y = (objectY - (event.screenX - e.screenX) / 32) % 360;
            renderer.render(scene, camera);
          };
          uplistener = () => {
            window.removeEventListener('mousemove', movelistener);
            window.removeEventListener('mouseup', uplistener);
          };
          window.addEventListener('mousemove', movelistener);
          window.addEventListener('mouseup', uplistener);
        });

        canvas.setAttribute('data-rendered', uri);

        resolve(canvas);
      },
      undefined,
      err => reject(err),
    ),
  );
};

export const createGLTFPreview = (canvas, uri) => createPreview(new GLTFLoader(), canvas, uri);

export const createOBJPreview = (canvas, uri) => createPreview(new OBJLoader(), canvas, uri);

export const createObjectPreview = (canvas, uri, filename) =>
  uri && (filename || uri).match(/.obj$/)
    ? createOBJPreview(canvas, uri)
    : createGLTFPreview(canvas, uri);

export const createPanoramicView = (canvas, uri) => {
  if (!canvas) {
    return Promise.reject(canvas);
  }

  if (canvas.getAttribute('data-rendered') === uri) {
    return Promise.resolve(canvas);
  }

  const width = canvas.offsetWidth;
  const height = canvas.offsetHeight;

  const renderer = new THREE.WebGLRenderer({
    canvas,
    preserveDrawingBuffer: false,
  });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(width, height);

  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, width / height, 1, 1000);
  camera.target = new THREE.Vector3(0, 0, 0);

  const sphere = new THREE.SphereGeometry(100, 100, 40);
  sphere.applyMatrix(new THREE.Matrix4().makeScale(-1, 1, 1));

  const sphereMaterial = new THREE.MeshBasicMaterial();
  sphereMaterial.map = new THREE.TextureLoader().load(uri);

  const sphereMesh = new THREE.Mesh(sphere, sphereMaterial);
  scene.add(sphereMesh);

  let manualControl = false;
  let longitude = 0;
  let latitude = 0;

  const render = () => {
    if (!document.contains(canvas)) {
      return;
    }

    requestAnimationFrame(render);

    if (!manualControl) {
      longitude += 0.1;
    }

    latitude = Math.max(-85, Math.min(85, latitude));
    camera.target.x =
      500 * Math.sin(THREE.Math.degToRad(90 - latitude)) * Math.cos(THREE.Math.degToRad(longitude));
    camera.target.y = 500 * Math.cos(THREE.Math.degToRad(90 - latitude));
    camera.target.z =
      500 * Math.sin(THREE.Math.degToRad(90 - latitude)) * Math.sin(THREE.Math.degToRad(longitude));
    camera.lookAt(camera.target);

    renderer.render(scene, camera);
  };

  let uplistener;
  let movelistener;
  canvas.addEventListener('mousedown', event => {
    window.removeEventListener('mouseup', uplistener);
    window.removeEventListener('mousemove', movelistener);
    const { clientX, clientY } = event;
    const stopLongitude = longitude;
    const stopLatitude = latitude;
    manualControl = true;
    movelistener = e => {
      longitude = (clientX - e.clientX) * 0.1 + stopLongitude;
      latitude = (e.clientY - clientY) * 0.1 + stopLatitude;
    };
    uplistener = () => {
      manualControl = false;
      window.removeEventListener('mousemove', movelistener);
      window.removeEventListener('mouseup', uplistener);
    };
    window.addEventListener('mousemove', movelistener);
    window.addEventListener('mouseup', uplistener);
  });

  canvas.setAttribute('data-rendered', uri);
  render();
  return Promise.resolve(canvas);
};
