"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportStorypoint = exports.exportArtifact = exports.exportExhibition = exports.importExhibition = exports.getExhibitionAudio = exports.getExhibitionImage = exports.getExhibitionBlobURIPrivate = exports.getExhibitionBlobURI = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _public = _interopRequireDefault(require("artsteps2-config/public.json"));

var _artifact = require("./artifact");

var _storypoint = require("./storypoint");

var _media = require("./media");

var _buffer = require("./buffer");

var _obj = require("./obj");

var _math = require("./math");

var DISTANCE_MIN = 2;
var DISTANCE_MAX = 14;
var ANGLE_MIN = 15;
var ANGLE_MAX = 65;
var NUMBER_OF_INCREMENTS = 8;
var PATH_DEFAULT_IMG = '/images/exhibition.png';
var PATH_EXHIBITIONS = '/api/exhibitions';
var PATH_EXHIBITIONS_PRIVATE = '/api/exhibitionsPrivate';
var PATH_EXHIBITION_IMAGE = 'image';
var PATH_EXHIBITION_AUDIO = 'audio';
var FILE_SYSTEM_ROOT_URL = _public["default"].usersFileSystemUrl;
var DEFAULT_FONT_SIZE = 50;
var DEFAULT_LINE_SPACING = 1;
var DEFAULT_ARTIFACT_SCALE = 2;
var DEFAULT_ARTIFACT_COLOUR = '#666666';

var getRootURL = function getRootURL() {
  return typeof window === 'undefined' ? process.env.ROOT_URL : "".concat(window.location.protocol, "//").concat(window.location.host);
};

var setProtocol = function setProtocol(url) {
  return url && url.replace(/^https?:\/\//, getRootURL().match(/^[a-zA-Z:]+\/\//));
};

var setHostname = function setHostname(url) {
  return url && setProtocol(url.replace(/^\//, "".concat(getRootURL(), "/")));
};

var stripHostname = function stripHostname(url) {
  return url && url.replace(getRootURL(), '');
};

var toStrId = function toStrId(ref) {
  return ref && (ref._id || ref).toString();
}; // called on save


var importMappings = {
  artifacts: function artifacts(_artifacts) {
    return _artifacts.map(function (a) {
      var artifact = Object.assign({}, a);
      artifact.artifact = artifact.artifactId;
      delete artifact.artifactId;
      delete artifact.uri;
      delete artifact.audioUri;
      delete artifact.artifactType;
      delete artifact.title;
      delete artifact.description;
      delete artifact.preview;
      delete artifact.hasCanvas;
      delete artifact.nonInteractive;
      delete artifact.colorTint;
      delete artifact.files;
      return artifact;
    });
  },
  storyPoints: function storyPoints(storypoints) {
    return storypoints.map(function (p) {
      var storypoint = Object.assign({}, p);
      storypoint.storyPoint = storypoint.storyPointId;
      delete storypoint.storyPointId;
      delete storypoint.pointTitle;
      delete storypoint.pointDescription;
      delete storypoint.audioUri;
      delete storypoint.colour;
      delete storypoint.pointOrder;
      delete storypoint.artifacts;
      delete storypoint.waitTime;
      delete storypoint.travelTime;
      delete storypoint.artifactId;
      return storypoint;
    });
  },
  stories: function stories(_stories) {
    return _stories.map(function (s) {
      var story = Object.assign({}, s);
      delete story.storyTitle;
      delete story.storyDescription;
      return story;
    });
  },
  walls: function walls(_walls) {
    return _walls.map(function (w) {
      var wall = Object.assign({}, w);
      delete wall.attachedPaintingIds;
      wall.textureFront = stripHostname(wall.textureFront);
      wall.textureBack = stripHostname(wall.textureBack);
      return wall;
    });
  },
  buildingPolygons: function buildingPolygons(_buildingPolygons) {
    return _buildingPolygons.map(function (p) {
      var polygon = Object.assign({}, p);
      polygon.ceilingTexture = stripHostname(polygon.ceilingTexture);
      polygon.floorTexture = stripHostname(polygon.floorTexture);
      return polygon;
    });
  },
  doors: function doors(_doors) {
    return _doors.map(function (d) {
      var door = Object.assign({}, d);

      if (door.topPart) {
        door.topPart = Object.assign({}, door.topPart);
        delete door.topPart.attachedPaintingIds;
        door.topPart.textureFront = stripHostname(door.topPart.textureFront);
        door.topPart.textureBack = stripHostname(door.topPart.textureBack);
      }

      door.hallTexture = stripHostname(door.hallTexture);
      door.hallTextureFront = stripHostname(door.hallTextureFront);
      door.hallTextureBack = stripHostname(door.hallTextureBack);
      return door;
    });
  },
  floorTexture: function floorTexture(texture) {
    return stripHostname(texture);
  },
  generalSettings: function generalSettings(settings) {
    return Object.assign({}, settings, {
      groundTexture: stripHostname(settings.groundTexture)
    });
  },
  exhibitionId: function exhibitionId() {
    return undefined;
  },
  template: function template(_template) {
    return _template.templateId || null;
  }
}; // Maps exhibition to send to Unity
// Called when exhibition model comes from DB

var exportMappings = {
  artifacts: function artifacts() {
    var Artifacts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var _ref = arguments.length > 1 ? arguments[1] : undefined,
        _artifacts2 = _ref.artifacts;

    return Artifacts.map(function (artifact) {
      var match = _artifacts2.find(function (a) {
        return toStrId(a) === toStrId(artifact.artifact);
      });

      return Object.assign({}, artifact, {
        uri: match && (0, _artifact.getArtifactURI)(match),
        thumbnail: match && (0, _artifact.getVideoThumbnail)(match),
        artifactId: match && match._id,
        audioUri: match && (0, _artifact.getArtifactAudio)(match),
        artifactType: match && match.type,
        link: match && match.link,
        title: match && match.title,
        description: match && match.caption,
        preview: match && (0, _artifact.getArtifactImage)(match),
        textProperties: match && match.type === 'text' ? {
          fontSize: (0, _obj.getProperty)(match, 'model.textProperties.fontSize') || DEFAULT_FONT_SIZE,
          fontStyle: (0, _obj.getProperty)(match, 'model.textProperties.fontStyle'),
          alignment: (0, _obj.getProperty)(match, 'model.textProperties.alignment'),
          lineSpacing: DEFAULT_LINE_SPACING
        } : undefined,
        colorTint: match && match.type === 'text' ? "".concat((match.model || match).colourTint || DEFAULT_ARTIFACT_COLOUR, "ff") : undefined,
        hasCanvas: match && (match.model || match).hasCanvas,
        nonInteractive: match && (match.model || match).nonInteractive,
        files: ((match || {}).files || []).map(function (f) {
          return f.uri || f.bin && f.bin.name;
        }).filter(function (f) {
          return f;
        })
      });
    }).filter(function (a) {
      return a.artifactId;
    });
  },
  storyPoints: function storyPoints() {
    var StoryPoints = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    var _ref2 = arguments.length > 1 ? arguments[1] : undefined,
        storypoints = _ref2.storypoints,
        exhibition = _ref2.exhibition;

    return StoryPoints.map(function (point) {
      var match = storypoints.find(function (p) {
        return toStrId(p) === toStrId(point.storyPoint);
      });
      var storypoint = Object.assign({}, point, {
        storyPointId: match && match._id,
        pointTitle: match ? match.title : point.pointTitle,
        pointDescription: match ? match.description : point.pointDescription,
        audioUri: (0, _storypoint.getStorypointAudio)(match),
        colour: match ? match.colour : point.colour,
        pointOrder: match ? match.order : point.pointOrder,
        artifacts: match ? match.artifacts : point.artifacts
      });

      if (!storypoint.artifacts) {
        var pointPosition = (0, _math.parseQuaternion)(storypoint.watchPosition);
        var pointRotation = (0, _math.toEulerianAngle)((0, _math.parseQuaternion)(storypoint.watchRotation));
        var artifacts = (exhibition.artifacts || []).map(function (artifact) {
          var artifactPosition = (0, _math.parseQuaternion)(artifact.position);
          var artifactRotation = (0, _math.toEulerianAngle)((0, _math.parseQuaternion)(artifact.rotation));
          return {
            artifactId: artifact.artifact._id || artifact.artifact,
            distance: Math.sqrt(Math.pow(artifactPosition[0] - pointPosition[0], 2) + Math.pow(artifactPosition[2] - pointPosition[2], 2)),
            angle: Math.abs((0, _math.toRelativeAngle)(Math.abs((0, _math.toPositiveAngle)(pointRotation[1]) - (0, _math.toPositiveAngle)((0, _math.getHorizontalAngle)(pointPosition, artifactPosition))))),
            rotationAngle: Math.abs((0, _math.toPositiveAngle)(pointRotation[1]) - (0, _math.toPositiveAngle)(artifactRotation[1]))
          };
        });
        var angle = ANGLE_MIN;
        var distance = DISTANCE_MIN;

        var filter = function filter(d, a, artifact) {
          return artifact.distance < d && artifact.angle < a && artifact.rotationAngle > 135;
        };

        do {
          storypoint.artifacts = artifacts.filter(filter.bind(undefined, distance, angle));
          angle += (ANGLE_MAX - ANGLE_MIN) / NUMBER_OF_INCREMENTS;
          distance += (DISTANCE_MAX - DISTANCE_MIN) / NUMBER_OF_INCREMENTS;
        } while (!storypoint.artifacts.length && angle < ANGLE_MAX && distance < DISTANCE_MAX);
      }

      return storypoint;
    }).filter(function (p) {
      return p.storyPointId;
    });
  },
  walls: function walls() {
    var Walls = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return Walls.map(function (wall) {
      return Object.assign({}, wall, {
        textureFront: setHostname(wall.textureFront),
        textureBack: setHostname(wall.textureBack)
      });
    });
  },
  buildingPolygons: function buildingPolygons() {
    var BuildingPolygons = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return BuildingPolygons.map(function (polygon) {
      return Object.assign({}, polygon, {
        ceilingTexture: setHostname(polygon.ceilingTexture),
        floorTexture: setHostname(polygon.floorTexture)
      });
    });
  },
  doors: function doors() {
    var Doors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    return Doors.map(function (door) {
      return Object.assign({}, door, {
        hallTexture: setHostname(door.hallTexture),
        hallTextureFront: setHostname(door.hallTextureFront),
        hallTextureBack: setHostname(door.hallTextureBack),
        topPart: Object.assign({}, door.topPart, {
          textureFront: setHostname((door.topPart || {}).textureFront),
          textureBack: setHostname((door.topPart || {}).textureBack)
        })
      });
    });
  },
  generalSettings: function generalSettings(settings) {
    return Object.assign({}, settings, {
      groundTexture: setHostname(settings.groundTexture)
    });
  },
  floorTexture: function floorTexture(texture) {
    return setHostname(texture);
  },
  template: function template(_template2, _ref3) {
    var templates = _ref3.templates;

    // On edit template is an object on view template is just the id
    if (_template2 && _template2._id) {
      return {
        templateId: _template2._id,
        uri: "".concat(FILE_SYSTEM_ROOT_URL).concat(_template2.uri)
      };
    }

    if (_template2 && _template2.length && !_template2._id && templates && templates.length) {
      var templateMatch = templates.find(function (t) {
        return t._id === _template2;
      });
      return templateMatch && templateMatch.uri && templateMatch.uri.length ? {
        templateId: _template2,
        uri: "".concat(FILE_SYSTEM_ROOT_URL).concat(templateMatch.uri)
      } : undefined; // should be undefined
    }

    return undefined; // should be undefined
  }
};

var getExhibitionBlobURI = function getExhibitionBlobURI(exhibition, bin, path) {
  if (!bin) {
    return undefined;
  }

  if (bin.preview) {
    return bin.preview;
  }

  if (bin.content && bin.contentType) {
    return (0, _buffer.toDataURL)(bin);
  }

  return "".concat(getRootURL()).concat(PATH_EXHIBITIONS, "/").concat(exhibition._id, "/").concat(path);
};

exports.getExhibitionBlobURI = getExhibitionBlobURI;

var getExhibitionBlobURIPrivate = function getExhibitionBlobURIPrivate(exhibition, bin, path) {
  if (!bin) {
    return undefined;
  }

  if (bin.preview) {
    return bin.preview;
  }

  if (bin.content && bin.contentType) {
    return (0, _buffer.toDataURL)(bin);
  }

  return "".concat(getRootURL()).concat(PATH_EXHIBITIONS_PRIVATE, "/").concat(exhibition._id, "/").concat(path);
};

exports.getExhibitionBlobURIPrivate = getExhibitionBlobURIPrivate;

var getExhibitionImage = function getExhibitionImage(exhibition) {
  var privateSpace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var defaultImage = "".concat(getRootURL()).concat(PATH_DEFAULT_IMG);

  if (!exhibition.image) {
    return defaultImage;
  }

  if (typeof exhibition.image === 'string') {
    return exhibition.image;
  } // getting file from file system


  var thumbnailEntry = exhibition.image;

  if (exhibition.user && thumbnailEntry.file && thumbnailEntry.bin && thumbnailEntry.filecreatedAt > thumbnailEntry.bin.updatedAt || thumbnailEntry.file && !thumbnailEntry.bin) {
    var _exhibition$user, _exhibition$image;

    return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat((exhibition === null || exhibition === void 0 ? void 0 : (_exhibition$user = exhibition.user) === null || _exhibition$user === void 0 ? void 0 : _exhibition$user._id) || (exhibition === null || exhibition === void 0 ? void 0 : exhibition.user), "/").concat(exhibition === null || exhibition === void 0 ? void 0 : (_exhibition$image = exhibition.image) === null || _exhibition$image === void 0 ? void 0 : _exhibition$image.file);
  }

  if (privateSpace) return exhibition.image.uri || getExhibitionBlobURIPrivate(exhibition, exhibition.image.bin, "".concat(PATH_EXHIBITION_IMAGE, "/bin")) || defaultImage;
  return exhibition.image.uri || getExhibitionBlobURI(exhibition, exhibition.image.bin, "".concat(PATH_EXHIBITION_IMAGE, "/bin")) || defaultImage;
};

exports.getExhibitionImage = getExhibitionImage;

var getExhibitionAudio = function getExhibitionAudio(exhibition) {
  var privateSpace = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (!exhibition.audio) {
    return null;
  }

  if (typeof exhibition.audio === 'string') {
    return exhibition.audio;
  }

  if (exhibition.audio.uri) {
    return (0, _media.createAudioStream)(exhibition.audio.uri);
  } // getting file from file system


  var audioEntry = exhibition.audio;
  var userId;

  if ((0, _typeof2["default"])(exhibition.user) === 'object') {
    userId = exhibition.user._id;
  } else {
    userId = exhibition.user;
  }

  if (audioEntry && audioEntry.file) return "".concat(FILE_SYSTEM_ROOT_URL, "/users/").concat(userId, "/").concat(exhibition.audio.file);
  if (privateSpace) return getExhibitionBlobURIPrivate(exhibition, exhibition.audio.bin, "".concat(PATH_EXHIBITION_AUDIO, "/bin"));
  return getExhibitionBlobURI(exhibition, exhibition.audio.bin, "".concat(PATH_EXHIBITION_AUDIO, "/bin"));
};

exports.getExhibitionAudio = getExhibitionAudio;

var importExhibition = function importExhibition(exhibitionImport) {
  if (!exhibitionImport) {
    return exhibitionImport;
  }

  return Object.assign({
    timestamp: Date.now()
  }, Object.keys(exhibitionImport).reduce(function (importModel, prop) {
    var item = (importMappings[prop] || function (i) {
      return i;
    })(exhibitionImport[prop]);

    return item === undefined ? importModel : Object.assign(importModel, (0, _defineProperty2["default"])({}, prop, item));
  }, {}));
};

exports.importExhibition = importExhibition;

var exportExhibition = function exportExhibition(exhibition, artifacts, storypoints, templates) {
  if (!exhibition) return undefined;
  var exhibitionExport = {
    exhibitionId: exhibition._id,
    exhibitionTitle: exhibition.title,
    exhibitionDescription: exhibition.description,
    exhibitionAudio: getExhibitionAudio(exhibition),
    timestamp: Date.now(),
    startingPosition: '(0, 0, -12)'
  }; // Takes the exhibition model and parses each element with export mappings

  Object.keys(exhibition.model || {}).forEach(function (prop) {
    var item = (exportMappings[prop] || function (i) {
      return i;
    })(exhibition.model[prop], {
      artifacts: artifacts,
      storypoints: storypoints,
      exhibition: exhibition.model,
      templates: templates
    });

    if (item !== undefined) {
      exhibitionExport[prop] = item;
    }
  });
  return exhibitionExport;
};

exports.exportExhibition = exportExhibition;

var exportArtifact = function exportArtifact(artifact) {
  return !artifact || !artifact.image ? Promise.resolve(null) : new Promise(function (resolve, reject) {
    var image = new Image();

    image.onload = function () {
      var scaleX = 0;
      var scaleY = 0;
      var scaleZ = 0;

      if (artifact.type === 'image') {
        var _ref4 = artifact.dimensions || {},
            width = _ref4.width,
            height = _ref4.height;

        if (width) {
          height = height || image.height / image.width * width;
        }

        if (height) {
          width = width || image.width / image.height * height;
        }

        height = height || DEFAULT_ARTIFACT_SCALE;
        width = width || image.width / image.height * height;
        scaleX = width;
        scaleY = height;
      }

      resolve({
        artifactId: artifact._id,
        artifactType: artifact.type,
        title: artifact.title,
        description: artifact.caption,
        position: artifact.position,
        rotation: artifact.rotation,
        scaleX: scaleX,
        scaleY: scaleY,
        scaleZ: scaleZ,
        hasCanvas: (artifact.model || {}).hasCanvas,
        nonInteractive: (artifact.model || {}).nonInteractive,
        textProperties: {
          fontSize: (0, _obj.getProperty)(artifact, 'model.textProperties.fontSize') || DEFAULT_FONT_SIZE,
          fontStyle: (0, _obj.getProperty)(artifact, 'model.textProperties.fontStyle'),
          alignment: (0, _obj.getProperty)(artifact, 'model.textProperties.alignment'),
          lineSpacing: 1
        },
        colorTint: "".concat((artifact.model || {}).colourTint || DEFAULT_ARTIFACT_COLOUR, "ff"),
        uri: (0, _artifact.getArtifactURI)(artifact),
        thumbnail: (0, _artifact.getVideoThumbnail)(artifact),
        timestamp: Date.now()
      });
    };

    image.onerror = function (err) {
      return reject(err);
    };

    image.src = (0, _artifact.getArtifactImage)(artifact);
  });
};

exports.exportArtifact = exportArtifact;

var exportStorypoint = function exportStorypoint(storypoint) {
  return Promise.resolve(storypoint && {
    storyPointId: storypoint._id,
    title: storypoint.title,
    pointDescription: storypoint.description,
    pointOrder: storypoint.order,
    colour: storypoint.colour,
    timestamp: Date.now()
  });
};

exports.exportStorypoint = exportStorypoint;