import React, { useState, useEffect } from 'react';
import Loader from '../../generic/Loader';
import ExhibitionInfoFirstRow from './ExhibitionInfoFirstRow';
import ExhibitionInfoBeforeComments from './ExhibitionInfoBeforeComments';
import { API_STATUS, getAuthUser, getApiStatus, getApiResource } from '../../../reducers';
import { compose, withState } from '../../../enhancers';
import CommentList from '../../comments/lists/CommentList';
import useWindowSize from '../../../hooks/windowSize';

export const ExhibitionInfoView = ({
  currentUser = { _id: null },
  exhibition = { _id: null },
  categories = [],
  ready = true,
}) => {
  const [isSmall, setIsSmall] = useState(false);
  const size = useWindowSize();
  useEffect(() => {
    setIsSmall(size.width < 1280);
  }, [size.width]);

  return ready ? (
    exhibition._id && (
      <div style={{ padding: '20px 10px 0px 10px' }}>
        <ExhibitionInfoFirstRow
          categories={categories}
          currentUserId={currentUser._id}
          exhibition={exhibition}
        />
        <ExhibitionInfoBeforeComments exhibition={exhibition} currentUserId={currentUser._id} />
      </div>
    )
  ) : (
    <Loader />
  );
};

const mapState = (state, { exhibitionId }) => ({
  ready: getApiStatus(state, `exhibitions/${exhibitionId}`) === API_STATUS.IDLE,
  categories: Object.values(getApiResource(state, 'categories')),
  currentUser: getAuthUser(state),
});

const ExhibitionInfo = compose(withState(mapState))(ExhibitionInfoView);

export default ExhibitionInfo;
