import React, { useEffect, useState } from 'react';
import { __ } from 'artsteps2-common';
import { Redirect } from 'react-router';

import config from 'artsteps2-config/public.json';
import MetaTags from '../components/layouts/MetaTags';
import Link from '../components/generic/Link';
import DefaultLayout from '../components/layouts/DefaultLayout';
import ExhibitionList from '../components/exhibitions/lists/ExhibitionList';
import {
  API_KEYS,
  getUIProperty,
  getApiResource,
  getAuthUser,
  getApiStatus,
  API_STATUS,
} from '../reducers';
import { withState, compose, withDispatch } from '../enhancers';
import { setUIProperty, apiGET } from '../actions';

import Grid from '@material-ui/core/Grid';

import {
  BodyWithPadding,
  StyledButton,
  Text,
  ExhibitionThumbnailImage,
  ExhibitionThumbnailDiv,
} from '../styles/GenericStyled';
import colors from '../styles/colors';
import ExhibitionViewFrame from '../components/exhibitions/viewer/ExhibitionViewFrame';
import GroupList from '../components/exhibitions/lists/GroupList';

const createQuery = ({ filter, limit, offset, sort, include, populate }) => ({
  filter,
  sort,
  page: { limit, offset },
  include,
  populate,
});

const query = {
  filter: {
    published: true,
    staffPick: true,
  },
  populate: ['user'],
  include: [
    'categories',
    'description',
    `views.${API_KEYS.META}.totalCount`,
    `likes.${API_KEYS.META}.totalCount`,
    'visits',
    'rating',
    '_v1Rating',
    'staffPick',
  ],
};

const GOOGLE_PLAY_URI = config.applications.playURI;
const APP_STORE_URI = config.applications.itunesURI;

const HomePageView = ({
  isPrivateSpace,
  space = {},
  currentUser,
  ready = false,
  homePageExhibition,
  setHomePageExhibition,
  exhibitions,
}) => {
  const [enterExpo, setEnterExpo] = useState(false);

  useEffect(() => {
    setHomePageExhibition();
  }, []);

  return (
    <DefaultLayout>
      <MetaTags title={__('intro.preview_header1')} description={__('intro.preview_header2')} />

      <Grid
        style={{
          marginBottom: '20px',
          marginTop: '20px',
        }}
        container
        justify="space-evenly"
      >
        <Grid item xs={11}>
          {homePageExhibition && enterExpo ? (
            <ExhibitionViewFrame exhibitionId={homePageExhibition._id} />
          ) : (
            <>
              <ExhibitionThumbnailDiv>
                <ExhibitionThumbnailImage src="/images/templates/EntranceThumbnail.jpg" />
                <StyledButton style={{ position: 'absolute' }} onClick={e => setEnterExpo(true)}>
                  ENTER EXPO
                </StyledButton>
              </ExhibitionThumbnailDiv>
            </>
          )}
        </Grid>
      </Grid>

      <div
        className="ui segment basic home-page"
        style={{ marginTop: isPrivateSpace ? '5rem' : '1rem' }}
      >
        <div className="ui padded segment basic vertical">
          <BodyWithPadding padding="0% 3% 0% 3%">
            <Text type="body1">
              The AthEnsVtradE virtual fair is an initiative by the Athens Chamber of Commerce and
              Industry, aiming to promote businesses through an innovative virtual reality platform.
              The Athens Chamber of Commerce and Industry is bringing together businesses from all
              industries to participate in business to business and business to consumer activities
              in a safe virtual environment.
            </Text>
            <br />
            <Text type="body1">
              Browse through the exhibitors’ booths, discover products and services they offer, and
              communicate directly with the companies’ representatives for an interactive customer
              experience. Enjoy your virtual tour of the AthEnsVtradE!
            </Text>
            <h2 className="ui header">Exhibitors</h2>
          </BodyWithPadding>
          <GroupList sort="title" include={['categories']} />
          <div className="pager-buttons">
            <Link to="/explore">
              <StyledButton
                type="secondary"
                mycolor={colors.ourBlack}
                background={colors.ourWhite}
                backgroundhover={colors.ourBlack}
                mycolorhover={colors.ourWhite}
                border={1}
              >
                {__('explore_exhibitions')}
              </StyledButton>
            </Link>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};

const mapState = state => ({
  homePageExhibition: getUIProperty(state, 'homePageExhibition'),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  unverifiedOpen: getUIProperty(state, 'unverifiedNotificationOpen'),
  currentUser: getAuthUser(state),
  ready:
    getApiStatus(state, `spaces/${window.location.hostname.split('.')[0]}`) === API_STATUS.IDLE,
  exhibitions: getApiResource(state, 'exhibitions'),
});

const mapDispatch = dispatch => ({
  setHomePageExhibition: () =>
    dispatch(apiGET('exhibitions', createQuery(query))).then(({ response: responseExhibition }) => {
      if (responseExhibition)
        return dispatch(setUIProperty('homePageExhibition', Object.values(responseExhibition)[0]));
    }),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  onUnverifiedNotificationClosed: () =>
    dispatch(setUIProperty('unverifiedNotificationOpen', false)),
});

const HomePage = compose(withState(mapState), withDispatch(mapDispatch))(HomePageView);

export default HomePage;
