import React from 'react';
import Loader from '../../generic/Loader';
import ArtifactLibrary from './ArtifactLibrary';

export const ArtifactListView = ({
  artifacts = [],
  controls = false,
  ready = true,
  exhibitionId,
  showInFolders,
}) => (
  <>
    <ArtifactLibrary
      showInFolders={showInFolders}
      artifacts={artifacts}
      controls={controls}
      exhibitionId={exhibitionId}
    />
    {ready || <Loader inverted={false} />}
  </>
);

const ArtifactList = ArtifactListView;

export default ArtifactList;
