import React, { useState, useEffect } from 'react';
import { __ } from 'artsteps2-common';
import publicConfig from 'artsteps2-config/public.json';

import Dialog from '../../../generic/Dialog';
import { getApiResource, getUIProperty } from '../../../../reducers';
import { setUIProperty, apiGET } from '../../../../actions';
import {
  compose,
  withState,
  withDispatch,
  withLifecycle,
  withLocalState,
} from '../../../../enhancers';
import createYourOwnImage from '../../../../styles/images/templates/CreateYourOwn.png';
import usePrevious from '../../../../hooks/usePrevious';
import { ImageButton, TemplateList, ToolbarSection } from '../ExhibitionEditStyled';
import MyToolTip from '../../../generic/MyToolTip';
import { Text } from '../../../../styles/GenericStyled';
import useWindowSize from '../../../../hooks/windowSize';

export const API_URI = `${publicConfig.api.hostname}`;

export const ExhibitionTemplatePanelView = ({
  templates,
  selectedTemplate = {},
  setSelectedTemplate = () => Promise.resolve(false),
  isExhibitionEmpty,
  displayDialog = false,
  onCloseDialog = () => Promise.resolve(false),
  openDialog = () => Promise.resolve(false),
  requestIsEmpty = () => Promise.resolve(false),
  onTogglePlacement = () => Promise.resolve(false),
  importedAt,
}) => {
  const [templateClicked, setTemplateClicked] = useState(undefined);

  const prevTemplateClicked = usePrevious(templateClicked);

  const size = useWindowSize();

  const calcHeight = () => {
    return `${(size.width * 16.5 * 0.005).toFixed(0)}px`;
  };

  useEffect(() => {
    if (templateClicked !== undefined && prevTemplateClicked !== templateClicked) {
      if (isExhibitionEmpty) {
        setSelectedTemplate(templateClicked);
        requestIsEmpty();
        onTogglePlacement(); //not sure if we keep this
      } else {
        openDialog();
      }
    }
  }, [templateClicked]);

  return (
    <div className="exhibition-space-editor">
      <ToolbarSection>
        <TemplateList>
          <Text type="h7">{__('exhibition_templates')}</Text>
          {templates.map(t =>
            t.public ? (
              <MyToolTip title={__(t.title)} placement="top" key={t.uri}>
                <ImageButton
                  height={calcHeight()}
                  width="100%"
                  style={{ backgroundImage: `url(${t.image})` }}
                  selected={+(templateClicked === t)}
                  onKeyPress={event => event.keyCode === 13 && setTemplateClicked(t)}
                  onClick={() => {
                    setTemplateClicked(t);
                  }}
                />
              </MyToolTip>
            ) : (
              <></>
            ),
          )}
          <div>
            <h5>{__('more_to_come_soon')}</h5>
          </div>
          <Dialog
            open={displayDialog}
            title={__('content_deletion')}
            message={__('confirm_content_deletion')}
            type="warning"
            onConfirm={() => {
              onTogglePlacement();
              setSelectedTemplate(templateClicked);
              onCloseDialog();
              requestIsEmpty();
            }}
            onReject={() => {
              setTemplateClicked(undefined);
              onCloseDialog();
            }}
          />
        </TemplateList>
      </ToolbarSection>
    </div>
  );
};

const mapState = (state, { exhibitionId }) => ({
  selectedTemplate: getUIProperty(state, `exhibitions/${exhibitionId}/selectedTemplate`),
  templates: Object.values(getApiResource(state, 'templates')),
  displayDialog: getUIProperty(state, `exhibitions/${exhibitionId}/displayDialog`),
  exhibitionNotEmpty:
    !!(
      getUIProperty(state, `exhibitions/${exhibitionId}/export/artifacts`) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/import/artifacts`) ||
      []
    ).length ||
    !!(
      getUIProperty(state, `exhibitions/${exhibitionId}/export/displayCases`) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/import/displayCases`) ||
      []
    ).length ||
    !!(
      getUIProperty(state, `exhibitions/${exhibitionId}/export/walls`) ||
      getUIProperty(state, `exhibitions/${exhibitionId}/import/walls`) ||
      []
    ).length,
  isExhibitionEmpty: !!getUIProperty(state, `exhibitions/${exhibitionId}/isEmpty`),
  importedAt: getUIProperty(state, `exhibitions/${exhibitionId}/importedAt`),
});

const mapDispatch = (dispatch, { exhibitionId, requestingIsEmpty }) => ({
  fetchTemplates: () => dispatch(apiGET('templates')),
  setSelectedTemplate: template => {
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/selectedTemplate`, template));
  },
  onCloseDialog: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayDialog`, false)),
  openDialog: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayDialog`, true)),
  onTogglePlacement: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/placingStructure`, undefined)),
  requestIsEmpty: () => {
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/requestingIsEmpty`, !requestingIsEmpty));
  },
});

const lifecycleMap = {
  onDidMount: ({ fetchTemplates }) => fetchTemplates(),
};

const ExhibitionTemplatePanel = compose(
  withLocalState('templateClicked', 'onTemplateClicked', false),
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionTemplatePanelView);

export default ExhibitionTemplatePanel;
