import React, { useEffect, useRef, useState } from 'react';
import InputRange from 'react-simple-range';
import config from 'artsteps2-config/public.json';
import { __ } from 'artsteps2-common';
import utils from '../../utils';
import Loader from '../generic/Loader';
import { setUIProperty, apiGET } from '../../actions';
import { API_STATUS, getUIProperty, getApiResource, getApiStatus } from '../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import License from './License';

const MAP_WIDTH = 256;
const MAP_HEIGHT = 128;
const MAP_ZOOM = 16;

export const ArtifactViewerView = ({
  artifact = {},
  license,
  ready = true,
  currentImageIndex = 0,
  displayInformation = false,
  volume = 0,
  playback = true,
  onNextImage = () => Promise.resolve(false),
  onPreviousImage = () => Promise.resolve(false),
  onDisplayInformation = () => Promise.resolve(false),
  onSetVolume = () => Promise.resolve(false),
  onSetPlayback = () => Promise.resolve(false),
  isPrivateSpace,
  fullscreenMode,
  widthFirst,
  exhibitionId,
  space,
}) => {
  const myRef = useRef();

  const [images, setImages] = useState([]);

  useEffect(() => {
    setImages(getImageURIs);
  }, [fullscreenMode, artifact]);

  const getMapURI = () =>
    artifact.location &&
    !!artifact.location.lat &&
    !!artifact.location.lng &&
    `https://maps.googleapis.com/maps/api/staticmap?markers=${artifact.location.lat},${
      artifact.location.lng
    }&size=${MAP_WIDTH}x${MAP_HEIGHT}&maptype=roadmap&key=${config.maps.apikey}&zoom=${artifact
      .location.zoom || MAP_ZOOM}`;

  const getImageURIs = () => {
    if (artifact.covers && artifact.covers.length) {
      return artifact.covers.map(
        (cover, idx) => cover.uri || `/api/artifacts/${artifact._id}/covers/${idx}/bin`,
      );
    }

    if (artifact.type === 'text') return [];

    if (fullscreenMode) {
      return [utils.artifact.getArtifactImageFull(artifact)];
    }
    return [utils.artifact.getArtifactImage(artifact)];
  };

  const renderAdditionalInformation = () => {
    if (!artifact.information) {
      return null;
    }
    if (!displayInformation) {
      return (
        <button
          className="anchor read-more"
          onKeyPress={event => event.keyCode === 13 && onDisplayInformation()}
          onClick={onDisplayInformation}
        >
          {__('read_more')}
          <i className="ui icon angle double right" />
        </button>
      );
    }
    return <p>{utils.text.toMultiline(artifact.information)}</p>;
  };

  const renderVideoControls = () =>
    artifact.type === 'video' && (
      <div className="video-controls">
        <button onClick={() => onSetPlayback(!playback)}>
          <i className={`ui icon ${playback ? 'pause' : 'play'}`} />
        </button>
        <button onClick={() => onSetVolume(volume === 0 ? 0.5 : 0)}>
          <i
            className={`ui icon volume ${volume === 0 ? 'off' : ''}${volume > 0.5 ? 'up' : ''}${
              volume > 0 && volume <= 0.5 ? 'down' : ''
            }`}
          />
        </button>
        <div className="input-range">
          <InputRange
            max={100}
            min={0}
            step={1}
            value={volume * 100}
            thumbSize={16}
            thumbColor="#fff"
            onChange={({ value }) => onSetVolume(value / 100)}
          />
        </div>
      </div>
    );

  return ready ? (
    <div className="artifact-viewer">
      <h3>{artifact.title}</h3>
      <div ref={myRef} className="artifact-image">
        {images && images.length > 0 && artifact.type !== 'object' && (
          <img
            onContextMenu={e => e.preventDefault()}
            className={fullscreenMode ? 'doubleclicked-image' : 'clicked-image'}
            src={images[currentImageIndex]}
            alt=""
          />
        )}
        {images.length < 2 && artifact.type === 'object' && (
          <canvas
            className="object-preview"
            width={
              myRef && myRef.current ? ((60 * myRef.current.clientWidth) / 100).toFixed(0) : 280
            }
            height={
              myRef && myRef.current ? ((60 * myRef.current.clientHeight) / 100).toFixed(0) : 280
            }
            ref={canvas =>
              utils.webgl
                .createObjectPreview(canvas, utils.artifact.getArtifactURI(artifact))
                .catch(() => false)
            }
          />
        )}
        {currentImageIndex > 0 && (
          <button className="ui circular secondary icon button previous" onClick={onPreviousImage}>
            <i className="ui icon left caret" />
          </button>
        )}
        {images.length > 1 && currentImageIndex < images.length - 1 && (
          <button className="ui circular secondary icon button next" onClick={onNextImage}>
            <i className="ui icon right caret" />
          </button>
        )}
      </div>
      {renderVideoControls()}
      {artifact.audio && (artifact.audio.bin || artifact.audio.uri || artifact.audio.file) && (
        <div className="artifact-audio">
          <audio autoPlay controls src={utils.artifact.getArtifactAudio(artifact)}>
            <track kind="captions" src={`data:text/plain,${__('preview')}`} />
          </audio>
        </div>
      )}
      <div className="artifact-info">
        {artifact.uri && (
          <div className="text center aligned">
            <a target="_blank" rel="noopener noreferrer" href={artifact.uri}>
              {artifact.uri}
            </a>
          </div>
        )}
        <div>{utils.text.toMultilineWithLink(artifact.caption, exhibitionId)}</div>
        {renderAdditionalInformation()}
        {artifact.location && !!artifact.location.lat && !!artifact.location.lng && (
          <img src={getMapURI()} alt={__('location')} />
        )}
        {artifact.license && <License licenseId={artifact.license} />}
      </div>
    </div>
  ) : (
    <Loader />
  );
};

const mapState = (state, { artifactId, artifact, exhibitionId }) => ({
  artifact: getApiResource(state, `artifacts/${artifactId}`),
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  volume: getUIProperty(state, `artifacts/${artifactId}/volume`),
  playback: getUIProperty(state, `artifacts/${artifactId}/playback`),
  ready: getApiStatus(state, `artifacts/${artifactId}`) === API_STATUS.IDLE,
  currentImageIndex: getUIProperty(state, `artifacts/${artifactId}/imageIndex`),
  displayInformation: getUIProperty(state, `artifacts/${artifactId}/displayInformation`),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  fullscreenMode: getUIProperty(state, `exhibitions/${exhibitionId}/fullscreenArtifactCard`),
  widthFirst: getUIProperty(state, `exhibitions/${exhibitionId}/widthFirst`),
});

const mapDispatch = (dispatch, { artifactId, currentImageIndex, exhibitionId }) => ({
  onFetchArtifact: () => dispatch(apiGET(`artifacts/${artifactId}`)),
  onNextImage: () =>
    dispatch(setUIProperty(`artifacts/${artifactId}/imageIndex`, (currentImageIndex || 0) + 1)),
  onPreviousImage: () =>
    dispatch(setUIProperty(`artifacts/${artifactId}/imageIndex`, (currentImageIndex || 0) - 1)),
  onDisplayInformation: () =>
    dispatch(setUIProperty(`artifacts/${artifactId}/displayInformation`, true)),
  onSetVolume: volume => dispatch(setUIProperty(`artifacts/${artifactId}/volume`, volume)),
  onSetPlayback: playback => dispatch(setUIProperty(`artifacts/${artifactId}/playback`, playback)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchArtifact }) => onFetchArtifact(),
  onDidUpdate: (props, { onFetchArtifact, artifactId }) =>
    props.artifactId !== artifactId && onFetchArtifact(),
};

const ArtifactViewer = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ArtifactViewerView);

export default ArtifactViewer;
