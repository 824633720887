import React from 'react';
import Grid from '@material-ui/core/Grid';
import videoPreviewWhole from '../../styles/images/privateSpaces/whole.mp4';
import { InfoStyled, VideoArea, CarouselGrid } from '../../styles/PrivateSpacesPageStyled';

const CustomCarousel = ({ media, infos }) => (
  <CarouselGrid container justify="space-around" wrap="wrap">
    <Grid item style={{ marginLeft: '100px', width: '100px' }} xs={4}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '100%' }}
      >
        {infos.map((info, index) => (
          <Grid style={{ width: '100%' }} key={index} item>
            <InfoStyled active={1}>{info}</InfoStyled>
          </Grid>
        ))}
      </Grid>
    </Grid>
    <Grid style={{ position: 'relative' }} item xs={7}>
      <VideoArea active autoPlay loop>
        <source src={videoPreviewWhole} type="video/mp4" />
      </VideoArea>
    </Grid>
  </CarouselGrid>
);

export default CustomCarousel;
