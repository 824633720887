import ReactPixel from 'react-facebook-pixel';
import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from '../styles/images/logo-artsteps.png';
import {
  Text,
  TextButton,
  StyledButton,
  HorizontalItems,
  StyledTextField,
  VerticalItems,
  UnderLinedLink,
} from '../styles/GenericStyled';

import { apiGET, apiPOST, authRegister, setLocation, addMessage, setUIProperty } from '../actions';

import { withState, withDispatch, withLifecycle, compose } from '../enhancers';
import {
  getAuthToken,
  getUIProperty,
  getApiResource,
  AUTH_STATUS,
  getAuthUser,
  getAuthStatus,
} from '../reducers';
import MetaTags from '../components/layouts/MetaTags';
import Logo from '../components/privateSpaces/Logo';

import OAuthButton from '../components/users/forms/OAuthButton';

const services = ['google'];

const Registeriew = ({
  openRegister,
  setOpenRegister,
  setOpenLogin,
  space,
  isPrivateSpace,
  onRegister,
  onAddMessage,
  page,
  onRedirect,
  onSubscribe,
}) => {
  const [data, setData] = useState({
    email: '',
    name: '',
    password: '',
    confirmation: '',
    registrationCode: '',
    date: '2002-05-24',
  });
  const [isValid, setIsValid] = useState([true, true, true, true, true, true]); // for form validation
  const [ready, setReady] = useState(true);

  const onChange = e => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    setReady(false);
    setIsValid([
      data.name,
      data.email,
      data.password,
      data.confirmation && data.password === data.confirmation,
      data.registrationCode,
      data.date,
    ]);
    if (!data.name) {
      setReady(true);
      return onAddMessage({ title: __('empty_field_name'), type: 'error' });
    }
    if (!data.email) {
      setReady(true);
      return onAddMessage({ title: __('empty_field_email'), type: 'error' });
    }
    if (!data.password) {
      setReady(true);
      return onAddMessage({ title: __('empty_field_password'), type: 'error' });
    }
    if (!data.confirmation) {
      setReady(true);
      return onAddMessage({
        title: __('confirm_your_password'),
        type: 'error',
      });
    }
    if (data.password !== data.confirmation) {
      setReady(true);
      return onAddMessage({ title: __('password_mismatch'), type: 'error' });
    }
    return onRegister({
      emails: [{ address: data.email }],
      profile: { name: data.name, birthday: data.date },
      services: {
        password: data.password,
      },
      spaces: isPrivateSpace ? { registrationCode: data.registrationCode } : undefined,
    }).then(({ response }) => {
      if (response.error) {
        setReady(true);
        onAddMessage({ title: __(response.error), type: 'error' });
        return;
      }
      ReactPixel.track('CompleteRegistration', { status: true });
      if (data.subscription) {
        onSubscribe(data.email);
      }
      setReady(true);
      setOpenRegister(false);
      onRedirect(`/`);
    });
  };

  return (
    <VerticalItems margin="20px">
      <MetaTags title={__('sign_in')} description={__('sign_in_header')} />

      {isPrivateSpace ? (
        <Logo space={space} width="100px" maxWidth="100px" maxHeight="100px" />
      ) : (
        <img style={{ width: '80%' }} src={logo} alt={__('brand_name')} />
      )}

      <Text type="h6" align="center">
        Start browsing and creating VR Exhibitions
      </Text>

      <StyledTextField
        id="name"
        name="name"
        placeholder="Name"
        value={data.name}
        onChange={e => onChange(e)}
        fullWidth
        variant="outlined"
        required
        error={!isValid[0]}
      />
      <StyledTextField
        id="email"
        name="email"
        placeholder="Email"
        value={data.email}
        onChange={e => onChange(e)}
        fullWidth
        variant="outlined"
        required
        error={!isValid[1]}
      />
      <StyledTextField
        type="password"
        id="password"
        name="password"
        placeholder="Create Password"
        value={data.password}
        onChange={e => onChange(e)}
        fullWidth
        variant="outlined"
        required
        error={!isValid[2]}
      />
      <StyledTextField
        type="password"
        id="confirmation"
        name="confirmation"
        placeholder="Repeat Password"
        value={data.confirmation}
        onChange={e => onChange(e)}
        fullWidth
        variant="outlined"
        required
        error={!isValid[3]}
      />
      {isPrivateSpace && (
        <StyledTextField
          type="password"
          id="registrationCode"
          name="registrationCode"
          placeholder="Registration Code"
          value={data.registrationCode}
          onChange={e => onChange(e)}
          fullWidth
          variant="outlined"
          required
          error={!isValid[4]}
        />
      )}
      <StyledTextField
        type="date"
        id="date"
        name="date"
        variant="outlined"
        value={data.date}
        onChange={e => onChange(e)}
        label="Birthday"
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        required
      />
      <Text type="body3">
        By continuing you agree to Artsteps' &nbsp;
        <UnderLinedLink to="/article/privacy-policy" onClick={() => setOpenRegister(false)}>
          Privacy Policy
        </UnderLinedLink>
        &nbsp; and use of cookies.
      </Text>

      <StyledButton
        onClick={() => onSubmit()}
        minwidth="150px"
        background="#FE7670"
        mycolor="white"
        type="secondary"
      >
        <HorizontalItems>
          Sign Up{' '}
          {!ready && (
            <CircularProgress
              style={{
                display: 'block',
                padding: '5px',
                width: '30px',
                height: '30px',
                marginLeft: '10px',
              }}
            />
          )}
        </HorizontalItems>
      </StyledButton>
      <Text type="h6">Or</Text>
      {services.map(service => (
        <OAuthButton
          service={service}
          text="Sign Up"
          // redirectionURL={redirectionURL}
          key={service}
        />
      ))}
      <TextButton
        onClick={() => {
          if (page) {
            onRedirect('/login');
          } else {
            setOpenRegister(false);
            setOpenLogin(true);
          }
        }}
      >
        Already a member? Sign in.
      </TextButton>
    </VerticalItems>
  );
};

const mapState = state => ({
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  token: getAuthToken(state),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  currentUser: getAuthUser(state),
  ready: getAuthStatus(state) === AUTH_STATUS.IDLE,
});

const mapDispatch = dispatch => ({
  onRedirect: page => dispatch(setLocation(page)),
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  onRegister: (username, password) => dispatch(authRegister(username, password)),
  onAddMessage: message => dispatch(addMessage(message, 'Register')),
  onSubscribe: mail => dispatch(apiPOST('subscribers', { mail })),
});

const Register = compose(withState(mapState), withDispatch(mapDispatch))(Registeriew);

export default Register;
