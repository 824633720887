import { mergeDeepRight } from 'ramda';

import {
  AUTH_NAMESPACE,
  AUTH_ACTION_REQUEST_AUTHORIZATION,
  AUTH_ACTION_REQUEST_RENEWAL,
  AUTH_ACTION_AUTHORIZE,
  AUTH_ACTION_DEAUTHORIZE,
  AUTH_ACTION_RENEW,
} from '../actions/auth';

import { API_NAMESPACE, API_ACTION_GET_SUCCESS, API_ACTION_PATCH_SUCCESS } from '../actions/api';

export const AUTH_STATUS = {
  IDLE: undefined,
  AUTHENTICATING: 'authenticating',
  REQUEST_REFRESH_AUTHENTICATION: 'token-renew',
};

const initialState = {
  status: AUTH_STATUS.IDLE,
  token: undefined,
  user: undefined,
};

export default (state = initialState, { type = '', response = {} }) => {
  switch (type) {
    case `${AUTH_NAMESPACE}/${AUTH_ACTION_REQUEST_AUTHORIZATION}`: {
      return {
        ...state,
        status: AUTH_STATUS.AUTHENTICATING,
      };
    }
    case `${AUTH_NAMESPACE}/${AUTH_ACTION_REQUEST_RENEWAL}`: {
      return {
        ...state,
        status: AUTH_STATUS.REQUEST_REFRESH_AUTHENTICATION,
      };
    }
    case `${AUTH_NAMESPACE}/${AUTH_ACTION_AUTHORIZE}`: {
      return {
        ...state,
        user: getAuthExpiration(response) > 0 ? { ...response.user } : undefined,
        token: response.token,
        status: AUTH_STATUS.IDLE,
      };
    }
    case `${AUTH_NAMESPACE}/${AUTH_ACTION_RENEW}`: {
      return {
        ...state,
        token: response.token,
        status: AUTH_STATUS.IDLE,
      };
    }
    case `${AUTH_NAMESPACE}/${AUTH_ACTION_DEAUTHORIZE}`: {
      return {
        ...state,
        user: undefined,
        token: undefined,
        status: AUTH_STATUS.IDLE,
      };
    }
    case `${API_NAMESPACE}/users/${(state.user || {})._id}/${API_ACTION_PATCH_SUCCESS}`: {
      return { ...state, user: mergeDeepRight(state.user, response) };
    }
    case `${API_NAMESPACE}/users/${(state.user || {})._id}/${API_ACTION_GET_SUCCESS}`: {
      return { ...state, user: mergeDeepRight(state.user, response) };
    }
    default: {
      return getAuthExpiration(state) > 0 ? state : { ...state, user: undefined, token: undefined };
    }
  }
};

export const getAuthUser = state => state.user;
export const getAuthToken = state => state.token;
export const getAuthStatus = state => state.status;
export const getAuthUserRoles = state =>
  (state.user && state.user.roles && state.user.roles.__global_roles__) || [];
export const getAuthExpiration = state => {
  try {
    return JSON.parse(atob(state.token.split('.')[1])).exp * 1000 - Date.now();
  } catch (err) {
    return -1;
  }
};
export const getAuthCurrentSpace = state => state.user && state.user.currentSpace;
