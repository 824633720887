import React from 'react';
import {
  VerticalItems,
  BodyWithPadding,
  StyledButton,
  StyledTextField,
  Text,
  UnderLinedLink,
} from '../../../styles/GenericStyled';
import Switch from '@material-ui/core/Switch';

const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

const FakeForm = ({
  setFakeCurrentUser,
  setOpenFakeForm,
  onWPVisitor,
  onAddMessage = () => Promise.resolve(false),
}) => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [subscription, setSubscription] = React.useState(false);

  const handleClick = () => {
    if (!name) {
      return onAddMessage({
        type: 'error',
        title: 'Name is missing',
      });
    } else if (!email) {
      return onAddMessage({
        type: 'error',
        title: 'Mail is Missing',
      });
    } else if (!email.match(/^\S+@\S+\.\S+$/)) {
      return onAddMessage({
        type: 'error',
        title: 'Mail is invalid',
      });
    }

    const user = { isFake: true, _id: uuidv4(), profile: { name, email, role: 'client' } };
    setFakeCurrentUser(user);

    onWPVisitor({ name, email, id: user._id, newsletter: subscription });

    setOpenFakeForm(false);
  };

  return (
    <BodyWithPadding padding="3%">
      <VerticalItems style={{ height: '100%', justifyContent: 'center' }} margin="24px">
        <Text type="h6" align="center">
          Please fill in your personal details
        </Text>
        <StyledTextField
          placeholder="Name"
          id="name-text-field"
          required
          variant="outlined"
          fullWidth
          onChange={e => setName(e.target.value)}
        />
        <StyledTextField
          placeholder="Email"
          id="email-text-field"
          required
          variant="outlined"
          fullWidth
          onChange={e => setEmail(e.target.value)}
          onKeyDown={e => e.keyCode === 13 && handleClick()}
        />

        <Text type="body3">
          Subscribe to our newsletter
          <Switch
            checked={subscription}
            onChange={e => setSubscription(e.target.checked)}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </Text>
        <Text type="body3">
          By continuing you agree to ACCI' &nbsp;
          <UnderLinedLink to="/article/privacy-policy">Privacy Policy</UnderLinedLink>
          &nbsp; and use of cookies.
        </Text>
        <StyledButton type="secondary" onClick={handleClick}>
          Submit
        </StyledButton>
      </VerticalItems>
    </BodyWithPadding>
  );
};

export default FakeForm;
