import React from 'react';
import { __ } from 'artsteps2-common';

import Form from '../../generic/forms/Form';

const IMAGE_WIDTH = 256;
const IMAGE_HEIGHT = 256;

const PrivateSpaceEditor = ({
  form = { data: {} },
  saveDisabled = false,
  extraFields = [],
  onClose,
  onRemove,
  setOpen = () => true,
  onSubmit = () => Promise.resolve(false),
  title,
}) => (
  <div style={{ width: '60%', margin: 'auto' }}>
    <h1 style={{ textAlign: 'center' }}>{title}</h1>
    <div className="artifact-editor">
      <Form
        form={form}
        onSubmit={() => {
          setOpen(false);
          onSubmit();
        }}
        fields={[
          {
            isActive: {
              type: 'toggle',
              default: true,
              label: form.data.isActive ? 'Active' : 'Inactive',
              hint: form.data.isActive ? 'Space is Active' : 'Space is not Active',
            },
            publicView: {
              type: 'toggle',
              default: true,
              label: 'View',
              hint: form.data.publicView ? __('Public view') : __('Private view'),
            },
            allowLinks: {
              type: 'toggle',
              default: false,
              label: __('Links'),
              hint: form.data.allowLinks ? __('Allow links') : __("Don't allow links"),
            },
            showTour: {
              type: 'toggle',
              default: false,
              label: __('Tour section'),
              hint: form.data.showTour ? __('Show') : __("Don't show"),
            },
            showArtstepsRedirect: {
              type: 'toggle',
              default: true,
              label: 'Artsteps Link',
              hint: form.data.showArtstepsRedirect ? __('Show') : __('Dont show'),
            },
            showExhibitions: {
              type: 'toggle',
              default: true,
              label: 'Exhibitions on Header',
              hint: form.data.showExhibitions ? __('Show') : __("Don't show"),
            },
            email: {
              type: 'text',
              label: __('Email'),
              required: true,
              hint: 'will be used to send registration code',
            },
            title: { type: 'text', label: __('Title'), required: true },
            subtitle: { type: 'text', label: __('Subtitle'), required: true },
            subdomain: { type: 'text', label: __('Subdomain'), required: true, hint: 'url prefix' },
            backGround: {
              type: 'image',
              label: __('Background'),
              required: true,
              multiple: false,
              asArray: true,
              quality: 1.0,
              mimeType: form.data.model && form.data.model.hasCanvas ? 'image/jpeg' : 'image/png',
              preview: (file, idx) => `/api/spacesOpen/${form.data.subdomain}/backGround/0/bin`,
              thumbnail: {
                name: 'image',
                mimeType: 'image/jpeg',
                width: IMAGE_WIDTH,
                height: IMAGE_HEIGHT,
              },
            },
            'model.hasCanvas': {
              type: 'toggle',
              default: true,
              label:
                form.data.model && form.data.model.hasCanvas
                  ? __('non_transparent_image')
                  : __('transparent_image'),
              hint:
                form.data.model && form.data.model.hasCanvas
                  ? __('non_transparent_image_hint')
                  : __('transparent_image_hint'),
            },
            logo: {
              type: 'image',
              label: __('Logo'),
              required: true,
              multiple: false,
              quality: 1.0,
              asArray: true,
              mimeType: form.data.model && form.data.model.hasCanvas2 ? 'image/jpeg' : 'image/png',
              preview: (file, idx) => `/api/spacesOpen/${form.data.subdomain}/logo/0/bin`,
              thumbnail: {
                name: 'image',
                mimeType: 'image/jpeg',
                width: IMAGE_WIDTH,
                height: IMAGE_HEIGHT,
              },
            },
            'model.hasCanvas2': {
              type: 'toggle',
              default: true,
              label:
                form.data.model && form.data.model.hasCanvas2
                  ? __('non_transparent_image')
                  : __('transparent_image'),
              hint:
                form.data.model && form.data.model.hasCanvas2
                  ? __('non_transparent_image_hint')
                  : __('transparent_image_hint'),
            },

            description: { type: 'textarea', label: __('description') },
          },
        ]}
        buttons={[
          ...(onRemove
            ? [
                {
                  label: __('remove'),
                  onClick: onRemove,
                  colour: 'delete',
                  icon: 'trash',
                  type: 'button',
                },
              ]
            : []),
          {
            label: __('save'),
            icon: 'save',
            colour: 'secondary',
            disabled: saveDisabled || !form.data.title,
          },
        ]}
      />
    </div>
  </div>
);

export default PrivateSpaceEditor;
