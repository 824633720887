import React from 'react';
import banner from '../../styles/images/privateSpaces/header.png';
import { BannerImage, BannerGradient, OverlayText } from '../../styles/PrivateSpacesPageStyled';
import { Text } from '../../styles/GenericStyled';
import useWindowSize from '../../hooks/windowSize';

const PSbanner = ({ title, sub1, sub2 }) => {
  const size = useWindowSize();

  return (
    <div style={{ position: 'relative' }}>
      <BannerImage src={banner} />
      <BannerGradient />
      <OverlayText style={{ width: `${size.width < 600 ? '100%' : '60%'}` }}>
        <Text align="center" type={size.width < 600 ? 'h6' : 'h3'}>
          {title}
        </Text>
        <Text
          style={{ display: '-webkit-inline-box' }}
          align="center"
          type={size.width < 600 ? 'body2' : 'sub2'}
        >
          {sub1}
        </Text>

        <Text
          style={{ display: '-webkit-inline-box' }}
          align="center"
          type={size.width < 600 ? 'body2' : 'sub2'}
        >
          {sub2}
        </Text>
      </OverlayText>
    </div>
  );
};

export default PSbanner;
