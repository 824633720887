import React, { useState, useEffect } from 'react';
import { __ } from 'artsteps2-common';
import Box from '@material-ui/core/Box';
import publicConfig from 'artsteps2-config/public.json';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getUIProperty, getAuthUser } from '../../../reducers';
import { setUIProperty } from '../../../actions';
import { Text, WPFrame, WPDiv, ExpandButton } from '../../../styles/GenericStyled';
import colors from '../../../styles/colors';

const resolveText = eventType => {
  if (eventType === 7) {
    return 'Chat ended by client.';
  } else if (eventType === 8) {
    return 'Chat ended by seller.';
  } else if (eventType === 9) {
    return 'Order has been submitted. A confirmation email has been sent to your email account';
  }
};

const WPEmbedView = ({ exhibitionId, chatInfo = {}, setChatInfo, setChat, currentUser }) => {
  const [wordpressURL, setWordpressURL] = useState();

  useEffect(() => {
    window.removeEventListener('message', () => Promise.resolve(false));

    window.addEventListener(
      'message',
      event => {
        if (`${event.origin}/` !== publicConfig.wordpressURL) return;

        if (
          event.data &&
          event.data.eventtype &&
          window?.unity?.instances &&
          currentUser &&
          (event.data.eventtype === 7 || event.data.eventtype === 8 || event.data.eventtype === 9)
        ) {
          // Send event to close chat
          setChat(
            window.unity.instances['unity'].channels.socketio,
            exhibitionId,
            chatInfo,
            currentUser,
            false,
            resolveText(event.data.eventtype),
          );
          setChatInfo({ ...chatInfo, showChat: false, showWarning: false });
        }
      },
      false,
    );

    return () => window.removeEventListener('message', () => Promise.resolve(false));
  }, [window.unity, exhibitionId, chatInfo, currentUser]);

  useEffect(() => {
    if (currentUser && currentUser.profile && chatInfo.roomId) {
      if (currentUser.profile.role === 'client' && currentUser.profile.email) {
        setWordpressURL(
          `${publicConfig.wordpressLink}${exhibitionId}/chat/?roomId=${chatInfo.roomId}&clientId=${currentUser._id}&sellerId=${chatInfo.userId}&fullname=${currentUser.profile.name}&email=${currentUser.profile.email}`,
        );
      } else if (currentUser.profile.role === 'seller' && chatInfo.email) {
        setWordpressURL(
          `${publicConfig.wordpressLink}${exhibitionId}/chat/?roomId=${chatInfo.roomId}&clientId=${chatInfo.userId}&sellerId=${currentUser._id}&fullname=${chatInfo.name}&email=${chatInfo.email}`,
        );
      }
    }
  }, [currentUser, chatInfo]);

  return (
    <>
      {chatInfo.roomId && wordpressURL && chatInfo.showChat && (
        <WPDiv animationName={chatInfo.showChat ? 'expand' : 'collapse'}>
          <WPFrame src={encodeURI(wordpressURL)} title="WordPress Site"></WPFrame>
        </WPDiv>
      )}
    </>
  );
};

const mapState = (state, { exhibitionId }) => ({
  chatInfo: getUIProperty(state, 'chatInfo'),
  currentUser: getAuthUser(state) || getUIProperty(state, 'fakeCurrentUser'),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  setChatInfo: chatInfo => dispatch(setUIProperty('chatInfo', chatInfo)),
});

const WPEmbed = compose(withState(mapState), withDispatch(mapDispatch))(WPEmbedView);

export default WPEmbed;
