import React, { useEffect } from 'react';
import { Apps, ViewList, Check } from '@material-ui/icons';
import { IconButton, AppBar, withWidth, Hidden } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { __ } from 'artsteps2-common';
import { Redirect } from 'react-router';
import MetaTags from '../components/layouts/MetaTags';
import Link from '../components/generic/Link';
import DefaultLayout from '../components/layouts/DefaultLayout';
import Pager from '../components/generic/lists/Pager';
import ExhibitionList from '../components/exhibitions/lists/ExhibitionList';
import {
  API_KEYS,
  getUIProperty,
  getAuthUser,
  getApiStatus,
  API_STATUS,
  getApiResource,
} from '../reducers';
import { withState, withDispatch, compose, withLifecycle } from '../enhancers';
import { apiGET, setUIProperty } from '../actions';

import MaintenanceBanner from '../components/banners/maintenanceBanner';
import UverifiedNotificationModal from '../components/banners/UnverifiedNotificationModal';
import ExhibitionCardList from '../components/exhibitionCardList/ExhibitionCardList';
import { BodyWithPadding, StyledButton, Text } from '../styles/GenericStyled';
import MyToolTip from '../components/generic/MyToolTip';
import publicConfig from 'artsteps2-config/public.json';

const LIMIT = 15;

const useStyles = makeStyles({
  appBar: {
    backgroundColor: 'rgba(52, 58, 64, 0.1)',
    boxShadow: '0 0 0 0 rgba(243, 243, 243, 1)',
    flexWrap: 'wrap',
  },
  scroll: {
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    width: '100%',
  },
});

const ExhibitionListPageView = ({
  isPrivateSpace,
  orderby = 'alphabetically',
  query = {},
  unverifiedOpen = false,
  currentUser,
  space,
  ready,
  onUnverifiedNotificationClosed = () => Promise.resolve(false),
  categories,
  width,
}) => {
  const classes = useStyles();
  const [view, setView] = React.useState('list');

  useEffect(() => {
    if (width === 'sm') {
      setView('list');
    }
  }, [width]);

  const [filter, setFilter] = React.useState({});

  React.useEffect(() => {
    if (!isPrivateSpace) {
      setFilter({
        published: true,
        categories: query.category || undefined,
        group: query.group || undefined,
        q: query.search || undefined,
      });
    } else {
      setFilter({
        publishedPrivateSpace: true,
        categories: query.category || undefined,
        group: query.group || undefined,
        q: query.search || undefined,
      });
    }
  }, [isPrivateSpace, query]);

  const sort = (() => {
    switch (orderby) {
      case 'alphabetically': {
        return 'title';
      }
      case 'latest': {
        return '-publishedAt';
      }
      case 'most-viewed': {
        return '-visits';
      }
      case 'top-rated': {
        return '-rating';
      }
      default: {
        return 'title';
      }
    }
  })();

  const include = [
    'staffPick',
    'categories',
    'description',
    `views.${API_KEYS.META}.totalCount`,
    `likes.${API_KEYS.META}.totalCount`,
    'visits',
    'rating',
    '_v1Rating',
  ];

  const populate = ['user'];

  if (!ready && !currentUser) {
    return <div />;
  }
  if (ready && isPrivateSpace && !currentUser && !space.title) {
    return <Redirect to="/login" />;
  }

  const sectorCategoryId = categories.find(category => category.title.toLowerCase() === 'sector')
    ?._id;

  return (
    <DefaultLayout>
      <MetaTags title={__('explore')} />
      <AppBar className={classes.appBar} position="static">
        <BodyWithPadding padding="10px" className={width === 'sm' && classes.scroll}>
          {categories
            .filter(c => c._id !== sectorCategoryId)
            .map(category => (
              <StyledButton
                key={category._id}
                style={{ margin: '5px' }}
                type="tertiary"
                mycolor={
                  filter.categories === category._id
                    ? 'rgba(255, 255, 255, 0.8)'
                    : 'rgba(52, 58, 64, 0.8)'
                }
                backgroundhover={
                  filter.categories === category._id
                    ? 'rgba(52, 58, 64, 0.8)'
                    : 'rgba(52, 58, 64, 0.06)'
                }
                background={
                  filter.categories === category._id
                    ? 'rgba(52, 58, 64, 1)'
                    : 'rgba(255, 255, 255, 1)'
                }
                onClick={() => {
                  if (filter.categories === category._id) {
                    setFilter({
                      ...filter,
                      categories: undefined,
                    });
                  } else {
                    setFilter({
                      ...filter,
                      categories: category._id,
                    });
                  }
                }}
              >
                {category.title}
                {filter.categories === category._id && <Check style={{ marginLeft: '10px' }} />}
              </StyledButton>
            ))}
        </BodyWithPadding>
      </AppBar>
      <BodyWithPadding padding="10px" className="exhibition-list-page ui segment basic">
        <ExhibitionList
          wide
          usernames
          filter={filter}
          sort={sort}
          include={include}
          populate={populate}
          limit={LIMIT}
        />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="exhibitions"
          query={{
            filter,
            sort,
            include,
            populate,
          }}
        />
      </BodyWithPadding>
      {process.env.REACT_APP_SHOW_BANNER === 'TRUE' && <MaintenanceBanner />}

      <UverifiedNotificationModal
        open={
          !!(
            unverifiedOpen &&
            currentUser &&
            currentUser.services.password &&
            !currentUser.emails[0].verified
          )
        }
        onClose={onUnverifiedNotificationClosed}
      />
    </DefaultLayout>
  );
};

const mapState = state => ({
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  unverifiedOpen: getUIProperty(state, 'unverifiedNotificationOpen'),
  currentUser: getAuthUser(state),
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  categories: Object.values(getApiResource(state, `categories`)),
  ready:
    getApiStatus(state, `spaces/${window.location.hostname.split('.')[0]}`) === API_STATUS.IDLE,
});

const mapDispatch = dispatch => ({
  onFetchCategories: () => dispatch(apiGET('categories')),
  onUnverifiedNotificationClosed: () =>
    dispatch(setUIProperty('unverifiedNotificationOpen', false)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchCategories }) => {
    onFetchCategories();
  },
};

const ExhibitionListPage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
  withWidth(),
)(ExhibitionListPageView);

export default ExhibitionListPage;
