import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Paper from '@material-ui/core/Paper';
import { compose, withState, withDispatch } from '../../../enhancers';
import { getFormProperty } from '../../../reducers';
import { setFormProperty } from '../../../actions';
import { HorizontalItems, InvisibleTextField, Text } from '../../../styles/GenericStyled';
import MyToolTip from '../MyToolTip';
import colors from '../../../styles/colors';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    marginTop: '10px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

const TagInputsView = ({ label, hint, tags = [], setProperty }) => {
  const classes = useStyles();

  const [tmpTag, setTmpTag] = React.useState('');
  // const [chipData, setChipData] = React.useState([{ key: 0, label: 'Angular' }]);

  const handleDelete = tagToDelete => () => {
    setProperty(
      'tags',
      tags.filter(tag => tag !== tagToDelete),
    );
  };

  const handleChange = e => {
    // Do not allow space convert to lower case letters
    const tag = e.target.value
      .trim()
      .replace(';', '')
      .toLowerCase();
    setTmpTag(tag);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13 || e.keyCode === 186) {
      e.preventDefault();
      if (tmpTag !== '' && !tags.includes(tmpTag)) setProperty('tags', [...tags, tmpTag]);
      setTmpTag('');
    }
  };

  const handleOnBlur = () => {
    if (tmpTag !== '' && !tags.includes(tmpTag)) setProperty('tags', [...tags, tmpTag]);
    setTmpTag('');
  };

  return (
    <div style={{ marginBottom: '10px' }}>
      <HorizontalItems>
        <Text>{label}</Text>
        <MyToolTip placement="right" title={hint}>
          <HelpOutlineIcon style={{ marginLeft: '5px', opacity: '0.6' }} />
        </MyToolTip>
        <Text style={{ marginLeft: '10px' }} mycolor={colors.ourRed}>
          (Enter a comma after each tag)
        </Text>
      </HorizontalItems>
      <Paper
        onClick={() => document.getElementById('tag-input-field').focus()}
        style={{ justifyContent: 'flex-start' }}
        className={classes.root}
      >
        <HorizontalItems style={{ flexWrap: 'wrap' }}>
          {tags.length > 0 &&
            tags.map((tag, idx) => (
              <Chip
                key={`${tag}-${idx}`}
                label={tag}
                onDelete={handleDelete(tag)}
                className={classes.chip}
              />
            ))}
          <InvisibleTextField
            id="tag-input-field"
            value={tmpTag}
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
            onChange={handleChange}
          />
        </HorizontalItems>
      </Paper>
    </div>
  );
};

const mapState = (state, { artifactId }) => ({
  tags: getFormProperty(state, `artifact:${artifactId || 'new'}`, 'tags'),
});

const mapDispatch = (dispatch, { artifactId }) => ({
  setProperty: (reduxName, value) =>
    dispatch(setFormProperty(`artifact:${artifactId || 'new'}`, reduxName, value)),
});

const TagInputs = compose(withState(mapState), withDispatch(mapDispatch))(TagInputsView);

export default TagInputs;
