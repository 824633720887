import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import colors from '../../../styles/colors';
import logo from '../../../styles/images/logo.png';
import Badge from '@material-ui/core/Badge';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

export const ArtifactTabs = withStyles({
  indicator: {
    background: colors.ourBlack,
  },
})(Tabs);

export const ArtifactTab = withStyles({
  root: {
    paddingTop: '17px',
    lineHeight: '0.9',
    '@media (min-width: 0px)': {
      minWidth: 0,
    },
    background: colors.ourGray,
    textTransform: 'unset',
    fontFamily: 'Muli',
    opacity: 1,
    '&$selected': {
      color: colors.ourBlack,
      '&:hover': {
        opacity: 1,
      },
    },
  },
})(Tab);

export const ArtifactBadge = styled(Badge)`
  & > span {
    right: 90%;
    top: -13px;
  }

  ${props =>
    css`
      & > span {
        color: white;
        background-color: ${props.mycolor};
      }
    `}
`;

export const ToolbarContent = styled.div`
  overflow-y: auto;
  /* height: calc('100vh - 145px'); */
  /* max-height: calc('100vh - 145px'); */
  height: calc(100vh - 130px);
  max-height: calc(100vh - 130px);
  min-height: calc(100vh - 130px);
`;

export const ToolbarSection = styled.div`
  position: relative;
  margin: 0 0 1em 8px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5em;
`;

export const ConstructionList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & > * {
    margin-left: 8px;
  }
`;

export const ImageButton = styled(Button)`
  border-radius: 8px;
  border: ${props => (props.selected ? '2px solid black' : `2px solid ${colors.ourGray}`)};
  width: ${props => props.width || '64px'};
  height: ${props => props.height || '64px'};

  background-image: ${props => props.src && `url(${props.src})`};
  background-size: ${props => props.width || '64px'} ${props => props.height || '64px'};
  background-position: center;

  ${props =>
    props.inactive &&
    css`
      opacity: 0.5;
    `};

  &:hover {
    border: 2px solid ${colors.ourGreen};
  }
`;

export const TemplateList = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 8px;
  }
`;

export const ColorList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  & > * {
    margin: 2px;
  }
`;

export const ColorButton = styled(Button)`
  height: 10px;
  width: 10px;
  min-height: 10px;
  min-width: 10px;
  max-height: 10px;
  max-width: 10px;

  &.MuiButton-text {
    padding: 6px;
  }
  ${props =>
    props.background &&
    css`
      background: ${props.background};
      /* transform: translate(22px); */
    `}

    border: solid ${props => (props.selected ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)')};
    opacity: ${props => (props.inactive ? 0.5 : 1)};


`;

export const ItemList = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & > * {
    margin: 2px;
  }
`;

export const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  background: ${props => props.background || colors.ourGray};
  background-image: ${props => `url(${props.src})`};
  background-size: 64px 64px;
  background-position: center;
  position: relative;
  &:hover {
    border: 2px solid ${colors.ourGreen};
    & > * {
      display: block;
    }
  }

  border: ${props => (props.selected ? '2px solid black' : `2px solid ${colors.ourGray}`)};
  opacity: ${props => (props.inactive ? 0.5 : 1)};
`;

export const ItemEditButton = styled.button`
  text-align: center;
  position: absolute;
  top: 2px;
  right: 0px;
  width: 32px;
  border: none;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 3px;
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.6);
  display: none;
  cursor: pointer;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    margin-left: 4px;
    margin-bottom: 4px;
  }

  & > span {
    width: 100%;
  }
  flex-wrap: wrap;
`;
