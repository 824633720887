import styled, { css } from 'styled-components';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import colors from './colors';

/* ---- Create a private Space ---- */
export const StyledPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: middle;
  width: 300px;
  margin: 20px;
  padding-bottom: 20px;

  &:hover {
    background: #f0f0f0;
  }
`;

export const PaperImage = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  ${props => css`
    width: ${props.width};
    height: ${props.height};
    min-height: ${props.minHeight};
    background-image: url(${props.image});
    border-radius: ${props.radius};
  `}
`;

export const ScrollButton = styled.button`
  text-decoration: underline;
  font-weight: bold;
  background: transparent;
  border: none;
  font-family: 'Muli';

  &:hover {
    color: ${colors.ourGreen};
  }
`;

export const PaperStyled = styled(Paper)`
  align-items: flex-start;
  margin-left: 30px;
  border-radius: 8px;
  min-width: 300px;
  min-height: 300px;
  max-width: 300px;
  max-height: 300px;
`;
/* ----- MODAL-BUTTON --- */
export const Requestgrid = styled(Grid)`
  background-color: #12ad8f;
  min-height: 190px;
  padding: 0 7rem;
  margin-top: 108px;
  @media (max-width: 1515px) {
    margin-top: 0;
  }
  @media (max-width: 444px) {
    padding: 16px !important;
  }
  @media (max-width: 900px) {
    padding: 0;
  }
  @media (max-width: 1065px) {
    justify-content: center;
    text-align: center;
  }
`;

export const CustomTypography = styled(Typography)`
  color: white;
  font-family: 'Muli', 'Regular';
  ${props =>
    props.header === 'true' &&
    css`
      @media (max-width: 960px) {
        margin-top: 16px !important;
      }
    `}

  ${props =>
    props.span === 'true' &&
    css`
      font-weight: 700;
    `}
    ${props =>
      props.modalhead === 'true' &&
      css`
        color: black;
        font-weight: 700;
        font-size: 26px;
      `}
      ${props =>
        props.optional === 'true' &&
        css`
        font-family: 'Muli','Regular';
        display: inline-block;
        color: #afafaf;
        font-weight: 700;
        font-size: 16px;
        padding-left: 4px;
          }
        `}
      ${props =>
        props.company === 'true' &&
        css`
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          color: black;
          display: inline-block;
          font-size: 18px;
          line-height: 33px;
        `}
`;

export const CustomButton = styled(IconButton)`
  font-family: 'Muli', 'Regular';
  border: 2px solid #ffffff82;
  padding: 11px 53px;
  border-radius: 30px;
  color: white;
  transition: padding-right 0.2s ease-in, color 0.2s ease-in, background-color 0.2s ease-in,
    transform 0.2s;
  height: 55px;
  &:hover {
    background-color: white;
    padding-right: 113px;
    color: #12ad8f;
    & > span {
      transform: translate(23%);
    }
  }
  @media (max-width: 600px) {
    background-color: white;
    color: #12ad8f;
    margin-bottom: 12px;
  }
  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
`;

export const SendButton = styled(IconButton)`
  font-family: 'Muli', 'Regular';
  font-size: 18px;
  border: 1px solid #ffffff82;
  padding: 11px 53px;
  border-radius: 30px;
  color: white;
  background-color: #12ad8f;
  width: 80%;
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  margin: auto;
  justify-content: space-around;
  &:hover {
    background-color: #12ad8f;
    opacity: 0.7;
  }
  ${props =>
    props.disabled &&
    css`
      background-color: #12ad8f !important;
      opacity: 0.5;
      color: white !important;
    `}
`;

export const PlacedArrowIcon = styled(ArrowRightIcon)`
  display: none;
  width: 1.5em;
  height: 1.5em;
  ${props =>
    props.open &&
    css`
      transform: translate(22px);
      display: block;
    `}
`;

export const ModalGrid = styled(Grid)`
  margin: 19px auto;
  max-width: 464px;
  background-color: white;
  position: relative;
  &:focus {
    outline: none;
  }
`;

export const InputsModal = styled.input`
  width: 80%;
  height: 58px;
  border: 2px solid #f3f3f3;
  border-radius: 5px;
  padding-left: 20px;
  font-size: 20px;
  color: black;
  &:focus {
    border: 2px solid #cbc8c8;
    outline: none;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #808080;
  }
  :-ms-input-placeholder {
    color: #808080;
  }
`;

export const InputsLabel = styled(InputLabel)`
  display: flex;
  align-items: center;
  width: 80%;
  font-size: 18px;
  line-height: 33px;
  text-align: left;
  margin: auto;
  color: black;
`;

export const CustomTextField = styled(TextField)`
  border: 0;
  height: 58px;

  & > div {
    height: 100%;
  }
  & > div > input {
    font-size: 20px;
    padding-left: 20px !important;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #808080;
      opacity: 1;
    }
    :-ms-input-placeholder {
      color: #808080;
    }
  }
`;

export const CustomAutocomplete = styled(Autocomplete)`
  border: 2px solid #f3f3f3 !important;
  width: 80%;
  margin: auto;
  border: 2px solid;

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }
`;

export const DropDownOptions = styled(MenuItem)`
  background-color: #f4f3f3;
  border-radius: 5px;
  background-color: #f4f3f3;
  border-radius: 5px;
  border-bottom: 1px solid white;
  border-top: 2px solid white;
  padding: 11px 5px;
  color: #999999bf;
`;

export const UpArrow = styled(ArrowDropUpIcon)`
  position: absolute;
  top: 57px;
  right: 58px;
`;

export const DownArrow = styled(ArrowDropDownIcon)`
  position: absolute;
  top: 57px;
  right: 58px;
`;

/* ----- CAROUSEL ----- */
export const BulletButtonsDiv = styled.div`
  position: absolute;
  top: 492px;
  left: 343px;
`;

export const InvisibleButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
`;

export const InfoStyled = styled.div`
  text-align: left;
  font-family: 'Muli', 'Regular';
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.45;
  color: #343a4052;
  ${props =>
    props.active === 1 &&
    css`
      color: #343a40;
    `}
`;

export const ImageArea = styled.img`
  box-shadow: 0px 8px 28px #00000029;
  border-radius: 16px;
  width: 800px;
  height: 430px;
  opacity: 0%;
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${props =>
    props.active &&
    css`
      -webkit-animation: fade-in 1s forwards; /* Safari 4+ */
      -moz-animation: fade-in 1s forwards; /* Fx 5+ */
      -o-animation: fade-in 1s forwards; /* Opera 12+ */
      animation: fade-in 1s forwards; /* IE 10+, Fx 29+ */
    `}
`;

export const VideoArea = styled.video`
  box-shadow: 0px 8px 28px #00000029;
  border-radius: 16px;
  width: 800px;
  height: 450px;
  opacity: 0;
  object-fit: fill;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${props =>
    props.active &&
    css`
      -webkit-animation: fade-in 1s forwards; /* Safari 4+ */
      -moz-animation: fade-in 1s forwards; /* Fx 5+ */
      -o-animation: fade-in 1s forwards; /* Opera 12+ */
      animation: fade-in 1s forwards; /* IE 10+, Fx 29+ */
    `}
`;

export const MediaAreaMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 800px;
  max-height: fit-content;
  margin: auto;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ImageAreaMobile = styled.img`
  box-shadow: 0px 8px 28px #00000029;
  border-radius: 16px;
  width: 100%;
  height: 100%;
`;

export const VideoAreaMobile = styled.video`
  box-shadow: 0px 8px 28px #00000029;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: fill;
  @media (max-width: 600px) {
    border-radius: 0;
  }
  @media (max-width: 360px) {
    box-shadow: rgba(0, 0, 0, 0.36) 0px 0px 18px;
  }
`;

export const T1 = styled.div`
  width: 90%;
  margin-left: 5%;
  color: #343a40;
  font-family: 'Muli', 'Regular';
  font-size: 3.5rem;
  font-weight: 1000;
  line-height: 1;

  ${props =>
    props.headertitle === 'true' &&
    css`
      @media (max-width: 360px) {
        color: rgb(52, 58, 64);
        width: fit-content !important;
        margin-left: 52px !important;
        font-size: 2.4rem !important;
      }
    `}

  ${props =>
    props.title === 'true' &&
    css`
      margin: 40px 0px 30px 20px;
      @media (max-width: 1850px) {
        margin: 40px auto 30px auto;
      }
      @media (max-width: 960px) {
        font-size: 2.2rem;
        margin: 32px auto 5px auto;
      }
    `}

  ${props =>
    props.small &&
    css`
      margin: 0 auto 10px auto;
      font-size: 2.5rem;
      @media (max-width: 600px) {
        font-size: 2.1rem;
        width: 100%;
      }
    `}
  ${props =>
    props.mobile &&
    css`
      text-align: center;
    `}
`;
export const T2 = styled.div`
  width: 90%;
  margin-left: 5%;
  line-height: 1;
  text-align: center;
  color: #343a40;
  font-family: 'Muli', 'Regular';
  font-size: 2.5rem;
  font-weight: bold;
  text-transform: uppercase;

  ${props =>
    props.mobile &&
    css`
      font-size: 1.5rem;
    `}
`;
export const T3 = styled.div`
  width: 60%;
  line-height: 1;
  text-align: center;
  color: #343a40;
  font-family: 'Muli', 'Regular';
  font-size: 2rem;
  font-weight: bold;

  ${props =>
    props.sectionDiv === 'true' &&
    css`
      @media (min-width: 800px) {
        padding: 25px 0;
        width: 90%;
        max-width: 800px;
        text-align: left;
        margin: 63px auto;
      }
      @media (max-width: 800px) {
        padding: 25px 0;
        max-width: 800px;
        text-align: left;
        width: 88%;
        line-height: 1.2;
        margin: 18px auto 50px auto;
      }
    `}

  ${props =>
    props.mobile &&
    css`
      font-size: 2rem;
    `}

  ${props =>
    props.banner &&
    css`
      margin-top: 30px;
      font-weight: 300;
      display: inline !important;
      width: 100%;
      padding: 0 11px;
      @media (max-width: 1280px) and (min-width: 960px) {
        width: 75%;
        padding: 0px 11px;
        margin: 2% auto;
        line-height: 2.6rem;
      }
      @media (max-width: 360px) {
        text-align: left !important;
        font-size: 1.4rem !important;
        line-height: 1.4;
        padding: 0px 52px;
      }
    `}
  ${props =>
    props.small &&
    css`
      margin-top: 14px !important;
      @media (max-width: 600px) {
        font-size: 1rem;
      }
    `}
`;
export const Section = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;

  ${props =>
    props.mobile &&
    css`
      margin-top: 20px;
      margin-bottom: 0px;
      width: 100%;
    `}
`;
export const BannerImage = styled.img`
  width: 100%;
  height: 587px;
  margin: 0px;
  object-fit: fill;
  @media (max-width: 600px) {
    height: 266px;
  }
  @media (max-width: 360px) {
    height: 266px;
  }
`;

export const BannerGradient = styled.div`
  position: absolute;
  content: '';
  background: transparent linear-gradient(0deg, #ffffffb3 0%, #ffffff00 100%) 0% 0% no-repeat
    padding-box;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;
export const OverlayText = styled.div`
  position: absolute;
  padding: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TooltipStyle = withStyles({
  tooltip: {
    fontSize: '13px',
  },
})(Tooltip);

export const TooltipHover = styled(TooltipStyle)`
  background-color: #f5f5f9;
  color: black;
  opacity: 1;
`;

export const TooltipButton = styled(IconButton)`
  border: 0;
  padding: 0;
  color: black;
  margin-left: 9px;
  background-color: white;
  & > span {
    color: #343a40;
    opacity: 0.18;
  }
  &:hover {
    background-color: white;

    & > span {
      color: red;
      opacity: 1;
    }
  }
`;
export const StyledSpan = styled.span`
  font-weight: bold;
`;
export const CarouselGrid = styled(Grid)`
  padding: 57px 5% 50px 5%;
  max-width: 1765px;
  margin: auto;
  @media (max-width: 1700px) {
    padding: 57px 0 50px 0;
  }
`;
