import React, { useState, useEffect, useRef } from 'react';
import { __ } from 'artsteps2-common';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import utils from '../../utils';
import { compose, withState, withDispatch } from '../../enhancers';
import { getUIProperty, getApiResource, getAuthCurrentSpace } from '../../reducers';
import { setLocation, setUIProperty, addMessage, apiPOST, apiPATCH } from '../../actions';
import { ReactComponent as Steps } from '../../styles/images/steps.svg';
import * as generic from '../../styles/GenericStyled';
import * as header from '../../styles/styledComponents/header';
import DropDown from './DropDown';
import useWindowSize from '../../hooks/windowSize';
import MyToolTip from '../generic/MyToolTip';
import colors from '../../styles/colors';
import publicConfig from 'artsteps2-config/public.json';

const STEP_KEY_SPACE = 1;
const STEP_KEY_TEXTURES = 2;
const STEP_KEY_ARTIFACTS = 3;
const STEP_KEY_STORYTELLING = 4;
const STEP_KEY_PUBLISH = 5;

const steps = {
  [STEP_KEY_SPACE]: __('exhibition_space'),
  [STEP_KEY_TEXTURES]: __('exhibition_textures'),
  [STEP_KEY_ARTIFACTS]: __('exhibition_artifacts'),
  [STEP_KEY_STORYTELLING]: __('exhibition_storytelling'),
  [STEP_KEY_PUBLISH]: __('exhibition_publish'),
};

function useInterval(callback, delay) {
  const savedCallback = useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const formatSeconds = seconds => {
  let infoText = 'Last saved ';
  if (seconds > 60) infoText = infoText.concat(`${(seconds / 60).toFixed(0)} min ago`);

  return infoText;
};

const Autosave = ({
  switchsubview,
  isExhibitionEmpty,
  selectedTemplate,
  onSetSaveTimestamp,
  onSave,
  onAddMessage,
  exhibition,
  setSeconds,
  setLastSaved,
  seconds,
  hasChanges,
  lastSaved,
}) => {
  const [autosave, setAutosave] = useState(publicConfig.autoSaveDefaultValue);

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [setSeconds]);

  useEffect(() => {
    setLastSaved(formatSeconds(seconds));
  }, [seconds, setLastSaved]);

  const handleChange = () => {
    setAutosave(!autosave);
    if (isExhibitionEmpty)
      onAddMessage({
        title: __('cannot_autosave'),
        description: __('autosave_failure'),
        type: 'error',
      });
  };

  useInterval(() => {
    if (hasChanges) {
      if (autosave && (!isExhibitionEmpty || selectedTemplate !== '')) {
        onSetSaveTimestamp()
          .then(() => onSave())
          .then(() => {
            setSeconds(0);
          });
      } else if (autosave && isExhibitionEmpty) {
        onAddMessage({
          title: __('cannot_autosave'),
          description: __('autosave_failure'),
          type: 'error',
        });
      }
    }
  }, 1 * 60 * 1000);

  return (
    <>
      <header.ToggledGrid autosavetext="true" togglesave={`${switchsubview}`} item>
        <FormGroup style={{ marginLeft: '12px' }}>
          {autosave ? (
            <>
              <Box component={header.AutosaveTexts} variant="subtitle1" toggle={`${switchsubview}`}>
                <generic.Text type="h7" mycolor="#343a40">
                  Autosave On
                </generic.Text>
              </Box>
              <Box
                component={header.AutosaveTexts}
                variant="caption"
                color="textSecondary"
                toggle={`${switchsubview}`}
              >
                <generic.Text type="body3" mycolor="#343a40">
                  {/* saved {saveTime.minutes} min and {saveTime.seconds} ago */}
                  {lastSaved}
                </generic.Text>
              </Box>
            </>
          ) : (
            <generic.Text type="h7" mycolor="black">
              Autosave Off
            </generic.Text>
          )}
        </FormGroup>
      </header.ToggledGrid>

      <header.ToggledGrid togglevision={`${switchsubview}`}>
        <FormGroup>
          <FormControlLabel
            control={
              <header.AutosaveSwitch size="small" checked={autosave} onChange={handleChange} />
            }
          />
        </FormGroup>
      </header.ToggledGrid>
    </>
  );
};

const CreatorViewButtonsView = ({
  exportingAt = '',
  currentUser,
  fakeCurrentUser,
  isPrivateSpace,
  hasChanges = false,
  isExhibitionEmpty = true,
  selectedTemplate = '',
  setAutosaveTriggered,
  onRedirect = () => Promise.resolve(false),
  onSetImage = () => Promise.resolve(false),
  onUpsert = () => Promise.resolve(false),
  onExhibitionExport = () => Promise.resolve(undefined),
  onSetSaveTimestamp = () => Promise.resolve(undefined),
  onPreview = () => Promise.resolve(undefined),
  onAddMessage = () => Promise.resolve(false),
  switchsubview,
  handleSubView,
  creatorView,
  handleExhibitionPreview,
  exhibition = {},
  changeTimestamp,
  requestIsEmpty = () => Promise.resolve(false),
}) => {
  const size = useWindowSize();
  const [saveTime, setSetTime] = useState({ minutes: 0, seconds: 0 });

  const [seconds, setSeconds] = useState(0);
  const [lastSaved, setLastSaved] = useState(0);

  useEffect(() => {
    if (size.width > 1280) handleSubView(true);
  }, [size.width]);

  useEffect(() => {
    if (changeTimestamp) requestIsEmpty();
  }, [changeTimestamp]);

  return (
    <>
      <header.ToggledGrid
        smallheader="false"
        container
        spacing={2}
        alignItems="center"
        justify="flex-end"
      >
        {creatorView && (
          <>
            {localStorage.getItem('isKlimentini') && (
              <Autosave
                setSeconds={setSeconds}
                setLastSaved={setLastSaved}
                seconds={seconds}
                lastSaved={lastSaved}
                exhibition={exhibition}
                switchsubview={switchsubview}
                onSetSaveTimestamp={onSetSaveTimestamp}
                onSave={onExhibitionExport}
                setAutosaveTriggered={setAutosaveTriggered}
                label={__('autosave')}
                isExhibitionEmpty={isExhibitionEmpty}
                selectedTemplate={selectedTemplate}
                onAddMessage={onAddMessage}
                exportingAt={exportingAt}
                saveTime={saveTime}
                setSetTime={setSetTime}
                hasChanges={hasChanges}
                requestIsEmpty={requestIsEmpty}
              />
            )}
            <header.ToggledGrid togglevision={`${switchsubview}`} item>
              <MyToolTip placement="bottom" title={!hasChanges ? 'No changes found' : ''}>
                <div>
                  <Box
                    component={generic.StyledButton}
                    type="secondary"
                    mycolor="white"
                    background="#12ad8f"
                    disabled={!hasChanges}
                    onClick={() => {
                      requestIsEmpty();
                      if ((!isExhibitionEmpty || selectedTemplate !== '') && onExhibitionExport) {
                        onSetSaveTimestamp()
                          .then(() => onExhibitionExport())
                          .then(() => {
                            setSeconds(0);
                          });
                      } else {
                        onAddMessage({
                          title: __('Exhibition not saved'),
                          description: __('autosave_failure'),
                          type: 'error',
                        });
                      }
                    }}
                  >
                    {size.width >= 1920 ? __('save_draft') : <SaveIcon />}
                  </Box>
                </div>
              </MyToolTip>
            </header.ToggledGrid>
            {localStorage.getItem('isKlimentini') && (
              <header.ToggledGrid togglevision={`${switchsubview}`} item>
                <generic.StyledButton
                  type="secondary"
                  mycolor="black"
                  background="white"
                  border={1}
                  bordercolor="#343A4066"
                  onClick={() =>
                    onUpsert({ published: !exhibition.published }).then(
                      () =>
                        (exhibition.image && (exhibition.image.bin || exhibition.image.uri)) ||
                        onSetImage(),
                    )
                  }
                >
                  {exhibition.published ? __('Unpublish') : __('publish')}
                </generic.StyledButton>
              </header.ToggledGrid>
            )}
          </>
        )}

        {localStorage.getItem('isKlimentini') && (
          <Box
            item
            component={header.ToggledGrid}
            switch="true"
            toggle={`${switchsubview}`}
            creatorview={+creatorView}
          >
            <MyToolTip
              placement="bottom"
              title={
                creatorView && (hasChanges || (isExhibitionEmpty && selectedTemplate === ''))
                  ? 'Exhibition must be saved'
                  : ''
              }
            >
              <div>
                <generic.StyledButton
                  background="white"
                  disabled={
                    creatorView && (hasChanges || (isExhibitionEmpty && selectedTemplate === ''))
                  }
                  onClick={() => {
                    handleExhibitionPreview(creatorView);
                    if (creatorView) onPreview(`${exhibition._id}/?currentUser`);
                    else onRedirect(`/curate/${exhibition._id}/1`);
                  }}
                >
                  <generic.HorizontalItems>
                    <span>
                      <generic.Text mycolor={colors.ourBlack}>
                        {creatorView ? 'Preview off' : 'Preview On'}
                      </generic.Text>
                      <Steps style={{ opacity: 0.5 }} />
                    </span>
                  </generic.HorizontalItems>
                </generic.StyledButton>
              </div>
            </MyToolTip>
          </Box>
        )}
      </header.ToggledGrid>
    </>
  );
};

const mapState = (state, { exhibitionId = 'new' }) => ({
  exportingAt: getUIProperty(state, `exhibitions/${exhibitionId}/export/timestamp`),
  changeTimestamp: getUIProperty(state, `exhibitions/${exhibitionId}/changeTimestamp`),
  isExhibitionEmpty: !!getUIProperty(state, `exhibitions/${exhibitionId}/isEmpty`),
  warning: getUIProperty(state, `exhibitions/${exhibitionId}/pendingChangesWarning`),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  exhibition: {
    _id: exhibitionId,
    title: `Untitled (${new Date().toDateString()} ${new Date().getHours()}:${new Date().getMinutes()})`,
    space: getAuthCurrentSpace(state) || undefined,
    ...getApiResource(state, `exhibitionsUserProfile/${exhibitionId}`),
  },
  selectedTemplate: getUIProperty(state, `exhibitions/${exhibitionId}/selectedTemplate`),
  requestingIsEmpty: getUIProperty(state, `exhibitions/${exhibitionId}/requestingIsEmpty`),
  hasChanges: getUIProperty(state, `exhibitions/${exhibitionId}/hasChanges`),
});

const mapDispatch = (
  dispatch,
  {
    exhibitionId = 'new',
    exhibition,
    changeTimestamp = 0,
    currentStep,
    ready,
    artifacts = {},
    exhibitionArtifacts = [],
    requestingIsEmpty,
  },
) => {
  const stepKeys = Object.keys(steps)
    .sort()
    .filter((step, idx) => !(idx === 0 && idx === 3))
    .map(step => parseInt(step, 10));
  const isFirstStep = currentStep === stepKeys[0];
  const isLastStep = currentStep === stepKeys[stepKeys.length - 1];
  const onPreview = id => dispatch(setLocation(`/view/${id}`));
  const onRedirect = location => dispatch(setLocation(location));
  const onNextStep = () =>
    dispatch(
      setLocation(
        `/curate/${exhibitionId}/${
          stepKeys[(stepKeys.indexOf(currentStep) + 1) % stepKeys.length]
        }`,
      ),
    );
  const onPreviousStep = () =>
    dispatch(
      setLocation(
        `/curate/${exhibitionId}/${
          stepKeys[(stepKeys.indexOf(currentStep) - 1) % stepKeys.length]
        }`,
      ),
    );
  const onExhibitionExport = () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/exportingAt`, Date.now())); // sets value of exportingAt handled by Unity Wrapper ~790 as exportTimestamp
  const onSetSaveTimestamp = () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/saveTimestamp`, changeTimestamp));
  const onWarn = () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/pendingChangesWarning`, true));
  const onWarnDismissed = () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/pendingChangesWarning`, false));
  const requestIsEmpty = () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/requestingIsEmpty`, !requestingIsEmpty));
  const onAddMessage = message => dispatch(addMessage(message));
  const onUpsert = exhibition =>
    exhibitionId === 'new'
      ? dispatch(apiPOST('exhibitionsUserProfile', exhibition))
      : dispatch(apiPATCH(`exhibitionsUserProfile/${exhibitionId}`, exhibition));
  const onSetImage = () => {
    const artifact = exhibitionArtifacts
      .map(id => artifacts[id])
      .find(a => a && a.type === 'image');
    return dispatch(
      artifact
        ? setUIProperty(
            `exhibitions/${exhibitionId}/screenshot`,
            utils.artifact.getArtifactImage(artifact),
          )
        : setUIProperty(`exhibitions/${exhibitionId}/capturingAt`, Date.now()),
    );
  };

  return {
    onPreview,
    onNextStep,
    onPreviousStep,
    onExhibitionExport,
    onSetSaveTimestamp,
    onWarn,
    onUpsert,
    onSetImage,
    onWarnDismissed,
    onRedirect,
    onAddMessage,
    requestIsEmpty,
    saveDisabled: !ready,
    nextStepDisabled: !!isLastStep,
    previousStepDisabled: !!isFirstStep,
    previewDisabled: !ready || exhibitionId === 'new',
  };
};

const CreatorViewButtons = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(CreatorViewButtonsView);

export default CreatorViewButtons;
