import React, { useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  MediaAreaMobile,
  ImageAreaMobile,
  VideoAreaMobile,
} from '../../styles/PrivateSpacesPageStyled';
import { Text } from '../../styles/GenericStyled';
import useWindowSize from '../../hooks/windowSize';

const Media = ({ src, type, index, ai, refVideo }) => (
  <>
    {type === 'video' && ai === index && (
      <MediaAreaMobile>
        <VideoAreaMobile ref={refVideo} active={ai === index} loop muted="muted">
          <source src={src} type="video/mp4" />
        </VideoAreaMobile>
      </MediaAreaMobile>
    )}
    {type === 'img' && ai === index && (
      <MediaAreaMobile>
        <ImageAreaMobile active={ai === index} src={src} />
      </MediaAreaMobile>
    )}
  </>
);

const CustomCarousel = ({ media, infos }) => {
  const vid1 = useRef(null);
  const vid2 = useRef(null);
  const vid3 = useRef(null);
  const vid4 = useRef(null);
  const refs = [vid1, vid2, vid3, vid4];

  const size = useWindowSize();

  const handlePlay = () => {
    refs.forEach(ref => {
      if (ref !== null && ref.current) {
        if (ref.current.offsetTop >= window.scrollY) {
          ref.current.play();
        } else {
          ref.current.pause();
        }
      }
    });
  };

  useEffect(() => {
    window.onscroll = () => handlePlay(refs);
  });

  const myMedia = media.map((obj, index) => (
    <Grid container direction="column" item key={index} spacing={10}>
      <Grid item>
        <Media key={index} src={obj.src} type={obj.type} refVideo={refs[index]} />
        <Text
          style={{ margin: '50px' }}
          align="center"
          type={`${size.width < 600 ? 'body1' : 'sub1'}`}
        >
          {infos[index]}
        </Text>
      </Grid>
    </Grid>
  ));

  return (
    <Grid container justify="center" direction="column" wrap="wrap">
      {myMedia}
    </Grid>
  );
};

export default CustomCarousel;
