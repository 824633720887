import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: #f3f3f3;
  padding: 33px 15px 33px 70px;
  @media (max-width: 960px) {
    padding: 33px 15px 33px 20px;
  }
`;

export const Section = styled(Grid)`
  ${props => css`
    text-align: ${props.textalign};
  `}

  &:nth-child(2) {
    text-align: left !important;
  }
`;

export const BodyWithPadding = styled.div`
  @media (max-width: 960px) {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    max-width: 100%;
  }
`;

export const IconsSection = styled.div`
  margin: 24px 0 10px 0;
  @media (min-width: 450px) {
    & > div > a:first-child {
      padding-right: 12px;
    }
  }
  @media (min-width: 450px) and(max-width: 960px) {
    margin: 24px 0 10px 19px;
  }
  @media (max-width: 1479px) and (min-width: 960px) {
    & > div {
      flex-wrap: wrap;
    }
    /* google icon */
    & > div > a:first-child > div {
      height: 51px;
      width: 138px;
      background-size: 159px;
      background-position: -9px -9px;
    }
    /* apple icon  */
    & > div > a:last-child > div {
      height: 48px;
      width: 142px;
      background-size: 139px;
      background-position: 1px 0px;
    }
  }
`;

export const ButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.fillcolor &&
    css`
      & > svg {
        fill: ${props.fillcolor};
      }
    `}
`;

export const StyledList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  & > li {
    margin: 10px 0;
  }
`;

export const ListAlign = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 1036px) {
    flex-wrap: nowrap;
  }
  @media (max-width: 960px) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    max-width: 600px;
    margin: 25px 0 25px 5%;
  }
  ${props =>
    props.footerbuttons === 'true' &&
    css`
      justify-content: space-between;
      margin: 24px 0 0 0;
      width: 100%;
      flex-wrap: wrap;
      & > button:first-child,
      & > button:last-child {
        margin: 0 10px;
      }
      @media (max-width: 1771px) {
        & > button:first-child,
        & > button:last-child {
          margin: 10px 10px 10px 0;
        }
      }
      @media (min-width: 1036px) {
        flex-wrap: wrap;
      }
      @media (max-width: 960px) {
        flex-wrap: wrap;
        margin: 24px 0 36px 0;
        max-width: 642px;
      }
      @media (max-width: 800px) {
        justify-content: center;
      }
    `}
`;

export const ListSection = styled.div`
  ${props =>
    props.index === 1 &&
    css`
      padding: 0 50px;
      @media (max-width: 500px) {
        padding: 0 15px;
      }
      @media (max-width: 357px) {
        padding: 0;
      }
      @media (max-width: 1077px) and (min-width: 960px) {
        padding: 0 25px;
      }
    `}
`;

export const NewsletterWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  @media (max-width: 960px) {
    margin-top: 0px;
  }
`;

export const NewsletterInput = styled.input`
  outline: none;
  border: 7px solid white;
  border-radius: 19px;
  width: 385px;
  padding: 5px 0 5px 16px;
  font-size: 15px;
  font-family: 'Muli', Regular;
  &::placeholder {
    color: #343a4085;
  }
  @media (max-width: 1179px) {
    width: 300px;
  }
  @media (max-width: 960px) {
    width: 373px;
  }
  @media (max-width: 400px) {
    width: 287px;
  }
`;

export const NewsletterButton = styled.button`
  background-color: #12ad8f;
  border: none;
  border-radius: 20px;
  padding: 0px 22px;
  height: 35px;
  position: absolute;
  right: 3px;
  outline: none;
  transition: padding 0.5s, transform 0.5s;

  & > svg {
    display: none;
  }

  &:hover {
    padding: 0 36px;
    transform: translateX(7px);
    & > svg {
      display: block;
      position: absolute;
      right: 8px;
      top: 7px;
      color: white;
    }
  }
`;

export const SocialWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  & > a {
    color: #06060682;
    background-color: #c1c1c142;
    display: flex;
    padding: 4px;
    margin-right: 10px;
    border-radius: 5px;
    &:hover {
      background-color: #c1c1c19e;
      color: #06060682;
    }
  }
`;
