import React from 'react';
import { Text, BodyWithPadding } from '../../styles/GenericStyled';

const PSpaperMobile = ({ handleOpen, text, subtext, description, source, background }) => (
  <div style={{ width: '100%', margin: 0, background }}>
    <BodyWithPadding style={{ width: '100%' }} padding="50px">
      <Text align="center" type="h5">
        {text}
      </Text>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Text align="center" style={{ marginTop: '30px' }} type="h5">
          {subtext}
        </Text>
        <Text align="center" type="sub2">
          {description}
        </Text>
      </div>
    </BodyWithPadding>
  </div>
);
export default PSpaperMobile;
