import React, { useState, useEffect, useLayoutEffect } from 'react';
import config from 'artsteps2-config/public.json';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import PaymentIcon from '@material-ui/icons/Payment';
import Box from '@material-ui/core/Box';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import { getAuthUser, getUIProperty } from '../../reducers';
import { compose, withState, withDispatch } from '../../enhancers';
import { setUIProperty } from '../../actions';
import Link from '../generic/Link';
import AppIconsSection from '../generic/AppIconsSection';
import logo from '../../styles/images/logo.png';
import Grid from '@material-ui/core/Grid';
import * as footer from '../../styles/styledComponents/footer';
import * as generic from '../../styles/GenericStyled';
import OurModal from '../../components/generic/OurModal';
import Paypal from '../../modal_content/Paypal';

const GOOGLE_PLAY_URI = config.applications.playURI;
const APP_STORE_URI = config.applications.itunesURI;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const social = [
  {
    path: 'https://www.facebook.com/accigr/',
    icon: <FacebookIcon style={{ fontSize: 40 }} />,
  },
  {
    path: 'https://twitter.com/athenschamber',
    icon: <TwitterIcon style={{ fontSize: 40 }} />,
  },
];

const footerItems = [
  {
    header: 'Legal',
    items: [
      {
        name: 'Terms of Service',
        path: 'https://www.acci.gr/acci/static/about.jsp?id=15423&context=101',
      },
      {
        name: 'Privacy Policy',
        path: 'https://www.acci.gr/acci/static/about.jsp?id=15444&context=101',
      },
      {
        name: 'Security Policy',
        path: 'https://www.acci.gr/acci/static/about.jsp?id=15422&context=101',
      },
    ],
  },
];

const List = () => (
  <>
    {footerItems.map((element, index) => (
      <footer.ListSection key={index} index={index}>
        <generic.Text type="h5">{element.header}</generic.Text>
        <footer.StyledList>
          {element.items.map(item => (
            <li key={index + item.name}>
              <a key={index} href={item.path} target={'_blank'}>
                <generic.Text component="span" type="body1" mycolor="#343a40b8" weight="600">
                  {item.name}
                </generic.Text>
              </a>
            </li>
          ))}
        </footer.StyledList>
      </footer.ListSection>
    ))}
  </>
);

export const DefaultFooterView = ({
  onContributeModalOpen = Promise.resolve(false),
  onToggleDonateModal = Promise.resolve(false),
  donateModal = false,
}) => {
  const [width] = useWindowSize();
  const [textAlign, setTextAlign] = useState('left');

  useEffect(() => {
    if (width >= 960) setTextAlign('left');
    else setTextAlign('center');
  }, [width]);

  return (
    <footer.StyledFooter>
      <OurModal width={400} height={550} openModal={donateModal} setOpenModal={onToggleDonateModal}>
        <Paypal />
      </OurModal>
      <Grid container wrap="wrap">
        <footer.Section item xs={12} md={6} textalign={textAlign}>
          <footer.BodyWithPadding style={{ height: '100%' }}>
            <generic.VerticalItems style={{ height: '100%' }} justify="flex-start">
              <img src={logo} alt="logo" width="300px" />
              <generic.Text
                type="body2"
                mycolor="#343A40"
                weight="600"
                style={{ marginTop: 'auto' }}
              >
                Designed & Developed by Artsteps team
              </generic.Text>
              {width >= 960 && (
                <generic.Text
                  type="body2"
                  mycolor="#343A40"
                  weight="600"
                  style={{ marginTop: 'auto' }}
                >
                  © ATHENS CHAMBER OF COMMERCE AND INDUSTRY
                </generic.Text>
              )}
            </generic.VerticalItems>
          </footer.BodyWithPadding>
        </footer.Section>
        <footer.Section item xs={12} md={6} textalign={textAlign}>
          <generic.VerticalItems justify="flex-end" style={{ padding: '0 20px' }}>
            <footer.ListAlign>
              <List />
            </footer.ListAlign>
          </generic.VerticalItems>
        </footer.Section>
        <footer.Section item xs={12} md={4} textalign={textAlign}>
          {width < 960 && (
            <generic.Text type="body2" mycolor="#343A40" weight="600" style={{ marginTop: '20px' }}>
              © 2020 Dataverse Ltd. All rights reserved.
            </generic.Text>
          )}
        </footer.Section>
      </Grid>
    </footer.StyledFooter>
  );
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  donateModal: getUIProperty(state, 'donateModal'),
});

const mapDispatch = dispatch => ({
  onContributeModalOpen: () => dispatch(setUIProperty('contributionModalOpen', true)),
  onToggleDonateModal: show => dispatch(setUIProperty('donateModal', show)),
});

const DefaultFooter = compose(withState(mapState), withDispatch(mapDispatch))(DefaultFooterView);

export default DefaultFooter;
