import React from 'react';
import { __ } from 'artsteps2-common';
import { getUIProperty } from '../../../../reducers';
import { setUIProperty } from '../../../../actions';
import { compose, withState, withDispatch, withLifecycle } from '../../../../enhancers';
import wallPlacementImage from '../../../../styles/images/controls/place_wall.png';
import doorPlacementImage from '../../../../styles/images/controls/place_door.png';
import Dialog from '../../../generic/Dialog';
import { ConstructionList, ImageButton, ToolbarSection } from '../ExhibitionEditStyled';
import MyToolTip from '../../../generic/MyToolTip';
import { Text } from '../../../../styles/GenericStyled';

export const ExhibitionSpacePanelView = ({
  placing = false,
  placingWall = false,
  placingDoor = false,
  onToggleWallPlacement = () => Promise.resolve(false),
  onToggleDoorPlacement = () => Promise.resolve(false),
  requestIsEmpty,
  openDialog = () => Promise.resolve(false),
  closeDialog = () => Promise.resolve(false),
  requestingIsEmpty = () => Promise.resolve(false),
  onDeleteExhibition = () => Promise.resolve(false),
  onTemplateRemove = () => Promise.resolve(false),
  selectDoor = () => Promise.resolve(false),
  selectWall = () => Promise.resolve(false),
  isExhibitionEmpty,
  displayDialog = false,
  selectedStructure,
  onDelete,
  changeTimestamp,
  keyboardCapture,
}) => {
  const handleClick = placementFunc => {
    requestIsEmpty();
    placementFunc();
  };

  const placeStructure = () => {
    switch (selectedStructure) {
      case 'Door':
        onToggleDoorPlacement();
        break;
      case 'Wall':
        onToggleWallPlacement();
        break;
      default:
        break;
    }
  };

  return (
    <ToolbarSection>
      <Text type="h7">{__('construction_tools')}</Text>
      <ConstructionList>
        <MyToolTip
          title={__(placingWall ? 'stop_wall_placement' : 'start_wall_placement')}
          placement="top"
        >
          <ImageButton
            style={{ backgroundImage: `url(${wallPlacementImage})` }}
            inactive={+(placing && !placingWall)}
            selected={+placingWall}
            onKeyPress={event => event.keyCode === 13 && handleClick(onToggleWallPlacement)}
            onClick={() => selectWall() && handleClick(onToggleWallPlacement)}
          />
        </MyToolTip>
        <MyToolTip
          title={__(placingDoor ? 'stop_door_placement' : 'start_door_placement')}
          placement="top"
        >
          <ImageButton
            inactive={+(placing && !placingDoor)}
            selected={+placingDoor}
            onKeyPress={event => event.keyCode === 13 && handleClick(onToggleDoorPlacement)}
            onClick={() => selectDoor() && handleClick(onToggleDoorPlacement)}
            style={{ backgroundImage: `url(${doorPlacementImage})` }}
          />
        </MyToolTip>
        <Dialog
          open={displayDialog}
          title={__('article_deletion')}
          message={__('confirm_article_deletion')}
          type="warning"
          onConfirm={() =>
            onTemplateRemove() && onDeleteExhibition() && closeDialog() && placeStructure()
          }
          onReject={() => closeDialog()}
        />
      </ConstructionList>
    </ToolbarSection>
  );
};

const mapState = (state, { exhibitionId }) => ({
  placing: !!getUIProperty(state, `exhibitions/${exhibitionId}/placingStructure`),
  placingWall: getUIProperty(state, `exhibitions/${exhibitionId}/placingStructure`) === 'Wall',
  placingDoor: getUIProperty(state, `exhibitions/${exhibitionId}/placingStructure`) === 'Door',
  requestingIsEmpty: getUIProperty(state, `exhibitions/${exhibitionId}/requestingIsEmpty`),
  isExhibitionEmpty: !!getUIProperty(state, `exhibitions/${exhibitionId}/isEmpty`),
  displayDialog: getUIProperty(state, `exhibitions/${exhibitionId}/displayStructureDialog`),
  selectedStructure: getUIProperty(state, `exhibitions/${exhibitionId}/selectedStructure`),
  onDelete: !!getUIProperty(state, `exhibitions/${exhibitionId}/onDelete`),
  changeTimestamp: getUIProperty(state, `exhibitions/${exhibitionId}/changeTimestamp`),
  keyboardCapture: getUIProperty(state, `exhibitions/${exhibitionId}/keyboardCapture`),
});

const mapDispatch = (
  dispatch,
  { exhibitionId, placingDoor, placingWall, requestingIsEmpty, onDelete },
) => ({
  onToggleWallPlacement: () =>
    dispatch(
      setUIProperty(
        `exhibitions/${exhibitionId}/placingStructure`,
        placingWall ? undefined : 'Wall',
      ),
    ),
  onToggleDoorPlacement: () =>
    dispatch(
      setUIProperty(
        `exhibitions/${exhibitionId}/placingStructure`,
        placingDoor ? undefined : 'Door',
      ),
    ),
  selectDoor: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/selectedStructure`, 'Door')),
  selectWall: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/selectedStructure`, 'Wall')),
  requestIsEmpty: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/requestingIsEmpty`, !requestingIsEmpty)),
  onDeleteExhibition: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/onDelete`, !onDelete)),
  closeDialog: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayStructureDialog`, false)),
  openDialog: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayStructureDialog`, true)),
  onTemplateRemove: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/selectedTemplate`, undefined)),
});

const mapLifeCycle = {
  onDidUpdate: (prevProps, props) => {
    if (prevProps.changeTimestamp !== props.changeTimestamp) {
      props.requestIsEmpty();
    }
  },
};

const ExhibitionSpacePanel = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(mapLifeCycle),
)(ExhibitionSpacePanelView);

export default ExhibitionSpacePanel;
