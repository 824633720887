import React, { useState } from 'react';
import logo from '../styles/images/logo-artsteps.png';
import { __ } from 'artsteps2-common';

import MetaTags from '../components/layouts/MetaTags';
import FullLayout from '../components/layouts/FullLayout';

import { setLocation, apiPOST, addMessage, clearMessages, clearFormData } from '../actions';
import { compose, withDispatch, withState, withLocalState } from '../enhancers';
import { getForm } from '../reducers';
import { Text, StyledButton, StyledTextField, VerticalItems } from '../styles/GenericStyled';
import Link from '../components/generic/Link';
import colors from '../styles/colors';

const isValid = mail => {
  const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!mail.match(mailformat)) {
    return false;
  }

  return true;
};

const EmailVerificationRecoveryView = ({
  onRedirect = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  sendVerificationRecoveryRequest = email => Promise.resolve({ response: { error: 'error' } }),
  onClearMessages = () => Promise.resolve(false),
  onClearForm = () => Promise.resolve(false),
  setVerificationRecoveryReqStatus = () => 'notSent',
  verificationRecoveryReqStatus = 'notSent',
  form = { data: {}, name: 'verificationRecovery' },
}) => {
  const [email, setEmail] = useState('');
  const onSubmit = () => {
    if (!isValid(email)) {
      return onAddMessage({ title: __('invalid_email'), type: 'error' });
    }

    if (!email) {
      return onAddMessage({ title: __('empty_field_email'), type: 'error' });
    }

    return sendVerificationRecoveryRequest(email).then(({ response }) => {
      if (response.error) {
        onAddMessage({ title: __(response.error), type: 'error' });
        return;
      }
      onClearMessages();
      // onClearForm();
      setVerificationRecoveryReqStatus('Sent');
    });
  };

  return (
    <FullLayout>
      <MetaTags title={__('sign_in')} description={__('welcome_header')} />
      <div className="login-form">
        <div className="ui two column stackable grid">
          <div className="stretched column cover-column">
            <div className="ui basic segment">
              <h1 className="ui header white-text">{__('welcome_header')}</h1>
              <h4 style={{ whiteSpace: 'pre-line' }}>{__('welcome_subtitle')}</h4>
            </div>
          </div>
          <div className="column" style={{ display: 'block' }}>
            <VerticalItems margin="20px">
              <Link to="/">
                <img style={{ width: '80px', height: '80px' }} src={logo} alt={__('brand_name')} />
              </Link>
              <Text type="h6">{__('Email Verification')}</Text>
              {verificationRecoveryReqStatus === 'notSent' && (
                <Text>{__('verification_recovery')}</Text>
              )}
              {verificationRecoveryReqStatus === 'Sent' && (
                <Text>{__('verification_recovery_sent')}</Text>
              )}
              <StyledTextField
                onKeyDown={e => e.keyCode === 13 && onSubmit()}
                id="verificationRecovery"
                name="verificationRecovery"
                placeholder="Account Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
                variant="outlined"
                required
                error={false}
              />
              <StyledButton
                disabled={verificationRecoveryReqStatus === 'Sent'}
                type="secondary"
                onClick={onSubmit}
              >
                {__('Resend Verification')}
              </StyledButton>
            </VerticalItems>
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

const mapDispatch = dispatch => ({
  onRedirect: page => dispatch(setLocation(page)),
  onAddMessage: message => dispatch(addMessage(message, 'verificationRecovery')),
  onClearMessages: () => dispatch(clearMessages('verificationRecovery')),
  onClearForm: () => dispatch(clearFormData('verificationRecovery')),
  sendVerificationRecoveryRequest: email =>
    dispatch(apiPOST('auth/artsteps/verification-recovery', { email })),
});

const mapState = state => ({
  form: getForm(state, 'verificationRecovery'),
});

const EmailVerificationRecoveryPage = compose(
  withLocalState('verificationRecoveryReqStatus', 'setVerificationRecoveryReqStatus', 'notSent'),
  withDispatch(mapDispatch),
  withState(mapState),
)(EmailVerificationRecoveryView);

export default EmailVerificationRecoveryPage;
