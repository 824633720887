import React, { useState, useEffect, useLayoutEffect } from 'react';
import { __ } from 'artsteps2-common';

import { Text, HorizontalItems } from '../../styles/GenericStyled';

import { getUIProperty } from '../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { setUIProperty, apiGET, setLocation } from '../../actions';
import Link from '../generic/Link';

import { MenuStyled, MenuItemStyled, HeaderLink } from '../../styles/GenericStyled';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// const creatorSteps = ['Create Space', 'Add Content', 'Plan Tour', 'Enter Details'];

/* States that are needed here:
  Creator View,
  LoggedIn user View,
  Curator View,
  ExhibitionPreview,
  Contributed ?
*/

export const SectorsView = ({ onRedirect = () => Promise.resolve(false), sectors = [] }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = React.useRef();

  const handleClick = event => {
    setAnchorEl(ref.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HorizontalItems>
        <Text
          type="body1"
          mycolor="#343a40"
          ref={ref}
          aria-controls="simple-menu"
          aria-haspopup="true"
          active={+false}
        >
          <HeaderLink
            mycolor="rgb(102 102 102)"
            onClick={e => {
              handleClick(e);
              e.preventDefault();
            }}
          >
            Halls
          </HeaderLink>
          <ArrowDropDownIcon
            onClick={e => {
              handleClick(e);
              e.preventDefault();
            }}
            style={{ color: 'rgb(102 102 102)' }}
          />
        </Text>
      </HorizontalItems>
      <MenuStyled
        disableScrollLock
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {sectors.map(sector => (
          <MenuItemStyled
            key={sector._id}
            onClick={() => {
              handleClose();
              onRedirect(`/view/${sector._id}`);
            }}
          >
            {sector.title}
          </MenuItemStyled>
        ))}
      </MenuStyled>
    </>
  );
};

const mapState = state => ({
  sectors: getUIProperty(state, 'sectors'),
});

const mapDispatch = (dispatch, { categories }) => {
  const sectorCategoryId = categories.find(category => category.title.toLowerCase() === 'sector')
    ?._id;
  return {
    onRedirect: location => dispatch(setLocation(location)),
    onFetchSectors: () =>
      dispatch(
        apiGET('exhibitions', {
          include: 'categories',
          filter: { categories: sectorCategoryId },
        }),
      ),
    setSectors: sectors => dispatch(setUIProperty(`sectors`, sectors)),
  };
};

const lifecycleMap = {
  onDidMount: ({ onFetchSectors, setSectors }) => {
    onFetchSectors().then(({ response: exhibitions }) => setSectors(Object.values(exhibitions)));
  },
};

const Sectors = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(SectorsView);

export default Sectors;
