import React from 'react';
import '../../styles/contributionBanner.css';

import { compose, withDispatch, withState } from '../../enhancers';
import { setUIProperty } from '../../actions';

import { StyledButton } from '../../styles/GenericStyled';
import Paypal from '../../modal_content/Paypal';
import OurModal from '../../components/generic/OurModal';
import { getUIProperty } from '../../reducers';

function ContributeBannerRaw({
  onToggleDonateModal = () => Promise.resolve(false),
  donateModal = false,
  ...props
}) {
  return (
    <>
      {props.open && (
        <div className="contributionBanner-div">
          <OurModal
            width={400}
            height={550}
            openModal={donateModal}
            setOpenModal={onToggleDonateModal}
          >
            <Paypal />
          </OurModal>
          <div className="contribution-banner">
            <div className="bell-wrapper">
              <i className="big bell icon" style={{ margin: 0 }} />
            </div>
            <div className="contribution-banner-text">
              <h4 className="contribution-header">SUPPORT ARTSTEPS.</h4>
              <p id="contribution-banner-subtext">
                Donate an amount and/ or become a Verified Contributor.
              </p>
            </div>
            <div className="contribution-link-wrapper">
              <StyledButton
                type="secondary"
                mycolor="white"
                background="transparent"
                border={1}
                bordercolor="white"
                onClick={() => {
                  props.hide();
                  props.onShowContributionModal();
                  props.onContributionModalInteraction();
                }}
              >
                Get verified
              </StyledButton>
              <StyledButton
                style={{ margin: '0px 0px 0px 20px' }}
                type="secondary"
                mycolor="#343A40"
                background="#ecc62c"
                onClick={() => onToggleDonateModal(true)}
              >
                Donate
              </StyledButton>
            </div>
          </div>
          <span
            className="x-icon-banner-contribution"
            onClick={() => {
              props.hide();
              props.onContributionModalInteraction();
            }}
          >
            &#x2715;
          </span>
        </div>
      )}
    </>
  );
}

const mapState = state => ({
  donateModal: getUIProperty(state, 'donateModal'),
});

const mapDispatch = dispatch => ({
  onShowContributionModal: () => dispatch(setUIProperty('contributionModalOpen', true)),
  onToggleDonateModal: show => dispatch(setUIProperty('donateModal', show)),
  onContributionModalInteraction: () =>
    dispatch(setUIProperty('contributionBannerInteraction', true)),
});

const ContributeBanner = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ContributeBannerRaw);

export default ContributeBanner;
