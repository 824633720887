import React from 'react';
import qs from 'qs';
import { NavLink } from 'react-router-dom';
import { getLocation, getUIProperty } from '../../reducers';
import { withState, withDispatch, compose } from '../../enhancers';
import { setUIProperty } from '../../actions';

export const LinkView = ({
  to = '/',
  exact = false,
  strict = false,
  children = [],
  location = {},
  className,
  activeClassName,
  isActive,
  linkDisabled,
  onOpenDialog,
  hasChanges,
  onClickedPathLink,
  mycolor,
  onClick = () => true,
}) => (
  <NavLink
    style={{ color: mycolor }}
    to={to && typeof to === 'object' && to.query ? { ...to, search: qs.stringify(to.query) } : to}
    exact={exact}
    strict={strict}
    isActive={isActive}
    className={className}
    activeClassName={activeClassName}
    location={location}
    onClick={e => {
      onClick(e);
      if (linkDisabled) e.preventDefault();
      if (hasChanges) {
        e.preventDefault();
        onClickedPathLink(to);
        onOpenDialog();
      }
    }}
  >
    {children}
  </NavLink>
);

const mapState = state => ({
  hasChanges: getUIProperty(
    state,
    `exhibitions/${getUIProperty(state, 'currentExhibitionId')}/hasChanges`,
  ),
  currentExhibitionId: getUIProperty(state, 'currentExhibitionId'),
  location: getLocation(state),
});

const mapDispatch = (dispatch, { currentExhibitionId }) => ({
  onOpenDialog: () => dispatch(setUIProperty(`exhibitions/${currentExhibitionId}/dialog`, true)),
  onClickedPathLink: link => dispatch(setUIProperty('activeLinkPath', link)),
});

const Link = compose(withState(mapState), withDispatch(mapDispatch))(LinkView);

export default Link;
