import Link from '../generic/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import * as header from '../../styles/styledComponents/header';
import colors from '../../styles/colors';

const SubMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '200px',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

const StyledMenu = styled(SubMenu)`
  & .MuiMenu-paper {
    top: 68px !important;
  }
  ${props =>
    props.publish === 'true' &&
    css`
      & .MuiMenu-paper {
        top: 127px !important;
      }
    `}
`;

const DropDownTypography = styled(Typography)`
  color: white;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  display: flex;

  ${props =>
    props.mycolor &&
    css`
      font-size: 20px;
      font-weight: normal;
      color: ${props.mycolor};
      font-family: 'Muli', Arial, Helvetica, sans-serif;
    `}

  ${props =>
    props.active === 'true' &&
    css`
      opacity: 100% !important;
    `}

  ${props =>
    props.createdropdown &&
    css`
      min-width: unset;
    `}
    ${props =>
      props.createdropdown &&
      !props.navlink &&
      props.publish === 'undefined' &&
      css`
        min-width: unset;
        font-size: 16px;
        font-family: 'Muli', Regular;
        color: white;
        text-align: center;
      `}
    ${props =>
      props.navlink &&
      props.createdropdown &&
      css`
        font-size: 20px;
        font-family: 'Muli', Regular;
        font-weight: normal;
        color: #343a40;
        text-align: center;
      `}

    ${props =>
      props.publish === 'true' &&
      props.createdropdown &&
      css`
        opacity: 1;
        color: black;
      `}
`;

const DropDownOptions = styled(MenuItem)`
  background-color: #f4f3f3;
  border-radius: 5px;
  border-radius: 5px;
  border-bottom: 1px solid white;
  border-top: 2px solid white;
  padding: 11px 5px 11px 17px;
  color: ${colors.ourBlack};
  font-family: 'Muli', Regular;
`;

const DropDownIcon = styled(ArrowDropDownIcon)`
  transform: rotate3d(0);
  transition: transform 0.2s;
  opacity: 0.7;
  font-size: 28px;
  ${props =>
    props.open &&
    css`
      transform: rotate3d(1, 0, 0, 180deg);
    `}
`;

export default function DropDown({
  create,
  exhibitionTemplates,
  defaultOption,
  onRedirect = () => Promise.resolve(false),
  onCloneTemplateAndInitialize,
  onToggleCreatorView,
  setLoading,
  hasChanges,
  onOpenDialog,
  options,
  navlink,
  staticOption,
  publish,
  onUpsert,
  exhibition,
  onSetImage,
  currentUser,
  privateSpaceLinks = false,
  activeLink,
  onClickedPathLink,
  memberOfPrivateSpace,
  setOpenLogin,
  willRedirect,
  mycolor,
}) {
  const [selectedOption, setOption] = useState(defaultOption);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeOption = event => {
    setOption(event.target.getAttribute('value'));
  };

  let items = [];

  // Create with templates
  if (create) {
    // Add static option to Create New
    items.push(
      <DropDownOptions
        key="new"
        value="Create new"
        onClick={() => {
          if (currentUser) {
            onToggleCreatorView(true);
          } else {
            setOpenLogin(true);
          }
        }}
      >
        {currentUser ? (
          <Link mycolor={colors.ourBlack} to="/curate/new/1">
            Create New
          </Link>
        ) : (
          'Create New'
        )}
      </DropDownOptions>,
    );
    items.push(
      exhibitionTemplates &&
        Object.keys(exhibitionTemplates).map(key => (
          <DropDownOptions
            value={exhibitionTemplates[key].title}
            key={key}
            onClick={() => {
              if (currentUser) {
                onCloneTemplateAndInitialize(exhibitionTemplates[key]._id, onRedirect, setLoading);
              } else {
                setOpenLogin(true);
              }
            }}
          >
            {`Use the template: "${exhibitionTemplates[key].title}"`}
          </DropDownOptions>
        )),
    );
  } else if (publish) {
    // If the user is not a member in a private Space Show only one Option
    if (!memberOfPrivateSpace) items.splice(1, 1);
    items =
      options &&
      options.map((option, idx) => (
        <DropDownOptions
          key={`option-${idx}`}
          value={option.name || option}
          onClick={() => {
            onUpsert(
              idx === 0
                ? { published: !exhibition.published }
                : { publishedPrivateSpace: !exhibition.publishedPrivateSpace },
            ).then(
              () =>
                (exhibition.image && (exhibition.image.bin || exhibition.image.uri)) ||
                onSetImage(),
            );
          }}
        >
          {option.name || option}
        </DropDownOptions>
      ));
  } else {
    items =
      options &&
      options.map((option, idx) => (
        <DropDownOptions
          onClick={() => {
            if (willRedirect) window.location.href = option.path;
          }}
          key={`option-${idx}`}
          value={option.name || option}
        >
          {!willRedirect ? (
            <Link mycolor={colors.ourBlack} to={`/${option.path}`}>
              {option.name || option}
            </Link>
          ) : (
            <> {option.name || option} </>
          )}
        </DropDownOptions>
      ));
  }

  return (
    <>
      <header.LinkTypography
        component="span"
        key="dropdown links"
        id="menu-dropdown"
        navlink={navlink}
        onClick={handleMenu}
        publish={`${publish}`}
      >
        <DropDownTypography
          mycolor={mycolor}
          navlink={navlink}
          publish={`${publish}`}
          active={`${!staticOption ? activeLink === selectedOption : activeLink === staticOption}`}
          onClick={() => {
            handleClose();
          }}
        >
          {staticOption}
          <DropDownIcon
            style={{ transition: 'opacity 1 ease 2s' }}
            color="inherit"
            open={open}
            publish={`${publish}`}
            navlink={`${navlink}`}
          />
        </DropDownTypography>
      </header.LinkTypography>
      <StyledMenu
        id="menu-dropdown"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        publish={`${publish}`}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {items}
      </StyledMenu>
    </>
  );
}
