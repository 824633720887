import React, { useState, useEffect } from 'react';
import { BodyWithPadding, Text } from '../../../styles/GenericStyled';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';

const ClientHelp = () => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    localStorage.setItem('dontShowHelp', checked);
  }, [checked, setChecked]);

  return (
    <BodyWithPadding padding="3%">
      <Text align="center">
        <InfoIcon fontSize="large" />
      </Text>
      <Text type="h5" align="center">
        Info
      </Text>
      <Grid container spacing={1} justify="center" alignItems="center">
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={6}>
            <img
              style={{ objectFit: 'contain' }}
              width="250"
              height="250"
              src="/images/seller.png"
            />
          </Grid>
          <Grid item xs={6}>
            <Text type="h6">
              If a company’s representative is online, their avatar should appear inside the booth
              and be available to chat.
            </Text>
            <Text type="h6">
              If you want to talk with the representative, click on their avatar to communicate.
            </Text>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3} alignItems="center">
          <Grid item xs={6}>
            <img
              style={{ objectFit: 'contain' }}
              width="250"
              height="250"
              src="/images/contact-form.png"
            />
          </Grid>
          <Grid item xs={6}>
            <Text type="h6">
              Alternatively, if a company rep is not available, you can communicate by using the
              OPEN CONTACT FORM button.
            </Text>
          </Grid>
        </Grid>
        <Grid item>
          <Text type="body">
            Don't show this message again
            <Switch
              checked={checked}
              onChange={e => setChecked(e.target.checked)}
              name="checkedA"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Text>
        </Grid>
      </Grid>
    </BodyWithPadding>
  );
};

export default ClientHelp;
