import styled, { css } from 'styled-components';
import Card from '@material-ui/core/Card';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';
import Link from '../../generic/Link';

/* <------ Exhhibition-Likes ------> */
export const LikesDisplay = styled.div`
  display: flex;
  align-items: center;
  opacity: 1 !important;

  & > * {
    vertical-align: center;
  }
`;
export const InvisibleButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-size: 0;
`;

/* <------ Exhhibition-Settings ------> */
export const SettingsDots = styled(Box)`
  background: #343a40;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 50%;
  right: 10px;
  top: 10px;
  opacity: 1;
  & > * {
    color: white;
    opacity: 1;
    text-align: center;
    vertical-align: middle;
  }
`;

export const ExhibitionMenu = styled.div`
  display: flex;
  flex-direction: column;
  background: black;
  color: white;
`;

export const ExhibitionMenuItem = styled.div`
  display: flex;
  vertical-align: middle;
  padding: 10px;
  color: white;

  & > * {
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }

  :hover {
    background: #303030;
  }
`;

/* <------ Exhhibition-Preview ------> */
export const BookMarkDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 10px;
  & > * {
    display: inline-flex;
    vertical-align: center;
    text-align: center;
  }
`;

export const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.7;
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const SkeletonCard = styled(Skeleton)`
  margin: auto;
  padding: 0;
  position: relative;
  height: 260px;
  min-height: 100px;
  max-width: 420px;
  min-width: 420px;

  @media (max-width: 488px) {
    max-width: 300px;
    min-width: unset;
  }

  ${props =>
    props.viewpage &&
    css`
      @media (min-width: 1280px) {
        min-width: 195px;
      }
      @media (min-width: 1500px) {
        height: 178px;
      }
      @media (max-width: 1500px) and (min-width: 1280px) {
        height: 143px;
      }
    `}
`;

export const MediaContainer = styled.div`
  margin: auto;
  padding: 0;
  position: relative;
  height: 260px;
  min-height: 100px;

  &:hover {
    #hidden-content {
      display: inline-flex;
      opacity: 1;
    }
  }

  max-width: 420px;
  min-width: 420px;
  overflow: visible;

  @media (max-width: 488px) {
    max-width: 300px;
    min-width: unset;
  }

  ${props =>
    props.viewpage &&
    css`
      @media (min-width: 1280px) {
        min-width: unset;
        height: auto;
      }
    `}
`;

export const HiddenContent = styled.div`
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -ms-transition: opacity 0.5s;
  -o-transition: opacity 0.5s;
  transition: opacity 0.5s;
  display: none;
`;

export const VerticalItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  & > * {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
  }
  & > span > * {
    margin-left: 10px;
  }
`;

export const ExhibitionDescription = styled.div`
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 32px;
  margin-top: 96px;
  width: 100%;
  color: white;
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
`;
export const TitleNameDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;
export const TitleLink = styled(Link)`
  & > span {
    color: #5c5858;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 2; number of lines to show */
    -webkit-box-orient: vertical;
    font-weight: 200;
    font-size: 1.6em;
    text-align: left;
    min-height: 22px;
    max-width: 325px;
    line-height: 32px;
  }
  ${props =>
    props.viewpage &&
    css`
      & > span {
        line-height: 24px;
        font-size: 1.1em;
      }
    `}
`;

export const ExhibitionInfo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
`;

export const ViewsAndCreated = styled.div`
  position: absolute;
  color: white;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  opacity: 0.8;
  bottom: 4px;
  & span:first-child {
    margin-left: 10px;
  }
  & > * {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    text-align: center;
  }
  & > span > * {
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 10px;
  }
`;

export const UsernameLink = styled(Link)`
  color: #b0aaaa;
  margin-left: 10px;

  :hover > span {
    color: #b0aaaa;
  }
  & > * {
    font-style: Bold;
    font-size: 1.1em;
    text-align: left;
    letter-spacing: 0px;
  }
`;

export const FollowButton = styled(Box)`
  &:hover {
    background: #555;
  }
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  background: ${props => props.color || 'black'};
  cursor: pointer;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 32px;
  border-radius: 20px;
  & > * {
    color: ${props => (props.color === 'black' ? 'white' : 'black')};
    text-align: center;
    vertical-align: middle;
  }
`;

export const ExhibitionCard = styled(Card)`
  width: 100%;

  &:hover {
    #hidden-content {
      display: inline-flex;
      opacity: 1;
    }
  }
`;
