import React, { useEffect, useState } from 'react';
import { __ } from 'artsteps2-common';
import Loader from '../../generic/Loader';
import Form from '../../generic/forms/Form';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { API_STATUS, getApiStatus, getApiResource, getFormProperty } from '../../../reducers';
import { apiGET, setFormProperty, handleFormPropertyChange } from '../../../actions';
import { toInches, toMeters } from '../../../reusableFunctions/dimensionsConvertions';

export const ArtifactInfoEditorView = ({
  artifactId = 'new',
  form = { data: {} },
  licenses = [],
  saveDisabled = false,
  ready = true,
  extraFields = [],
  dimensions = [],
  dimensionsInches = [],
  meters = {},
  inches = {},
  onClose,
  onFieldChange,
  onRemove,
  setProperty,
  onSubmit = () => Promise.resolve(false),
}) => {
  const convertFields = e => {
    // check if it is from meters or from inches

    const metric = e.target.name.split('.')[0]; // dimensions or dimensionsInches
    const dimension = e.target.name.split('.')[1]; // width or height or depth

    const fieldToChange = `${
      metric === 'dimensions' ? 'dimensionsInches' : 'dimensions'
    }.${dimension}`; // change to Inches if meters and vice versa

    if (metric === 'dimensions') setProperty(fieldToChange, toInches(e.target.value).toFixed(2));
    else setProperty(fieldToChange, toMeters(e.target.value).toFixed(2));
  };

  const [isInitialized, setIsInitizalized] = useState(false);

  /* Init form because inches are not stored in db */
  useEffect(() => {
    if (!isInitialized) {
      meters.width && setProperty('dimensionsInches.width', toInches(meters.width).toFixed(2));
      meters.height && setProperty('dimensionsInches.height', toInches(meters.height).toFixed(2));
      meters.depth && setProperty('dimensionsInches.depth', toInches(meters.depth).toFixed(2));
      (meters.width || meters.height || meters.depth) && setIsInitizalized(true);
    }
  }, [meters.width, meters.height, meters.depth]);

  return (
    <div className="artifact-editor">
      <Form
        artifactId={artifactId}
        onChange={e => {
          onFieldChange(e);
          convertFields(e);
        }}
        form={form}
        ready={ready}
        onSubmit={onSubmit}
        fields={[
          ...extraFields,
          {
            title: { type: 'text', label: __('title'), required: true },
            'model.nonInteractive': {
              type: 'toggle',
              default: false,
              toFormValue: value => !value,
              fromFormValue: value => !value,
              label:
                form.data.model && form.data.model.nonInteractive
                  ? __('non_interactive')
                  : __('interactive'),
              hint:
                form.data.model && form.data.model.nonInteractive
                  ? __('non_interactive_hint')
                  : __('interactive_hint'),
            },
            caption: { type: 'textarea', label: __('description') },
            link: { type: 'text', label: 'Exhibition link', hint: 'Id of another exhibition' },
            chips: {
              type: 'chips-input',
              label: 'Artifact Tags',
              hint: 'Categorize your artifacts',
            },
            audio: { type: 'audio', label: __('audio'), hint: __('audio_hint_image') },
            ...(dimensions.length === 0
              ? {}
              : {
                  dimensions: {
                    label: __('dimensions'),
                    type: dimensions.reduce(
                      (dimensionTypes, name) => ({
                        ...dimensionTypes,
                        [name]: {
                          placeholder: __(name),
                          type: 'number',
                          step: 0.01,
                          min: 0.01,
                          max: 3,
                        },
                      }),
                      {},
                    ),
                  },
                }),
            ...(dimensionsInches.length === 0
              ? {}
              : {
                  dimensionsInches: {
                    label: __('dimensions_inches'),
                    type: dimensionsInches.reduce(
                      (dimensionTypes, name) => ({
                        ...dimensionTypes,
                        [name]: {
                          placeholder: __(name),
                          type: 'number',
                          step: 0.01,
                          min: 1,
                          max: toInches(3).toFixed(2),
                        },
                      }),
                      {},
                    ),
                  },
                }),
            license: {
              type: 'select',
              required: true,
              options: licenses.reduce(
                (options, license) => ({
                  ...options,
                  [license._id]: `${license.title} (${license.acronym})`,
                }),
                {},
              ),
              label: __('license'),
            },
          },
        ]}
        buttons={[
          ...(onRemove
            ? [
                {
                  label: __('remove'),
                  onClick: onRemove,
                  colour: 'delete',
                  icon: 'trash',
                  type: 'button',
                },
              ]
            : []),
          {
            label: __('save'),
            icon: 'save',
            colour: 'secondary',
            disabled: saveDisabled || !form.data.title,
          },
        ]}
      />
      {ready || <Loader />}
    </div>
  );
};

const mapState = (state, { ready, artifactId }) => ({
  licenses: Object.values(getApiResource(state, 'licenses')),
  ready: ready && getApiStatus(state, 'licenses') === API_STATUS.IDLE,
  meters: getFormProperty(state, `artifact:${artifactId || 'new'}`, 'dimensions'),
  inches: getFormProperty(state, `artifact:${artifactId || 'new'}`, 'dimensionsInches'),
});

const mapDispatch = (dispatch, { artifactId, form }) => ({
  onFieldChange: event => dispatch(handleFormPropertyChange(form.name, event)),
  onFetchLicenses: () => dispatch(apiGET('licenses')),
  setProperty: (reduxName, value) =>
    dispatch(setFormProperty(`artifact:${artifactId || 'new'}`, reduxName, value)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchLicenses }) => onFetchLicenses(),
};

const ArtifactInfoEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ArtifactInfoEditorView);

export default ArtifactInfoEditor;
