import React from 'react';
import ArtifactViewer from '../../artifacts/ArtifactViewer';
import Loader from '../../generic/Loader';
import Overlay from '../../generic/Overlay';
import utils from '../../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { API_STATUS, getApiResource, getApiStatus, getUIProperty } from '../../../reducers';
import { apiGET, setUIProperty } from '../../../actions';

export const ExhibitionAccessibilityFrameView = ({
  exhibition = {},
  ready = true,
  displayedArtifactId,
  onOverlayOpen = () => Promise.resolve(false),
  onOverlayClose = () => Promise.resolve(false),
}) => {
  const renderArtifacts = (artifacts = []) =>
    artifacts.length > 0 && (
      <div className="view-artifacts ui two column stackable grid">
        {artifacts
          .map(artifact =>
            (exhibition.artifacts || []).find(a => a.artifactId === artifact.artifactId),
          )
          .filter(
            artifact => artifact && artifact.artifactType !== 'text' && !artifact.nonInteractive,
          )
          .map((artifact, idx) => (
            <div key={idx} className="column">
              <div className="artifact-container">
                <div className="artifact-preview">
                  <button
                    className="anchor"
                    onKeyPress={event =>
                      event.keyCode === 13 &&
                      !artifact.nonInteractive &&
                      onOverlayOpen(artifact.artifactId)
                    }
                    onClick={() => artifact.nonInteractive || onOverlayOpen(artifact.artifactId)}
                  >
                    {artifact.artifactType === 'image' && (
                      <img className="ui small image" src={artifact.uri} alt={artifact.title} />
                    )}
                    {artifact.artifactType === 'video' && (
                      <video controls alt={artifact.title}>
                        <source src={artifact.uri} />
                        <track kind="captions" />
                      </video>
                    )}
                    {artifact.artifactType === 'object' && (
                      <img className="ui small image" src={artifact.preview} alt={artifact.title} />
                    )}
                  </button>
                </div>
                <div className="artifact-content">
                  <button
                    className="button"
                    onKeyPress={event => event.keyCode === 13 && onOverlayOpen(artifact.artifactId)}
                    onClick={() => onOverlayOpen(artifact.artifactId)}
                  >
                    <h3 className="header">{artifact.title}</h3>
                  </button>
                  {artifact.description}
                </div>
              </div>
            </div>
          ))}
      </div>
    );

  const renderStorypoints = () => (
    <div className="view-storypoints ui vertical list">
      {(exhibition.storyPoints || []).map(storypoint => (
        <div key={storypoint.storyPointId} className="item" id={`point${storypoint.storyPointId}`}>
          <div className="content">
            <h2 className="header">{storypoint.pointTitle}</h2>
            {storypoint.audioUri && (
              <div className="storypoint-audio">
                <audio controls alt={storypoint.pointDescription}>
                  <source src={storypoint.audioUri} />
                  <track kind="captions" />
                </audio>
              </div>
            )}
            {storypoint.pointDescription}
            <div className="ui segment vertical">{renderArtifacts(storypoint.artifacts)}</div>
          </div>
        </div>
      ))}
    </div>
  );

  if (!ready) {
    return (
      <div className="exhibition-frame">
        <div className="exhibition-accessibility-view viewer">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="exhibition-frame ui">
      <div className="exhibition-accessibility-view viewer ui segment basic">
        <div className="exhibition-layout ui container">
          <h1>{exhibition.exhibitionTitle}</h1>
          <p>{exhibition.exhibitionDescription}</p>
          {renderStorypoints()}
        </div>
        <Overlay open={!!displayedArtifactId} fixed onClose={onOverlayClose}>
          <ArtifactViewer artifactId={displayedArtifactId} />
        </Overlay>
      </div>
    </div>
  );
};

const createFilter = exhibitions => ({ filter: { exhibitions } });

const mapState = (state, { exhibitionId }) => {
  const exhibition = getApiResource(state, `exhibitions/${exhibitionId}`);
  const artifacts = Object.values(getApiResource(state, 'artifacts', createFilter(exhibitionId)));
  const storypoints = Object.values(
    getApiResource(state, 'storypoints', {
      ...createFilter(exhibitionId),
      include: 'description',
    }),
  ).sort((pointA, pointB) => pointA.order - pointB.order);
  return {
    exhibition: utils.exhibition.exportExhibition(exhibition, artifacts, storypoints),
    ready: true,
    displayedArtifactId: getUIProperty(state, `exhibitions/${exhibitionId}/displayedArtifact`),
  };
};

const mapDispatch = (dispatch, { exhibitionId, isPrivateSpace }) => ({
  onFetchExhibition: () =>
    dispatch(
      apiGET(`${!isPrivateSpace ? 'exhibitions' : 'exhibitionsPrivate'}/${exhibitionId}`, {
        include: 'model',
      }),
    ),
  onFetchArtifacts: () => dispatch(apiGET('artifacts', createFilter(exhibitionId))),
  onFetchStorypoints: () =>
    dispatch(
      apiGET('storypoints', {
        ...createFilter(exhibitionId),
        include: 'description',
      }),
    ),
  onOverlayOpen: artifact =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayedArtifact`, artifact)),
  onOverlayClose: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayedArtifact`, null)),
});

const onInitialization = ({ onFetchExhibition, onFetchArtifacts, onFetchStorypoints }) =>
  onFetchExhibition()
    .then(() => onFetchArtifacts())
    .then(() => onFetchStorypoints());

const lifecycleMap = {
  onWillMount: props => onInitialization(props),
  onDidUpdate: ({ exhibitionId }, props) =>
    exhibitionId !== props.exhibitionId && onInitialization(props),
};

const ExhibitionAccessibilityFrame = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionAccessibilityFrameView);

export default ExhibitionAccessibilityFrame;
