import React, { useState, useEffect } from 'react';
import { PaperStyled } from '../../styles/PrivateSpacesPageStyled';
import { Text, BodyWithPadding, VerticalItems } from '../../styles/GenericStyled';
import colors from '../../styles/colors';
import useWindowSize from '../../hooks/windowSize';

const PaperContainer = ({ text, subtext, description, source }) => {
  const breakPoint = 1200;
  const size = useWindowSize();
  const [breaks, setBreaks] = useState(false);

  useEffect(() => {
    setBreaks(size.width < breakPoint);
  }, [size.width]);

  return (
    <PaperStyled elevation={5}>
      <BodyWithPadding padding="28px">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <Text style={{ marginBottom: '20px' }} align="center" type="h5">
            {text}
          </Text>
          <Text align="center" type="body1">
            {description}
          </Text>
        </div>
      </BodyWithPadding>
    </PaperStyled>
  );
};

export default PaperContainer;
