import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import Link from '../../components/generic/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';

export const CustomToolBar = styled(Toolbar)`
  padding-left: 0px !important;
  padding-right: 16px !important;
  min-height: 70px;
  @media (max-width: 720px) {
    min-height: 48px;
  }
  ${props =>
    props.stepper === 'true' &&
    css`
      padding-left: 0 !important;
      min-height: 60px;
    `}
`;

export const LinkTypography = styled(Typography)`
  font-size: 1.129rem;
  font-weight: bold;
  opacity: 87%;
  color: #343a40;
  text-align: center;
  cursor: pointer;

  ${props =>
    props.navlink === 'true' &&
    css`
      display: inline-flex;
      align-items: center;
      & > svg {
        color: #343a40;
        opacity: 0.7;
      }
      & > p {
        opacity: 87%;
      }
      &:hover {
        & > svg {
          opacity: 1 !important;
        }
        & > p {
          opacity: 1;
        }
      }
      body {
        padding-right: 0 !important;
      }
    `}

  ${props =>
    props.createdropdown &&
    !props.navlink &&
    css`
      display: inline-flex;
      padding: 0;
      min-width: 73px;
      justify-content: space-evenly;
      align-items: center;
      font-weight: unset;
      & > svg {
        color: white;
        opacity: 0.5;
        font-size: 28px;
      }
      &:hover {
        opacity: 1;
        & > svg {
          opacity: 0.5;
        }
      }
      body {
        padding-right: 0 !important;
      }
    `}
`;

export const ChangeViewButton = styled(Button)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: grey;
  color: white;
  width: 150px !important;
  margin: auto;
  height: 45px;
  z-index: 50;
`;

export const HeaderLinks = styled(Link)`
  color: #343a40;
  opacity: 0.7;
  &:hover {
    color: #343a40;
    opacity: 1;
  }
`;

export const FloatingAddButton = styled(Fab)`
  position: fixed;
  bottom: 20px;
  right: 24px;
  z-index: 5;
`;

export const CustomMadeTabs = withStyles({
  indicator: {
    bottom: '-1px',
    height: '4px',
    zIndex: 1,
    backgroundColor: '#3cb8a0',
  },
  scroller: {
    overflow: 'visible!important',
    height: '100%',
  },
  flexContainer: {
    overflow: 'visible',
    height: '100%',
  },
})(props => <PaddedTabs {...props} />);

export const PaddedTabs = styled(Tabs)`
  overflow: visible;
  align-self: stretch;
  transition: display 250ms;
  display: flex;
`;

export const NavLinkGrid = styled(Box)`
  min-width: fit-content;
  overflow: visible;
  min-height: inherit;

  ${props =>
    props.create === 'true' &&
    css`
      padding-right: 0;
      @media (max-width: 720px) {
        display: none;
      }
    `}
  ${props =>
    !props.navbarview &&
    css`
      @media (max-width: 960px) {
        display: none;
      }
    `}
`;

export const BreadcrumpTab = styled(Tab)`
  overflow: visible;
  height: 100%;
  text-transform: none;
  font-size: 1rem;
  color: #343a40;
  font-weight: bold;
  opacity: 1;
  &:hover {
    color: #343a40;
  }

  ${props =>
    props &&
    props.selected === true &&
    css`
      background-color: #f3f3f3;
    `}

  ${props =>
    props &&
    props.language === 'en' &&
    css`
      min-width: 40%;
    `}

  ${props =>
    props &&
    props.language === 'fr' &&
    css`
      min-width: 34%;
    `}

  @media(max-width: 798px) {
    min-width: 96px;
    /* padding: 6px 0; */
  }
`;

export const BreadcrumpSublabel = styled.span`
  font-size: 1.2rem;
  height: 24px;

  ${props =>
    props.text === 'true' &&
    css`
      font-size: 0.95rem;
      letter-spacing: 0;
    `}
  ${props =>
    props.switchlabel === 'true' &&
    css`
      color: #343a40;
      font: normal normal normal 18px/23px Muli;
      margin-left: 9px;
    `}
  ${props =>
    props.creator === 'true' &&
    css`
      @media (max-width: 1567px) {
        display: none;
      }
    `}
`;

export const AppBarWrapper = styled(AppBar)`
  padding-right: 0 !important;
  background-color: white;
  ${props =>
    props.stepper === 'true' &&
    css`
      margin-top: 71px;
      background-color: #e4e4e4;
    `}
`;

export const Span = styled.span`
  opacity: 0.5;
  ${props =>
    props.signin === 'false' &&
    css`
      opacity: 1;
    `}

  @media (max-width: 1280px) {
    display: none;
  }
`;

export const HeaderButtons = styled(Button)`
  font-size: 1.1rem;
  padding: 12px 25px;
  border-radius: 27px;
  text-transform: none;
  letter-spacing: 0;
  margin-left: 17px;
  ${props =>
    props.contribute === 1 &&
    css`
      background-color: #fe7670;
      color: white;
      padding: 10px 25px;
      &:hover {
        opacity: 0.5;
        background-color: #fe7670;
        transition: opacity 350ms;
      }
    `}
  ${props =>
    props.create === 'true' &&
    css`
      background-color: #343a40;
      color: #ffffff85;
      padding: 10px 22px 10px 14px;
      &:hover {
        opacity: 0.5;
        background-color: #343a40;
        transition: opacity 350ms;
        color: #ffffff85;
      }
      @media (max-width: 1280px) {
        padding: 13px 22px 13px 14px;
      }
    `}
  ${props =>
    props.createdropdow &&
    css`
      background-color: #343a40;
      color: #ffffff85;
      padding: 10px 11px 10px 25px;
      &:hover {
        opacity: 0.5;
        background-color: #343a40;
        transition: opacity 350ms;
        color: #ffffff85;
      }
    `}
  ${props =>
    props.save === 'true' &&
    css`
      background-color: #12ad8f;
      color: white;
      padding: 16px 30px;
      margin-left: 0;
      &:hover {
        opacity: 0.8;
        background-color: #12ad8f;
        transition: opacity 250ms;
      }
    `}
    ${props =>
      props.savesmall === 'true' &&
      css`
        background-color: #12ad8f;
        color: white;
        padding: 14px 20px;
        margin-left: 0;
        &:hover {
          opacity: 0.8;
          background-color: #12ad8f;
          transition: opacity 250ms;
        }
      `}
    ${props =>
      props.publish === 'true' &&
      css`
        border: 2px solid #343a4066;
        color: #343a40;
        background-color: white;
        margin-left: 0;
        padding: 14 25px;
        &:hover {
          opacity: 0.8;
          background-color: white;
          transition: opacity 250ms;
        }
      `}
      ${props =>
        props.publish === 'true' &&
        props.isPrivateSpace &&
        css`
          padding: 14px 7px 14px 16px;
        `}
    ${props =>
      props.togglesave === 'true' &&
      css`
        display: flex;
      `}
    ${props =>
      props.toggletick === 'false' &&
      css`
        display: none;
      `}
`;

export const AutosaveSwitch = withStyles({
  switchBase: {
    color: '#929292',
    '&$checked': {
      color: '#12AD8F',
    },
    '&$checked + $track': {
      backgroundColor: '#12AD8F',
    },
  },
  checked: {},
  track: {
    backgroundColor: '#929292',
  },
})(Switch);

export const UserSwitch = withStyles({
  switchBase: {
    top: '-2px',
    left: '-3px',
    padding: '1px!important',
    color: '#343A40',
    transform: 'translateX(0px)',
    '&$checked': {
      color: '#343A40',
      transform: 'translateX(27px)!important',
    },
    '&$checked + $track': {
      backgroundColor: '#343A40',
      opacity: 1,
    },
  },
  sizeSmall: {
    width: '66px',
    height: '42px',
    padding: '0px',
    border: '1px solid transparent',
    borderRadius: '67px',
  },
  checked: {
    padding: '2px 2px 2px 0px!important',
  },
  track: {
    backgroundColor: '#343a40',
  },
})(Switch);

export const SwitchLabel = styled(FormControlLabel)`
  padding: 3px 5px;
  background-color: #f3f3f3;
  border-radius: 39px;
  min-width: 213px;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: space-between;
  & > span:last-child {
    text-align: center;
    font-size: 1.119rem;
    letter-spacing: 0;
    opacity: 0.8;
  }

  ${props =>
    props.creator === 'true' &&
    css`
      @media (max-width: 1567px) {
        display: flex;
        width: 76px;
        min-width: 76px;
      }
    `}
`;

export const ToggledGrid = styled(Grid)`
  display: inherit;
  align-items: center;
  ${props =>
    props.autosavetext === 'true' &&
    css`
      color: #343a40;
      margin-right: 10px;
      @media (min-width: 1280px) {
        display: none;
      }
      @media (min-width: 1407px) {
        display: flex;
      }
    `}
    ${props =>
      props.smallheader === 'true' &&
      css`
        margin-right: 10px;
        @media (max-width: 1280px) {
          display: none;
        }
        @media (max-width: 960px) {
        }
      `}
      ${props =>
        props.smallheader === 'false' &&
        css`
          @media (max-width: 1280px) {
            margin-right: 10px;
          }
        `}
    ${props =>
      props.switch === 'true' &&
      props.creatorview &&
      css`
        @media (max-width: 1280px) {
          display: none;
        }
      `}
    ${props =>
      props.togglevision === 'true' &&
      css`
        @media (max-width: 1280px) {
          display: none;
        }
      `}

    ${props =>
      props.toggle === 'false' &&
      css`
        @media (max-width: 1280px) {
          display: flex;
        }
      `}
      ${props =>
        props.togglesave === 'true' &&
        css`
          @media (max-width: 1280px) {
            display: none;
          }
        `}
        ${props =>
          props.navbarview &&
          css`
            @media (max-width: 960px) {
              display: none;
            }
          `};
    ${props =>
      props.navbarview &&
      props.search === 'true' &&
      css`
        @media (max-width: 1144px) and (min-width: 960px) {
          display: none;
        }
      `}
`;

export const AutosaveTexts = styled(Typography)`
  ${props =>
    props.toggle === 'true' &&
    css`
      @media (max-width: 1280px) {
        display: none;
      }
    `}
`;

export const TransitMenuIcon = styled(MenuOpenIcon)`
  transition: transform 0.2s ease-in !important;

  ${props =>
    props.transit === 'true' &&
    css`
      transform: scaleX(1);
    `}
  ${props =>
    props.transit === 'false' &&
    css`
      transform: scaleX(-1);
    `}
`;
export const PlusSign = styled.span`
  height: 21px;
  font-size: 28px;
  position: relative;
  top: -11px;
  left: -6px;
  color: #5d6166;
  @media (max-width: 1280px) {
    top: -10px;
    left: 3px;
    color: #ffffffc4;
  }
`;

export const ToolbarGrid = styled(Grid)`
  min-height: inherit;
  ${props =>
    props.navbarView &&
    css`
      @media (max-width: 960px) {
        justify-content: flex-start;
      }
    `};
`;

export const StyledGrid = styled(Grid)`
  display: inherit;
`;
export const AccountButton = styled(IconButton)`
  margin-left: 6px;
  padding: 0;
  font-size: 4rem;
  color: #c9c9c9;
  border: 6px solid transparent;
  &:hover {
    background-color: #f5f5f5;
  }
  & > div {
    width: 46px;
    height: 46px;
  }
  ${props =>
    props.logged_out === 'true' &&
    css`
      border: 6px solid transparent;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        border: 6px solid transparent;
      }
    `}
`;
export const SmallLogo = styled.img`
  height: auto;
  width: 65px;
  display: flex;
  @media (max-width: 720px) {
    height: 38px;
    width: 45px;
  }
`;
export const AvatarIcon = styled(Avatar)`
  width: 46px;
  height: 46px;
  @media (max-width: 720px) {
    width: 32px;
    height: 32px;
  }
  ${props =>
    props.switch === 'true' &&
    css`
      width: 36px;
      padding: -5px;
      margin: 1px 2px;
      height: 36px;
      box-shadow: 0px 1px 9px -2px white;
      top: -1.5px;
      left: -3.4px;
    `}
  ${props =>
    props.switch === 'false' &&
    css`
      width: 36px;
      padding: -5px;
      margin: 1px 2px;
      height: 36px;
      box-shadow: 0px 1px 9px -2px white;
      top: -0.5px;
      left: -0.4px;
    `}
`;

export const ExhibitionInfo = styled(Box)`
  min-width: 507px;
  @media (max-width: 880px) {
    min-width: 374px;
  }
  & > div {
    padding-left: 30px;
  }
`;

export const CreateTooltip = styled(Tooltip)`
  .MuiTooltip-popper,
  .MuiTooltip-tooltip {
    font-size: 2rem !important;
  }
`;

export const StepsContainer = styled.div`
  margin-left: 6px;

  & > svg > g > g > use {
    fill: gray;
  }
  & > svg {
    height: 38px;
    width: 17px;
  }
`;

export const HeaderLink = styled.span`
  opacity: 87%;
  cursor: pointer;
  ${props =>
    css`
      font-size: 20px;
      color: ${props.mycolor};
      background: ${props.background};
      &:hover {
        color: ${props.mycolor};
      }
    `}
`;
