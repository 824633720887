import React from 'react';
import { __, utils } from 'artsteps2-common';
import publicConfig from 'artsteps2-config/public.json';
import ArtifactInfoEditor from './ArtifactInfoEditor';

const FILE_WIDTH = publicConfig.artifact.object.fileWidth;
const FILE_HEIGHT = publicConfig.artifact.object.fileHeight;
const IMAGE_WIDTH = publicConfig.artifact.object.imageWidth;
const IMAGE_HEIGHT = publicConfig.artifact.object.imageHeight;
const FILE_TYPE = publicConfig.artifact.object.fileType;

export const ObjectEditorView = ({
  form = { data: {} },
  ready = true,
  onSubmit,
  onClose,
  onRemove,
  artifactId,
}) => (
  <ArtifactInfoEditor
    artifactId={artifactId}
    form={form}
    ready={ready}
    onSubmit={onSubmit}
    onClose={onClose}
    onRemove={onRemove}
    dimensions={['width', 'height', 'depth']}
    dimensionsInches={['width', 'height', 'depth']}
    saveDisabled={
      !form.data.files ||
      !form.data.files.length > 0 ||
      (!form.data.files[0].bin && !form.data.files[0].uri) ||
      (!form.data.image && !form.data.files[0].image)
    }
    extraFields={[
      {
        files: {
          type: 'obj',
          label: __('object'),
          required: true,
          multiple: true,
          asArray: true,
          fromFormvalue: (files = []) =>
            files.map(file => ({
              ...file,
              image: form.data.image || {},
            })),
          preview: (file, idx) =>
            (file.bin && file.bin.name && file.bin.name.length) || (file.uri && file.uri.length)
              ? `/api/artifacts/${form.data._id}/files/${idx}/bin`
              : utils.artifact.getArtifactURI(form.data),
          width: FILE_WIDTH,
          height: FILE_HEIGHT,
          mimeType: FILE_TYPE,
          thumbnail: {
            name: 'image',
            width: IMAGE_WIDTH,
            height: IMAGE_HEIGHT,
          },
        },
      },
    ]}
  />
);

const ObjectEditor = ObjectEditorView;

export default ObjectEditor;
