import React, { useEffect, useState } from 'react';
import publicConfig from 'artsteps2-config/public.json';
import { __ } from 'artsteps2-common';
import Fullscreen from 'react-full-screen';
import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { compose, withState, withDispatch, withLifecycle, withProps } from '../../../enhancers';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getUIProperty,
  getAuthUser,
} from '../../../reducers';
import { apiGET, apiPOST, setUIProperty, setLocation } from '../../../actions';
import DeviceWarning from './DeviceWarning';
import UnityWrapper from '../arvr/UnityWrapper';
import WebVRWrapper from '../arvr/WebVRWrapper';
import ExhibitionStoryPlayer from './ExhibitionStoryPlayer';
import ArtifactViewer from '../../artifacts/ArtifactViewer';
import BrowserIconsSection from '../../generic/BrowserIconsSection';
import Loader from '../../generic/Loader';
import ArtifactOverlay from '../../layouts/ArtifactOverlay';
import Dialog from '../../generic/Dialog';
import utils from '../../../utils';
import chatSrc from '../../../styles/images/tutorial/chat.jpg';
import interactSrc from '../../../styles/images/tutorial/interact.jpg';
import mapSrc from '../../../styles/images/tutorial/map.jpg';
import moveSrc from '../../../styles/images/tutorial/move.jpg';
import tourSrc from '../../../styles/images/tutorial/tour.jpg';
import wasdSrc from '../../../styles/images/tutorial/wasd.jpg';
import ExhibitionViewOptions from './ExhibitionViewOptions';
import {
  HorizontalItems,
  VerticalItems,
  Text,
  WPDiv,
  WPFrame,
} from '../../../styles/GenericStyled';
import IFrame from '../../generic/IFrame';
import OurModal from '../../generic/OurModal';
import FakeForm from './FakeForm';
import WPEmbed from './WPEmbed';
import { addMessage } from '../../../actions/messages';
import ClientHelp from './ClientHelp';

const STEP_VIEW = 5;

const requestChat = (socket, exhibitionId, chatInfo, currentUser) => {
  socket &&
    socket.emit(
      `${exhibitionId}_${chatInfo.userId}_message_request`,
      JSON.stringify({
        messageType: 'accept',
        showWarning: true,
        name: currentUser?.profile?.name,
        email: currentUser?.profile?.email,
        userId: currentUser._id,
        roomId: chatInfo.roomId,
        role: currentUser?.profile?.role,
      }),
    );
};

const setChat = (socket, exhibitionId, chatInfo, currentUser, showChat, closeMessage) => {
  socket &&
    socket.emit(
      `${exhibitionId}_${chatInfo.userId}_show_chat`,
      JSON.stringify({
        showWarning: false,
        showChat,
        name: currentUser?.profile?.name,
        email: currentUser?.profile?.email,
        userId: currentUser?._id,
        roomId: chatInfo.roomId,
        role: currentUser?.profile?.role,
        closeMessage,
      }),
    );
};

export const ExhibitionViewFrameView = ({
  exhibition,
  storypoints = [],
  enabledAudio = ['', false],
  displayedArtifactId,
  artifacts,
  ready = false,
  webVR,
  embedded = false,
  fullscreen = false,
  agentWarning = false,
  width,
  height,
  tutorialParagraphs = [],
  onOverlayClose = () => Promise.resolve(false),
  onFullscreenChange = () => Promise.resolve(false),
  onCloseAgentWarning = () => Promise.resolve(false),
  onSetEnabledAudio,
  IFrameLink = undefined,
  setIFrameLink,
  setDisplayedArtifact,
  setFakeCurrentUser,
  setOpenFakeForm,
  currentUser,
  openFakeForm = true,
  onRedirect,
  fetchLinkedExhibition,
  categories = [],
  linkId,
  setLinkId,
  progress = 0,
  onAddMessage,
  chatInfo = {},
  setChatInfo,
  showContactForm = false,
  setShowContactForm,
  onWPVisitor,
}) => {
  const wordpressURL = `${publicConfig.wordpressURL}contact-form/?exhibitionId=${exhibition._id}&visitorId=${currentUser?._id}`;
  const [deviceWarning, setDeviceWarning] = useState(false);
  const [redirectWarning, setRedirectWarning] = useState(false);
  const [isSector, setIsSector] = useState(false);
  const [linkedExhibitionTitle, setLinkedExhibitionTitle] = useState('');
  const [showHelp, setShowHelp] = useState(false);

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        if (`${event.origin}/` !== publicConfig.wordpressURL) return;

        if (event.data && (event.data.eventtype === 11 || event.data.eventtype === 10)) {
          setShowContactForm(false);
        }
      },
      false,
    );
    //http://localhost:3000/view/60ba3e7d6885833094891ee0?data=1f124b47445018171b134b181f1d190550130e1e1f141f021f19184b4046141745134112404e4e434e4545464f424e4f47131346501113181213044b10131b171a1329475004191a134b05131a1a130450
    const params = new URL(window.location.href).searchParams;

    // Decipher data
    const data = params.get('data');
    const myDecipher = utils.cipher.decipher('5k09di4kisd94');

    if (data) {
      const dataArray = myDecipher(data).split('&');

      if (dataArray.length >= 5) {
        setFakeCurrentUser({
          _id: decodeURI(dataArray[0].split('=')[1]),
          exhibition: decodeURI(dataArray[2].split('=')[1]),
          profile: {
            name: decodeURI(dataArray[1].split('=')[1]).replaceAll('+', ' '),
            gender: decodeURI(dataArray[3].split('=')[1]),
            role: decodeURI(dataArray[4].split('=')[1]),
          },
        });
      }
    }

    return () => window.removeEventListener('message', () => Promise.resolve(false));
  }, []);

  useEffect(() => {
    if (!currentUser) setOpenFakeForm(true);
    else setOpenFakeForm(false);

    if (
      currentUser &&
      currentUser.profile.role === 'client' &&
      !(localStorage.getItem('dontShowHelp') === 'true')
    ) {
      setShowHelp(true);
    }
  }, [currentUser]);

  useEffect(() => {
    const sectorCategoryId = categories.find(category => category.title.toLowerCase() === 'sector')
      ?._id;

    if (linkId) {
      fetchLinkedExhibition(linkId)
        .then(({ response: responseExhibition }) => {
          setLinkedExhibitionTitle(responseExhibition.title);
          if (
            responseExhibition.categories &&
            responseExhibition.categories.length > 0 &&
            sectorCategoryId === responseExhibition.categories[0]
          )
            setIsSector(true);
          else setIsSector(false);

          setRedirectWarning(true);
        })
        .catch(() => setRedirectWarning(false));
    } else setRedirectWarning(false);
  }, [linkId]);

  useEffect(() => {
    setDeviceWarning(utils.navigator.isMobile() || utils.navigator.isIE());
  }, [utils.navigator.isMobile(), utils.navigator.isIE()]);

  useEffect(() => {
    document.title = `Evea | ${exhibition.title}`;
  }, [exhibition]);

  // An effect that monitor displayed artifact to see if it is a Youtube Link
  // If it is it will show an IFrame and will not open artifact overlay viewer
  useEffect(() => {
    if (displayedArtifactId) {
      const displayedArtifact =
        artifacts.find(artifact => artifact._id === displayedArtifactId) || {};

      if (displayedArtifact?.link?.length > 0) {
        setDisplayedArtifact(undefined);
      }
      if (
        displayedArtifact.files &&
        displayedArtifact.files.length > 0 &&
        displayedArtifact.files[0].uri
      ) {
        if (
          displayedArtifact.files[0].uri.includes('youtube.com') ||
          displayedArtifact.files[0].uri.includes('youtu.be')
        ) {
          setDisplayedArtifact(undefined);
          setIFrameLink(displayedArtifact.files[0].uri);
        }
      }
    }
  }, [displayedArtifactId]);

  return (
    <Fullscreen enabled={fullscreen} onChange={onFullscreenChange}>
      <div style={{ position: 'relative' }}>
        <OurModal
          width="25%"
          height="auto"
          maxHeight="500px"
          openModal={openFakeForm}
          setOpenModal={setOpenFakeForm}
          cannotClose={true}
        >
          <FakeForm
            onAddMessage={onAddMessage}
            setFakeCurrentUser={setFakeCurrentUser}
            setOpenFakeForm={setOpenFakeForm}
            onWPVisitor={onWPVisitor}
          />
        </OurModal>

        <OurModal
          width="50%"
          height="unset"
          maxHeight="700px"
          openModal={showHelp}
          setOpenModal={setShowHelp}
        >
          <ClientHelp />
        </OurModal>

        {/* Request chat */}
        <Dialog
          type="no"
          open={chatInfo && chatInfo.messageType === 'request' && chatInfo.showWarning}
          title={`Do you want to chat with ${chatInfo.name}`}
          message=""
          onConfirm={() => {
            requestChat(
              window.unity.instances['unity'].channels.socketio,
              exhibition._id,
              chatInfo,
              currentUser,
            );

            setChatInfo({ ...chatInfo, showWarning: false });
          }}
          onReject={() => {
            setChatInfo({ ...chatInfo, showWarning: false });
          }}
        />

        {/* Accept chat */}
        <Dialog
          type="no"
          open={chatInfo && chatInfo.messageType === 'accept' && chatInfo.showWarning}
          title="Chat request"
          message={`${chatInfo.name} wants to start a chat with you`}
          onConfirm={() => {
            setChatInfo({
              ...chatInfo,
              showWarning: false,
              showChat: true,
            });

            // Establishes chat
            setChat(
              window.unity.instances['unity'].channels.socketio,
              exhibition._id,
              chatInfo,
              currentUser,
              true,
            );
          }}
          onReject={() => {
            setChat(
              window.unity.instances['unity'].channels.socketio,
              exhibition._id,
              chatInfo,
              currentUser,
              false,
              'Chat request declined',
            );

            setChatInfo({
              ...chatInfo,
              showWarning: false,
              showChat: false,
            });
          }}
        />

        {/* Show message when other user closes chat */}
        <Dialog
          type="info"
          open={chatInfo && chatInfo.closeMessage}
          title={chatInfo.closeMessage}
          onClose={() => {
            setChatInfo({
              ...chatInfo,
              closeMessage: false,
            });
          }}
        />
        <Dialog
          type="no"
          open={redirectWarning}
          title={`${
            isSector
              ? `Welcome to ${linkedExhibitionTitle}`
              : `Welcome to ${linkedExhibitionTitle} booth`
          }`}
          message={`Would you like to enter?`}
          onConfirm={() => {
            setLinkId(undefined);
            onRedirect(`/view/${linkId}`);
          }}
          onReject={() => {
            setLinkId(undefined);
            setRedirectWarning(false);
          }}
        />

        {IFrameLink && (
          <IFrame
            exhibitionId={exhibition._id}
            showTopBar
            src={IFrameLink}
            width="70%"
            height="70%"
          />
        )}
        <DeviceWarning
          deviceWarning={deviceWarning}
          setDeviceWarning={setDeviceWarning}
          exhibitionId={exhibition._id}
        />
        <div className={`exhibition-view viewer ${embedded ? 'embedded' : ''}`}>
          {showContactForm && (
            <WPDiv animationName={showContactForm ? 'expand' : 'collapse'}>
              <WPFrame src={encodeURI(wordpressURL)} title="WordPress Site"></WPFrame>
            </WPDiv>
          )}
          {exhibition.categories &&
            exhibition.categories.length > 0 &&
            exhibition.categories[0] !==
              categories.find(category => category.title.toLowerCase() === 'sector')?._id && (
              <WPEmbed exhibitionId={exhibition._id} setChat={setChat} />
            )}
          {!openFakeForm && exhibition && exhibition._id && !webVR && !deviceWarning && (
            <UnityWrapper
              exhibitionId={exhibition._id}
              exhibitionTitle={exhibition.title}
              width={width}
              height={height}
              releaseWheel
            />
          )}
          {!openFakeForm && exhibition && exhibition._id && webVR && (
            <WebVRWrapper
              exhibitionId={exhibition._id}
              exhibitionTitle={exhibition.title}
              width={width}
              height={height}
              releaseWheel
            />
          )}
          <ExhibitionStoryPlayer
            exhibition={exhibition}
            storypoints={storypoints.filter(p =>
              (((exhibition || {}).model || {}).storyPoints || []).some(
                point => point.storyPoint === p._id,
              ),
            )}
          />
          {enabledAudio[1] ? (
            <div
              style={{
                position: 'absolute',
                top: '10px',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <VerticalItems
                style={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  width: '292px',
                  borderRadius: '7px',
                }}
              >
                <Text mycolor="white">{__('enable_audio')}</Text>
                <HorizontalItems>
                  <IconButton
                    onClick={() => {
                      enabledAudio[0].current.play();
                      onSetEnabledAudio(enabledAudio[0], false);
                    }}
                  >
                    <CheckIcon style={{ color: '#38e538' }} />
                  </IconButton>
                  <IconButton onClick={() => onSetEnabledAudio(enabledAudio[0], false)}>
                    <CloseIcon style={{ color: 'red' }} />
                  </IconButton>
                </HorizontalItems>
              </VerticalItems>
            </div>
          ) : (
            <></>
          )}
          <ExhibitionViewOptions exhibition={exhibition} paragraphs={tutorialParagraphs} />
          {!IFrameLink && (
            <ArtifactOverlay
              open={!!displayedArtifactId && !IFrameLink}
              position="left"
              clear
              onClose={onOverlayClose}
              transparent
              exhibitionId={exhibition._id}
            >
              <ArtifactViewer artifactId={displayedArtifactId} exhibitionId={exhibition._id} />
            </ArtifactOverlay>
          )}
        </div>
        <Dialog
          type="warning"
          open={agentWarning}
          title={__('agent_warning')}
          message={__('agent_warning_description', exhibition)}
          onClose={onCloseAgentWarning}
        >
          <BrowserIconsSection />
        </Dialog>
      </div>
    </Fullscreen>
  );
};

const createArtifactFilter = exhibitions => ({ filter: { exhibitions } });
const createStorypointFilter = exhibition => ({
  filter: { exhibition },
  include: 'description',
});

const mapState = (state, { exhibitionId, unsupportedDevice, unsupportedAgent }) => ({
  showContactForm: getUIProperty(state, 'showContactForm'),
  chatInfo: getUIProperty(state, 'chatInfo'),
  progress: getUIProperty(state, `exhibitions/${exhibitionId}/progress`),
  linkId: getUIProperty(state, 'linkId'),
  currentUser: getAuthUser(state) || getUIProperty(state, 'fakeCurrentUser'),
  openFakeForm: getUIProperty(state, 'openFakeForm'),
  IFrameLink: getUIProperty(state, `exhibitions/${exhibitionId}/IFrameLink`),
  exhibition: window.location.href.includes('currentUser')
    ? getApiResource(state, `exhibitionsUserProfile/${exhibitionId}`)
    : getApiResource(
        state,
        `exhibitions${
          !publicConfig.spaceNames.includes(window.location.hostname.split('.')[0]) ? 'Private' : ''
        }/${exhibitionId}`,
      ),
  artifacts: Object.values(getApiResource(state, 'artifacts', createArtifactFilter(exhibitionId))),
  displayedArtifactId: getUIProperty(state, `exhibitions/${exhibitionId}/displayedArtifact`),
  storypoints: Object.values(
    getApiResource(state, 'storypoints', createStorypointFilter(exhibitionId)),
  ).sort((pointA, pointB) => pointA.order - pointB.order),
  ready: true,
  exhibitionImported: !!getUIProperty(state, `exhibitions/${exhibitionId}/imported`),
  fullscreen: getUIProperty(state, `exhibitions/${exhibitionId}/fullscreen`),
  agentWarning:
    unsupportedAgent &&
    !unsupportedDevice &&
    !getUIProperty(state, `exhibitions/${exhibitionId}/agentWarningClosed`),
  deviceWarning:
    unsupportedDevice && !getUIProperty(state, `exhibitions/${exhibitionId}/deviceWarningClosed`),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  enabledAudio: getUIProperty(state, `exhibitions/${exhibitionId}/enabledAudio`),
  categories: Object.values(getApiResource(state, 'categories')),
});

const mapDispatch = (dispatch, { exhibitionId, isPrivateSpace }) => ({
  setShowContactForm: value => dispatch(setUIProperty('showContactForm', value)),
  onAddMessage: message => dispatch(addMessage(message)),
  onWPVisitor: visitor => dispatch(apiPOST('wp/visitor', visitor)),
  setChatInfo: chatInfo => dispatch(setUIProperty('chatInfo', chatInfo)),
  setLinkId: linkId => dispatch(setUIProperty(`linkId`, linkId)),
  setTravelInfos: travelInfos => dispatch(setUIProperty(`travelInfos`, travelInfos)),
  fetchLinkedExhibition: id => dispatch(apiGET(`exhibitions/${id}`)),
  setOpenFakeForm: value => dispatch(setUIProperty('openFakeForm', value)),
  setFakeCurrentUser: user => dispatch(setUIProperty('fakeCurrentUser', user, true)),
  onFetchExhibition: () => {
    let endpoint = isPrivateSpace ? 'exhibitionsPrivate' : 'exhibitions';
    if (window.location.href.includes('currentUser')) {
      endpoint = 'exhibitionsUserProfile';
    }
    return dispatch(
      apiGET(`${endpoint}/${exhibitionId}`, {
        include: ['model', '_v1Views', 'categories'],
        populate: ['user'],
      }),
    );
  },
  setIFrameLink: word => dispatch(setUIProperty(`exhibitions/${exhibitionId}/IFrameLink`, word)),
  onFetchTemplates: () => dispatch(apiGET('templates')),
  onFetchArtifacts: () => dispatch(apiGET('artifacts', createArtifactFilter(exhibitionId))),
  onFetchStorypoints: () => dispatch(apiGET('storypoints', createStorypointFilter(exhibitionId))),
  setDisplayedArtifact: artifactId =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayedArtifact`, artifactId)),
  onOverlayClose: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/displayedArtifact`, null)),
  onImportExhibition: ({ exhibition, artifacts, storypoints, templates }) =>
    dispatch(
      setUIProperty(
        `exhibitions/${exhibitionId}/import`,
        utils.exhibition.exportExhibition(exhibition, artifacts, storypoints, templates),
      ),
    ).then(() => dispatch(setUIProperty(`exhibitions/${exhibitionId}/currentStep`, STEP_VIEW))),
  onFullscreenChange: fullscreen =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/fullscreen`, fullscreen)),
  onCloseDeviceWarning: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/deviceWarningClosed`, true)),
  onCloseAgentWarning: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/agentWarningClosed`, true)),
  onRedirect: location => dispatch(setLocation(location)),
  onSetEnabledAudio: (ref, show) =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/enabledAudio`, [ref, show])),
});

const onInitialization = ({
  onFetchExhibition,
  onFetchArtifacts,
  onFetchStorypoints,
  onFetchTemplates,
  onImportExhibition,
  onRedirect,
  isPrivateSpace,
}) =>
  Promise.all([
    onFetchExhibition(),
    onFetchArtifacts(),
    onFetchStorypoints(),
    onFetchTemplates(),
  ]).then(
    ([
      { response: exhibition },
      { response: artifacts },
      { response: storypoints },
      { response: templates },
    ]) => {
      if (exhibition.error) return onRedirect(!isPrivateSpace ? '/explore' : '/');
      return onImportExhibition({
        exhibition,
        artifacts: Object.values(artifacts),
        storypoints: Object.values(storypoints),
        templates: Object.values(templates),
      });
    },
  );

const mapProps = () => ({
  tutorialParagraphs: [
    [
      { text: __('tutorials.mouse'), icon: moveSrc, class: 'movement' },
      { text: __('tutorials.keyboard'), icon: wasdSrc, class: 'keyboard' },
      { text: __('tutorials.interact'), icon: interactSrc, class: 'interact' },
    ],
    [
      { text: __('tutorials.map'), icon: mapSrc, class: 'map' },
      { text: __('tutorials.tour'), icon: tourSrc, class: 'tour' },
      { text: __('tutorials.chat'), icon: chatSrc, class: 'chat' },
    ],
  ],
});

const lifecycleMap = {
  onWillMount: props => onInitialization(props),
  onDidUpdate: ({ exhibitionId }, props) => {
    if (exhibitionId !== props.exhibitionId) {
      onInitialization(props);
    }
  },
};

const ExhibitionViewFrame = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withProps(mapProps),
  withLifecycle(lifecycleMap),
)(ExhibitionViewFrameView);

export default ExhibitionViewFrame;
