/* eslint-disable no-nested-ternary */
// Should not nest ternary operators
import { __ } from 'artsteps2-common';
import React, { useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import utils from '../../utils';
import CreateWithTemplatesNew from '../buttons/CreateWithTemplatesNew';
import * as header from '../../styles/styledComponents/header';
import * as generic from '../../styles/GenericStyled';
import { getUIProperty, getApiResource } from '../../reducers';
import { ReactComponent as LoginIcon } from '../../styles/images/loginIcon.svg';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { setUIProperty } from '../../actions';
import useWindowSize from '../../hooks/windowSize';
import colors from '../../styles/colors';

const DefaultButtonsView = ({
  currentUser,
  space,
  hasChanges,
  onClickedPathLink,
  onOpenDialog,
  contributionBannerInteraction = false,
  isPrivateSpace,
  onToggleCreatorView = () => Promise.resolve(false),
  onShowContributionModal = () => Promise.resolve(false),
  onToggleNavbar = () => Promise.resolve(false),
  onAllowToCreate = () => Promise.resolve(false),
  allowedToCreate = false,
  navbarView,
  handleMenu,
  setOpenLogin,
  setOpenRegister,
  fakeCurrentUser,
}) => {
  const size = useWindowSize();

  useEffect(() => {
    if (size.width > 960) onToggleNavbar(true);
    if (
      currentUser &&
      Object.keys(space).length !== 0 &&
      space.subdomain === 'artedu' &&
      currentUser._id !== '5f119df6b227b91744e7acdc'
    )
      onAllowToCreate(false);
    else onAllowToCreate(true);
  }, [size.width, currentUser]);

  return (
    <header.ToggledGrid navbarview={+navbarView}>
      {localStorage.getItem('isKlimentini') && (
        <>
          {size.width > 1280 && !currentUser && (
            <Box style={{ marginLeft: 'auto' }}>
              <generic.StyledButton
                mycolor="white"
                background={colors.ourBlue}
                type="secondary"
                onClick={() => setOpenRegister(true)}
              >
                <header.Span signin={`${!!currentUser}`}>{__('register')}</header.Span>
              </generic.StyledButton>
            </Box>
          )}
          {currentUser && (
            <Box style={{ marginLeft: '10px' }}>
              <generic.StyledButton
                href={'/curate/new/1'}
                mycolor="white"
                background="#343a40"
                type="secondary"
                onClick={e => {
                  if (hasChanges) {
                    e.preventDefault();
                    onClickedPathLink('/curate/new/1');
                    onOpenDialog();
                  } else {
                    onToggleCreatorView(true);
                  }
                }}
              >
                <AddIcon style={{ opacity: '0.5', fontSize: '1.9rem' }} />
                <header.Span signin={`${!!currentUser}`}>{__('create')}</header.Span>
              </generic.StyledButton>
            </Box>
          )}
        </>
      )}
      {!currentUser && !fakeCurrentUser && (
        <Box style={{ marginLeft: '10px' }}>
          <generic.StyledButton
            mycolor="white"
            background="#12AD8F"
            type="secondary"
            onClick={e => {
              if (!currentUser) setOpenLogin(true);
            }}
          >
            <header.Span signin={`${!!currentUser}`}>{__('login')}</header.Span>
            {size.width <= 1280 && (
              <LoginIcon style={{ fill: 'white', width: '30px', height: '30px' }} />
            )}
          </generic.StyledButton>
        </Box>
      )}
      {fakeCurrentUser && (
        <Box style={{ marginLeft: '10px' }}>
          <generic.StyledButton
            mycolor="white"
            background="#12AD8F"
            type="secondary"
            onClick={e => {
              localStorage.removeItem('ui');
              window.location.reload();
            }}
          >
            <header.Span signin={`${!!currentUser}`}>Logout</header.Span>
            {size.width <= 1280 && (
              <LoginIcon style={{ fill: 'white', width: '30px', height: '30px' }} />
            )}
          </generic.StyledButton>
        </Box>
      )}
      {currentUser && (
        <header.AccountButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={e => {
            handleMenu(e);
          }}
          color="inherit"
          logged_out={`${currentUser === '' || currentUser === undefined}`}
        >
          {currentUser ? (
            <header.AvatarIcon src={utils.user.getUserImage(currentUser)} />
          ) : (
            <header.AvatarIcon />
          )}
        </header.AccountButton>
      )}
    </header.ToggledGrid>
  );
};

const mapState = (state, { exhibitionId }) => ({
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  contributionBannerInteraction: getUIProperty(state, 'contributionBannerInteraction'),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  allowedToCreate: getUIProperty(state, 'allowToCreate'),
  hasChanges: getUIProperty(state, `exhibitions/${exhibitionId}/hasChanges`),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onOpenDialog: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/dialog`, true)),
  onClickedPathLink: link => dispatch(setUIProperty('activeLinkPath', link)),
  setOpenLogin: status => dispatch(setUIProperty('openLogin', status)),
  setOpenRegister: status => dispatch(setUIProperty('openRegister', status)),
  onToggleCreatorView: view => dispatch(setUIProperty('creatorView', view)),
  onToggleCuratorView: view => dispatch(setUIProperty('curatorView', view)),
  onToggleSwitchSubViewView: view => dispatch(setUIProperty('switchsubview', view)),
  onShowContributionModal: () => dispatch(setUIProperty('contributionModalOpen', true)),
  onToggleNavbar: view => dispatch(setUIProperty(`navbarView`, view)),
  onAllowToCreate: allow => dispatch(setUIProperty('allowToCreate', allow)),
});

const lifecycleMap = {};

const DefaultButtons = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(DefaultButtonsView);

export default DefaultButtons;
