import Grid from '@material-ui/core/Grid';

import React from 'react';
import { __ } from 'artsteps2-common';
import CircularProgress from '@material-ui/core/CircularProgress';
import Exhibition from './Exhibition';
import ExhibitionAdminControls from './ExhibitionAdminControls';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { API_STATUS, getApiResource, getApiStatus, getUIProperty } from '../../../reducers';
import { apiGET, apiDELETE } from '../../../actions';
import { HorizontalItems, Text, VerticalItems } from '../../../styles/GenericStyled';
import useWindowSize from '../../../hooks/windowSize';
import Loader from '../../generic/Loader';
import publicConfig from 'artsteps2-config/public.json';

export const ExhibitionListView = ({
  exhibitions = [{ _id: null }],
  ready = true,
  controls = false,
  usernames = false,
  currentUser,
  userId,
  categoryList,
  sortedReports,
  isAdmin,
  viewPage,
}) => {
  const size = useWindowSize();
  if (ready && !exhibitions.length) {
    return (
      <div className="ui main segment basic exhibition-list-loader">
        <div className="emtpy-list">{__('no_exhibitions_found')}</div>
      </div>
    );
  }

  // Making an object array with key the category id and value the category title
  // in order to find the category title by id
  const categoryListMapped = Object.values(categoryList).map(value => ({
    [value._id]: value.title,
  }));

  return (
    <>
      {ready ? (
        <Grid
          container
          justify="center"
          spacing={viewPage && size.width >= 1280 ? 0 : 8}
          style={{
            paddingTop: viewPage && size.width >= 1280 ? '32px' : '48px',
          }}
        >
          {exhibitions.map(
            exhibition =>
              exhibition._id && (
                <Grid
                  xs={viewPage && size.width >= 1280 ? 12 : false}
                  item
                  key={exhibition._id}
                  style={{
                    paddingBottom: viewPage ? '16px' : '32px',
                    paddingTop: 0,
                  }}
                >
                  <VerticalItems>
                    <Exhibition
                      ready={ready}
                      categoryListMapped={categoryListMapped}
                      userId={userId}
                      exhibition={exhibition}
                      controls={controls}
                      usernames={usernames}
                      fetchImageFromExhibitionProfileUser={
                        currentUser && userId && currentUser._id === userId
                      }
                      viewPage={viewPage}
                    />
                    {isAdmin && sortedReports && sortedReports[exhibition._id] && (
                      <ExhibitionAdminControls
                        sortedReports={sortedReports}
                        exhibitionId={exhibition._id}
                        userId={exhibition.user._id}
                      />
                    )}
                  </VerticalItems>
                </Grid>
              ),
          )}
        </Grid>
      ) : (
        <Loader />
      )}
    </>
  );
};
const createQuery = ({ filter, limit, offset, sort, include, populate }) => ({
  filter,
  sort,
  page: { limit, offset },
  include,
  populate,
});
const mapState = (state, props) => {
  const endpoint =
    props.currentUser && props.userId && props.currentUser._id === props.userId
      ? 'exhibitionsUserProfile'
      : 'exhibitions';

  const categories = Object.values(getApiResource(state, 'categories'));

  const sectorCategoryId = categories.find(category => category.title.toLowerCase() === 'sector')
    ?._id;
  return {
    categories,
    sortedReports: getUIProperty(state, 'sortedReports'),
    exhibitions: Object.values(getApiResource(state, endpoint, createQuery(props))).filter(
      exhibition =>
        exhibition._id !== window.location.href.split('/')[4] &&
        exhibition?.categories[0] !== sectorCategoryId &&
        exhibition.staffPick !== true,
    ),
    categoryList: getApiResource(state, `categories`),
    ready: getApiStatus(state, endpoint, createQuery(props)) === API_STATUS.IDLE,
  };
};
const mapDispatch = (dispatch, props) => {
  const endpoint =
    props.currentUser && props.userId && props.currentUser._id === props.userId
      ? 'exhibitionsUserProfile'
      : 'exhibitions';
  return {
    onFetchReports: () => dispatch(apiGET('reports')),
    onFetchExhibitions: () => dispatch(apiGET(endpoint, createQuery(props))),
    onDeleteExhibition: exhibitionId =>
      dispatch(apiDELETE(`exhibitionsUserProfile/${exhibitionId}`)),
    onDeleteReports: exhibitionId => dispatch(apiDELETE(`reports/${exhibitionId}`)),
  };
};

const onInitialization = ({ onFetchExhibitions, onFetchReports }) => {
  onFetchExhibitions();
};

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: (prevProps, props) =>
    JSON.stringify(createQuery(prevProps)) !== JSON.stringify(createQuery(props)) &&
    onInitialization(props),
};
const ExhibitionList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionListView);
export default ExhibitionList;
