import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import { compose, withState, withDispatch, withLifecycle } from '../../../../../enhancers';
import { apiGET, setUIProperty } from '../../../../../actions';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getAuthUser,
  getUIProperty,
  getFormProperty,
} from '../../../../../reducers';
import Theme from './Theme';
import { ToolbarSection } from '../../ExhibitionEditStyled';
import { Text } from '../../../../../styles/GenericStyled';
import { HorizontalItems } from '../../../../../styles/GenericStyled';
import { InvisibleButton } from '../../../lists/ExhibitionStyled';

export const ExhibitionThemePanelView = ({ exhibitionId, themes = [] }) => {
const [show, setShow] = useState(true);
  return (<ToolbarSection>
    <HorizontalItems>
      <InvisibleButton onClick={() => setShow(show => !show)}>
        {show ? <ArrowDropUpIcon fontSize="large" /> : <ArrowDropDownIcon fontSize="large" />}
      </InvisibleButton>
      <Text type="h7">
        {__('themes')} ({themes.length})
      </Text>

    </HorizontalItems>

    {show && themes.map((theme, idx) => (
      <Theme
        key={`theme-${idx}`}
        exhibitionId={exhibitionId}
        theme={theme.pallete}
        title={theme.title}
      />
    ))}
  </ToolbarSection>
); };

const createQuery = () => ({});

const mapState = (state, { exhibitionId }) => {
  const currentUser = getAuthUser(state);
  return {
    themes: Object.values(getApiResource(state, 'themes')),
    currentUser,
    painting: getUIProperty(state, `exhibitions/${exhibitionId}/painting`),
    placingTexture: !!getUIProperty(state, `exhibitions/${exhibitionId}/placingTexture`),
    colour: getUIProperty(state, `exhibitions/${exhibitionId}/paintingColour`),
    textures: Object.values(getApiResource(state, 'textures', createQuery(currentUser))),
    ready: getApiStatus(state, 'textures', createQuery(currentUser)) === API_STATUS.IDLE,
    searchTerm: getFormProperty(state, 'textures', 'search'),
  };
};

const mapDispatch = (dispatch, { exhibitionId, painting, currentUser }) => ({
  fetchThemes: () => dispatch(apiGET('themes')),
});

const lifecycleMap = {
  onDidMount: ({ fetchThemes }) => fetchThemes(),
};

const ExhibitionThemePanel = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionThemePanelView);

export default ExhibitionThemePanel;
