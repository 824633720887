import React, { useState, useEffect } from 'react';
import ContributeBanner from './ContributeBanner';
import { withState, compose } from '../../enhancers';
import { getUIProperty, getAuthUser } from '../../reducers';
import { getCookie } from '../../utils/cookies';

const BannerRendererRaw = ({
  isPrivateSpace,
  contributionBannerInteraction = false,
  currentUser,
}) => {
  const [open, setOpen] = useState(true);
  // console.log(open, isPrivateSpace, contributionBannerInteraction, currentUser);
  const hide = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const cookieScriptConsent = document.cookie
      .split(';')
      .filter(c => c.includes('CookieScriptConsent'));
    const visits = parseInt(getCookie('numberOfVisits'), 10);
    if (!currentUser && (!cookieScriptConsent[0] || contributionBannerInteraction)) setOpen(false);
    if (currentUser && (contributionBannerInteraction || visits % 10 !== 1)) {
      setOpen(false);
    }
  }, []);

  return (
    <div className="cookies-wrapper">{false && <ContributeBanner open={open} hide={hide} />}</div>
  );
};

const mapState = state => ({
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  contributionBannerInteraction: getUIProperty(state, 'contributionBannerInteraction'),
  currentUser: getAuthUser(state),
});

const BannerRenderer = compose(withState(mapState))(BannerRendererRaw);
export default BannerRenderer;
