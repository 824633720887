import React from 'react';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { PaperModal, StyledButton } from '../../styles/GenericStyled';

const StyledBackdrop = withStyles({
  root: {
    backdropFilter: 'blur(7px) !important',
  },
})(Backdrop);

const OurModal = ({
  children,
  openModal = false,
  setOpenModal,
  width,
  height,
  minWidth,
  minHeight,
  maxHeight,
  maxWidth,
  cannotClose,
}) => {
  return (
    <Modal
      disableBackdropClick
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      BackdropComponent={StyledBackdrop}
    >
      <PaperModal
        width={width}
        height={height}
        minWidth={minWidth}
        minHeight={minHeight}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        elevation={8}
      >
        {!cannotClose && (
          <StyledButton
            type="ghost"
            style={{
              fontSize: '1.5rem',
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: '1',
            }}
            background="black"
            mycolor="white"
            onClick={() => setOpenModal(false)}
          >
            <CloseIcon />
          </StyledButton>
        )}
        {children}
      </PaperModal>
    </Modal>
  );
};

export default OurModal;
