import Paper from '@material-ui/core/Paper';
import React from 'react';
import { __ } from 'artsteps2-common';
import config from 'artsteps2-config/public.json';
import styled from 'styled-components';
import { Text, StyledButton } from '../../../styles/GenericStyled';
import WarningIcon from '@material-ui/icons/Warning';

const StyledPaper = styled(Paper)`
  overflow: hidden !important;
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  bottom: 0;
  align-items: center;
  flex-direction: column;
  max-height: 100vh;
`;

const StyledDiv = styled.div`
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const openInApp = exhibitionId => {
  setTimeout(() => {
    window.location = config.applications.playURI;
  }, 250);
  window.location = `artsteps://view/${exhibitionId}`;
};
const DeviceWarning = ({ open, exhibitionId, deviceWarning, setDeviceWarning }) => {
  return (
    deviceWarning && (
      <StyledPaper>
        <StyledDiv>
          <WarningIcon style={{ fontSize: 40 }} />
          <Text align="center" type="h6">
            {__('device_warning')}
          </Text>
          <Text style={{ marginTop: '20px' }} align="center">
            {__('device_warning_description')}
          </Text>
        </StyledDiv>
        <div
          style={{
            paddingBottom: '10px',
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <StyledButton
            onClick={() => openInApp(exhibitionId)}
            background="#343a40"
            mycolor="white"
            type="secondary"
          >
            Open in App
          </StyledButton>
          <StyledButton
            onClick={() => setDeviceWarning(false)}
            background="#12ad8f"
            mycolor="white"
            type="secondary"
          >
            Continue
          </StyledButton>
        </div>
      </StyledPaper>
    )
  );
};

export default DeviceWarning;
