import Grid from '@material-ui/core/Grid';

import React from 'react';
import { __ } from 'artsteps2-common';
import CircularProgress from '@material-ui/core/CircularProgress';
import Exhibition from './Exhibition';
import ExhibitionAdminControls from './ExhibitionAdminControls';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { API_STATUS, getApiResource, getApiStatus, getUIProperty } from '../../../reducers';
import { apiGET, apiDELETE } from '../../../actions';
import {
  BodyWithPadding,
  HorizontalItems,
  Text,
  VerticalItems,
} from '../../../styles/GenericStyled';
import useWindowSize from '../../../hooks/windowSize';
import Loader from '../../generic/Loader';
import publicConfig from 'artsteps2-config/public.json';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import COLORS from '../../../styles/colors';

export const ExhibitionListView = ({
  exhibitions = [{ _id: null }],
  ready = true,
  controls = false,
  usernames = false,
  currentUser,
  userId,
  categories = [],
  sortedReports,
  isAdmin,
  viewPage,
}) => {
  const size = useWindowSize();

  // Making an object array with key the category id and value the category title
  // in order to find the category title by id
  let groups = {};

  categories.forEach(category => {
    groups[category.title] = [];
  });

  exhibitions.forEach(exhibition => {
    if (exhibition.categories && exhibition.categories.length > 0) {
      categories.forEach((category, idx) => {
        if (exhibition.categories.includes(category._id)) {
          groups[category.title].push(
            <li key={`${exhibition._id}${idx}`}>
              <HorizontalItems>
                <Link style={{ color: COLORS.ourBlue }} to={`/view/${exhibition._id}`}>
                  <Text type="body1">{exhibition.title} </Text>
                </Link>
              </HorizontalItems>
            </li>,
          );
        }
      });
    }
  });

  return (
    <BodyWithPadding padding="5%">
      <Grid container spacing={10} direction="row" justify="center" alignItems="flex-start">
        {categories.map(
          (category, idx) =>
            groups[category.title].length > 0 && (
              <Grid
                key={idx}
                item
                xs={4}
                container
                direction="column"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Text type="h5">{category.title}</Text>
                <ul>{groups[category.title]}</ul>
              </Grid>
            ),
        )}
      </Grid>
    </BodyWithPadding>
  );
};

const createQuery = ({ filter, limit, offset, sort, include, populate }) => ({
  filter,
  sort,
  page: { limit, offset },
  include,
  populate,
});
const mapState = (state, props) => {
  const categories = Object.values(getApiResource(state, 'categories'));

  const sectorCategoryId = categories.find(category => category.title.toLowerCase() === 'sector')
    ?._id;

  return {
    categories: categories.filter(category => category._id !== sectorCategoryId),
    sortedReports: getUIProperty(state, 'sortedReports'),
    exhibitions: Object.values(getApiResource(state, 'exhibitions', createQuery(props))).filter(
      exhibition =>
        exhibition._id !== window.location.href.split('/')[4] &&
        exhibition?.categories[0] !== sectorCategoryId &&
        exhibition.staffPick !== true,
    ),
    ready: getApiStatus(state, 'exhibitions', createQuery(props)) === API_STATUS.IDLE,
  };
};
const mapDispatch = (dispatch, props) => ({
  onFetchExhibitions: () => dispatch(apiGET('exhibitions', createQuery(props))),
});

const onInitialization = ({ onFetchExhibitions }) => {
  onFetchExhibitions();
};

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
};
const ExhibitionList = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionListView);
export default ExhibitionList;
