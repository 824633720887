import React from 'react';
import { __ } from 'artsteps2-common';
import { VerticalItems, Text, StyledButton, BodyWithPadding } from '../styles/GenericStyled';

const Paypal = () => {
  return (
    <BodyWithPadding padding="30px">
      <VerticalItems>
        <Text style={{ marginBottom: '15px' }} type="h6">
          {__('donate_amount')}
        </Text>
        <Text style={{ marginBottom: '15px' }} type="body1">
          {__('donate_text')}
        </Text>
        <img
          src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg"
          alt="PayPal Logo"
          style={{ marginBottom: '20px' }}
        />
        <StyledButton
          onClick={() => {
            window.location =
              'https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=7N3GKFABLACGQ&source=url';
          }}
          type="secondary"
          background="#EDC62C"
          mycolor="#343A40"
        >
          {__('donate_button')}
        </StyledButton>
      </VerticalItems>
    </BodyWithPadding>
  );
};

export default Paypal;
