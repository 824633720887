import React, { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import logo from '../styles/images/logo.png';
import { ReactComponent as Send } from '../styles/images/send-24px.svg';
import { apiPOST, addMessage, setUIProperty } from '../actions';
import { compose, withState, withDispatch, withLifecycle } from '../enhancers';
import { getAuthUser, getUIProperty } from '../reducers';
import CustomCarousel from '../components/privateSpaces/CustomCarousel';
import CustomCarouselMobile from '../components/privateSpaces/CustomCarouselMobile';
import * as PrivateComponent from '../styles/PrivateSpacesPageStyled';
import DefaultLayout from '../components/layouts/DefaultLayout';
import useWindowSize from '../hooks/windowSize';
import PSpaper from '../components/privateSpaces/PSpaper';
import PSpaperMobile from '../components/privateSpaces/PSpaperMobile';
import PSbanner from '../components/privateSpaces/PSbanner';
import blueMan from '../styles/images/blueMan.png';
import { HorizontalItems, Text, VerticalItems } from '../styles/GenericStyled';
import videoPreview1 from '../styles/images/privateSpaces/videoPreview1.mp4';
import videoPreview2 from '../styles/images/privateSpaces/videoPreview2.mp4';
import videoPreview3 from '../styles/images/privateSpaces/videoPreview3.mp4';
import videoPreview4 from '../styles/images/privateSpaces/videoPreview4.mp4';
import colors from '../styles/colors';

const { getNames } = require('country-list');

const media = [
  { src: videoPreview1, type: 'video' },
  { src: videoPreview2, type: 'video' },
  { src: videoPreview3, type: 'video' },
  { src: videoPreview4, type: 'video' },
];

const bannerText = [
  'Private Spaces',
  'Do you work for an educational institute, a gallery or a brand?',
  'Now you have the tools to create 3D virtual experiences tailored to your needs.',
];

const infos = [
  'Secure 3D virtual space under a unique domain name e.g. myownspace.artsteps.com',
  'User access control for both curators and visitors',
  'Personalised curating panel with your own branding',
  'One-click intergration in artsteps platform.',
  'Priority support',
];

const breakPoint = 1515;

const SectionTitle = ({ text }) => {
  const size = useWindowSize();
  return (
    <div style={{ marginBottom: size.width <= 1200 ? '0px' : '50px' }}>
      <Text
        type={size.width < 600 ? 'h6' : 'h4'}
        align="center"
        style={{ marginBottom: '5px' }}
        title="true"
      >
        {text}
      </Text>
      <hr style={{ margin: '0px' }} />
    </div>
  );
};

const isDisabled = state => {
  let disabled = false;
  for (const [key, value] of Object.entries(state)) {
    if (key !== 'company' && value === '') disabled = true;
  }
  return disabled;
};

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 5px;
  display: none;
  ${props =>
    props.visible &&
    css`
      display: flex;
    `}
`;

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '300px',
    width: '357px',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

const initialState = {
  username: '',
  email: '',
  country: '',
  company: '',
  licence: '',
};

const licences = ['Commercial Licence', 'Academic Licence', 'Other/Not sure'];

function PrivateSpacesPageView(props) {
  const [icon, setIcon] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [inputData, setInputData] = useState({ ...initialState });
  const [countryInput, setCountryInput] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    document.title = `Evea | Private Spaces`;
  }, []);

  const size = useWindowSize();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputData({ ...initialState });
  };

  const handleSubmit = e => {
    props.onCreateContactForm({ ...inputData }).then(({ response }) => {
      if (!response.error) {
        props.onAddMessage({ title: 'Your message has been sent', type: 'success' });
      } else {
        props.onAddMessage({ title: response.error, type: 'error' });
      }
    });
    handleClose();
  };

  const handleChange = (event, option) => {
    let name;
    let value;
    if (option === null) {
      value = '';
      name = 'country';
    } else if (!option) {
      name = event.target.name ? event.target.name : event.target.getAttribute('name');
      value = event.target.value !== 0 ? event.target.value : event.target.getAttribute('value');
    } else {
      value = option;
      name = 'country';
    }
    inputData[name] = value;
    setInputData({ ...inputData });
  };

  const PaperContainers = [
    {
      text: 'Universities & Schools',
      description:
        'Create 3D private education classrooms for your students and explore a new educational experience',
      source: blueMan,
      background: '#E7ECF7',
      id: '0',
    },
    {
      text: 'Museums & Galleries',
      description:
        'Provide your audience with an immersive experience and virtual guided tours designed specially for your needs.',
      source: blueMan,
      background: '#E4DEE8',
      id: '1',
    },
    {
      text: 'Brand & Businesses',
      description: 'Expand your marketing campaigns and events with unique virtual experiences.',
      source: blueMan,
      background: '#C7EBE4',
      id: '2',
    },
  ].map(obj =>
    size.width < 1200 ? (
      <PSpaperMobile
        id={obj.id}
        handleOpen={handleOpen}
        subtext={obj.subtext}
        text={obj.text}
        key={obj.text}
        description={obj.description}
        source={obj.source}
        background={obj.background}
      />
    ) : (
      <PSpaper
        id={obj.id}
        handleOpen={handleOpen}
        subtext={obj.subtext}
        text={obj.text}
        key={obj.text}
        description={obj.description}
        source={obj.source}
      />
    ),
  );

  return (
    <DefaultLayout>
      <PSbanner title={bannerText[0]} sub1={bannerText[1]} sub2={bannerText[2]} />
      <PrivateComponent.Section id="scroll-p" mobile={size.width < breakPoint}>
        <SectionTitle text="Key Features" />
        {size.width < breakPoint ? (
          <CustomCarouselMobile media={media} infos={infos} />
        ) : (
          <CustomCarousel infos={infos} />
        )}
      </PrivateComponent.Section>

      <PrivateComponent.Section mobile={size.width < 1200}>
        <SectionTitle text="Designed For" />
        <VerticalItems>
          <HorizontalItems style={{ flexDirection: size.width < 1200 ? 'column' : 'row' }}>
            {PaperContainers}
          </HorizontalItems>
        </VerticalItems>
      </PrivateComponent.Section>

      <PrivateComponent.Requestgrid
        container
        justify="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <PrivateComponent.CustomTypography variant="h4" header="true">
            Interested in having a{' '}
            <PrivateComponent.CustomTypography variant="inherit" span="true">
              {size.width <= 666 && size.width >= 444 ? <br /> : <span />}
              Private Space?
            </PrivateComponent.CustomTypography>
          </PrivateComponent.CustomTypography>
        </Grid>
        <Grid item>
          <PrivateComponent.CustomButton
            onClick={handleOpen}
            onMouseEnter={() => setIcon(true)}
            onMouseLeave={() => setIcon(false)}
            request="true"
          >
            Contact us
            <PrivateComponent.PlacedArrowIcon open={icon} />
          </PrivateComponent.CustomButton>
        </Grid>
      </PrivateComponent.Requestgrid>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        style={{ overflow: 'auto' }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PrivateComponent.ModalGrid container direction="column" justify="center" spacing={2}>
            <Box component={Grid} item textAlign="center">
              <img alt="" src={logo} width="177px" height="59px" />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.CustomTypography variant="h4" modalhead="true">
                Request Details
              </PrivateComponent.CustomTypography>
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="username-input">
                Name
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="username-input"
                onChange={e => handleChange(e)}
                name="username"
                value={inputData.username}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="email-input">
                Email
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="email-input"
                onChange={handleChange}
                name="email"
                value={inputData.email}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="country-input">
                Country
              </PrivateComponent.InputsLabel>
              <PrivateComponent.CustomAutocomplete
                value={inputData.country}
                onChange={(e, value) => handleChange(e, value)}
                onInputChange={(event, newInputValue) => {
                  setCountryInput(newInputValue);
                }}
                inputValue={countryInput}
                autoComplete
                options={getNames()}
                clearOnEscape
                getOptionSelected={option => option}
                getOptionLabel={option => option}
                renderOption={option => <React.Fragment>{option}</React.Fragment>}
                renderInput={params => (
                  <PrivateComponent.CustomTextField
                    {...params}
                    placeholder="Select"
                    style={{ border: 0 }}
                    required
                  />
                )}
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="company-input">
                <PrivateComponent.CustomTypography company="true">
                  Company
                </PrivateComponent.CustomTypography>
                <PrivateComponent.CustomTypography optional="true">
                  (Optional)
                </PrivateComponent.CustomTypography>
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="company-input"
                onChange={handleChange}
                name="company"
                value={inputData.company}
              />
            </Box>
            <Box component={Grid} item textAlign="center" style={{ position: 'relative' }}>
              <PrivateComponent.InputsLabel htmlFor="licence-input">
                Licence
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="licence-input"
                aria-controls="licence-input"
                aria-haspopup="true"
                name="licence"
                value={inputData.licence}
                placeholder="Select"
                readOnly
                onClick={handleMenu}
                required
              />
              {openMenu ? <PrivateComponent.UpArrow /> : <PrivateComponent.DownArrow />}
              <StyledMenu
                id="licence-input"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                open={openMenu}
                onClose={handleCloseMenu}
              >
                {licences.map((licence, index) => (
                  <PrivateComponent.DropDownOptions
                    key={`space${index}`}
                    value={licence}
                    name="licence"
                    onClick={e => {
                      handleChange(e);
                      handleCloseMenu();
                    }}
                  >
                    {licence}
                  </PrivateComponent.DropDownOptions>
                ))}
              </StyledMenu>
            </Box>
            <Box component={CloseButton} onClick={handleClose} visible={size.width < breakPoint}>
              <CloseIcon />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.SendButton disabled={isDisabled(inputData)} onClick={handleSubmit}>
                Send your Request
                <SvgIcon>
                  <Send />
                </SvgIcon>
              </PrivateComponent.SendButton>
            </Box>
          </PrivateComponent.ModalGrid>
        </Fade>
      </Modal>
    </DefaultLayout>
  );
}

const lifecycleMap = {
  onDidMount: () => {
    ReactPixel.track('ViewContent');
  },
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  requestOpen: getUIProperty('requestOpen'),
});
const mapDispatch = dispatch => ({
  onCreateContactForm: form => dispatch(apiPOST('privatespaces', form)),
  onAddMessage: message => dispatch(addMessage(message, 'privatespaces')),
  setOpenRequest: status => dispatch(setUIProperty('requestOpen', status)),
});

const PrivateSpacesPage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(PrivateSpacesPageView);

export default PrivateSpacesPage;
