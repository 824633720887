import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import PaymentIcon from '@material-ui/icons/Payment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import i18nextInstance from 'artsteps2-common';
import UserDropDown from './UserDropDown';
import { getAuthUser, getLocationQuery } from '../../reducers';
import { apiGET } from '../../actions';
import utils from '../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import SearchWrapper from './SearchBar';
import { ReactComponent as LoginIcon } from '../../styles/images/loginIcon.svg';
import * as header from '../../styles/styledComponents/header';
import { __ } from 'artsteps2-common';

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    top: '58px!important',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '200px',
    maxWidth: '100%',
    width: '100%',
    left: '0!important',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

const loggedinOptions = (currentUser, action) => [
  {
    path: `/profile/${currentUser._id}`,
    name: __('profile'),
    action: action[0],
    icon: <AccountCircleIcon />,
  },
  {
    path: `/contact`,
    name: __('support'),
    action: action[0],
    icon: <ContactSupportIcon />,
  },
  {
    name: `Lang: ${i18nextInstance.language.toLocaleUpperCase()}`,
    action: () => {
      action[0]();
      action[1]();
    },
    icon: <LanguageIcon />,
  },
  {
    path: `/logout`,
    name: 'Sign Out',
    action: action[0],
    icon: <LoginIcon />,
  },
];

const loggedOutOptions = action => [
  {
    path: `/contact`,
    name: 'Support',
    action: action[0],
    icon: <ContactSupportIcon />,
  },
  {
    name: 'Sign In',
    action: () => {
      action[0]();
      action[3]();
    },
    icon: <LoginIcon />,
  },
];

export const MobileHeaderView = ({ currentUser, query = {} }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SearchWrapper query={query} searchPage="/explore" />
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <UserDropDown
          loggedinOptions={loggedinOptions}
          loggedOutOptions={loggedOutOptions}
          handleClose={handleClose}
          currentUser={currentUser}
        />
      </StyledMenu>
      <header.AccountButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={e => {
          handleMenu(e);
        }}
        color="inherit"
        logged_out={`${currentUser !== '' || currentUser !== undefined}`}
      >
        {currentUser &&
          (currentUser.profile.image !== undefined &&
          Object.keys(currentUser.profile.image).length !== 0 ? (
            <header.AvatarIcon
              src={utils.user.getUserImage({ _id: currentUser._id, profile: currentUser.profile })}
            />
          ) : (
            <header.AvatarIcon />
          ))}
        {!currentUser && <header.AvatarIcon />}
      </header.AccountButton>
    </>
  );
};
const mapState = state => ({
  currentUser: getAuthUser(state),
  query: getLocationQuery(state),
});
const lifecycleMap = {
  onDidMount: ({ onFetchUser, currentUser }) => {
    if (currentUser) onFetchUser({ currentUser });
  },
};

const mapDispatch = dispatch => ({
  onFetchUser: ({ currentUser }) => dispatch(apiGET(`users/${currentUser._id}`)),
});
const MobileHeader = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(MobileHeaderView);

export default MobileHeader;
