import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

import Overlay from '../../../generic/Overlay';
import ArtifactUploader from '../../../artifacts/ArtifactUploader';
import ArtifactList from '../../../artifacts/lists/ArtifactList';
import ArtifactEditor from '../../../artifacts/ArtifactEditor';

import utils from '../../../../utils';
import { compose, withState, withDispatch, withLifecycle } from '../../../../enhancers';
import {
  API_STATUS,
  getApiResource,
  getApiStatus,
  getUIProperty,
  getAuthUser,
  getFormProperty,
} from '../../../../reducers';
import { apiGET, setFormProperty, setUIProperty, setUIData } from '../../../../actions';

import case1Image from '../../../../styles/images/cases/case1.jpg';
import case2Image from '../../../../styles/images/cases/case2.jpg';
import case3Image from '../../../../styles/images/cases/case3.jpg';
import case4Image from '../../../../styles/images/cases/case4.jpg';
import case5Image from '../../../../styles/images/cases/case5.jpg';
import {
  HorizontalItems,
  StyledTextField,
  StyledTheme,
  Text,
} from '../../../../styles/GenericStyled';
import { StyledFolderIcon, StyledListIcon } from '../../../artifacts/lists/ArtifactsStyled';
import MyToolTip from '../../../generic/MyToolTip';
import {
  ArtifactBadge,
  ArtifactTab,
  ArtifactTabs,
  ImageButton,
  ItemList,
  ToolbarSection,
} from '../ExhibitionEditStyled';
import COLORS from '../../../../styles/colors';

const ARTIFACT_TYPES = ['image', 'video', 'object', 'text'];
const DISPLAY_CASES = [
  { type: 1, image: case1Image },
  { type: 2, image: case2Image },
  { type: 3, image: case3Image },
  { type: 4, image: case4Image },
  { type: 5, image: case5Image },
];

export const ExhibitionArtifactPanelView = ({
  showInFolders = false,
  setShowInFolders,
  artifacts = [],
  currentTab = ARTIFACT_TYPES[0],
  ready = true,
  searchTerm = '',
  exhibitionId,
  placingCase,
  selectedCase,
  placingArtifact,
  selectedArtifact,
  editingArtifactId,
  editingArtifactType,
  onSearch = () => Promise.resolve(false),
  onPlaceDisplayCase = () => Promise.resolve(false),
  onStopPlacing = () => Promise.resolve(false),
  onTabChange = () => Promise.resolve(false),
  onEditorClose = () => Promise.resolve(false),
  onUpdateArtifact = () => Promise.resolve(false),
}) => {
  const [tabValue, setTabValue] = useState(0);

  const renderCase = ({ type, image }) => (
    <ImageButton
      key={type}
      style={{ backgroundImage: `url(${image})` }}
      draggable={!placingArtifact && !placingCase}
      onDragEnd={({ dataTransfer: { dropEffect = 'none' } = {} }) =>
        onStopPlacing().then(
          () => dropEffect !== 'none' && placingCase !== type && onPlaceDisplayCase(type),
        )
      }
      inactive={+(placingArtifact || (placingCase && placingCase !== type)) || +false}
      selected={+(placingCase === type)}
      onKeyPress={event =>
        event.keyCode === 13 && (placingCase === type ? onStopPlacing() : onPlaceDisplayCase(type))
      }
      onClick={() => (placingCase === type ? onStopPlacing() : onPlaceDisplayCase(type))}
    />
  );

  const getArtifacts = type => {
    const searchText = searchTerm.toLowerCase();
    return artifacts.filter(
      a =>
        (!type || a.type === type) &&
        (!searchText ||
          a.title.toLowerCase().indexOf(searchText) > -1 ||
          (a.caption && a.caption.toLowerCase().indexOf(searchText) > -1) ||
          (a.information && a.information.toLowerCase().indexOf(searchText) > -1) ||
          (Array.isArray(a.files) &&
            a.files.some(
              b =>
                (b.name && b.name.toLowerCase().indexOf(searchText) > -1) ||
                (b.meta && b.meta.text && b.meta.text.toLowerCase().indexOf(searchText) > -1),
            )) ||
          (Array.isArray(a.covers) &&
            a.covers.some(c => c.name && c.name.toLowerCase().indexOf(searchText) > -1)) ||
          (a.audio &&
            a.audio.bin &&
            a.audio.bin.name &&
            a.audio.bin.name.toLowerCase().indexOf(searchText) > -1) ||
          (a.audio && a.audio.uri && a.audio.uri.toLowerCase().indexOf(searchText) > -1)),
    );
  };

  const TabPanel = ({ value, index, children }) => {
    return value === index && <div>{children}</div>;
  };

  const renderArtifacts = (tabValue, setTabValue) => {
    return (
      <>
        <ArtifactTabs
          style={{ width: '109%', marginLeft: '-7%' }}
          value={tabValue}
          variant="scrollable"
          scrollButtons="on"
          onChange={(event, newValue) => setTabValue(newValue)}
        >
          {ARTIFACT_TYPES.map((type, idx) => (
            <ArtifactTab
              key={type}
              label={
                <ArtifactBadge
                  showZero
                  mycolor={tabValue === idx ? COLORS.ourGreen : 'gray'}
                  badgeContent={getArtifacts(type).length}
                >
                  {__(`${type}s`)}
                </ArtifactBadge>
              }
            />
          ))}
        </ArtifactTabs>
        {ARTIFACT_TYPES.map((type, idx) => {
          const filteredArtifacts = getArtifacts(type);
          return (
            <TabPanel key={idx} value={tabValue} index={idx}>
              <ArtifactUploader type={type}>
                {artifacts.length === 0 && <div className="help-text">{__('help_artifacts')}</div>}
                {artifacts.length > 0 && filteredArtifacts.length === 0 && (
                  <div className="help-text">{__(`no_artifact_${type}s_found`)}</div>
                )}
              </ArtifactUploader>
              <ArtifactList
                showInFolders={showInFolders}
                artifacts={filteredArtifacts}
                controls
                ready={ready}
                exhibitionId={exhibitionId}
              />
            </TabPanel>
          );
        })}
        <Overlay fixed open={!!editingArtifactId} onClose={onEditorClose}>
          <ArtifactEditor
            onClose={onEditorClose}
            onSave={artifact => onUpdateArtifact(artifact).then(() => onEditorClose())}
            exhibitionId={exhibitionId}
            artifactId={editingArtifactId}
            type={editingArtifactType}
          />
        </Overlay>
      </>
    );
  };

  return (
    <>
      <ToolbarSection>
        <Text type="h7">
          {__('display_cases')} ({DISPLAY_CASES.length})
        </Text>
        <ItemList>{DISPLAY_CASES.map(renderCase)}</ItemList>
      </ToolbarSection>
      <ToolbarSection>
        <HorizontalItems style={{ flexWrap: 'wrap' }}>
          <Text type="h7">
            {__('artifacts')} ({artifacts.length})
          </Text>
          <span style={{ marginLeft: 'auto' }}>
            <MyToolTip title="Show in list" placement="top">
              <StyledListIcon
                selected={+!showInFolders}
                fontSize="large"
                onClick={() => setShowInFolders(false)}
              />
            </MyToolTip>
            <MyToolTip title="Show in folders" placement="top">
              <StyledFolderIcon
                selected={+showInFolders}
                fontSize="large"
                onClick={() => setShowInFolders(true)}
              />
            </MyToolTip>
          </span>
        </HorizontalItems>
        <HorizontalItems>
          <StyledTextField
            style={{ padding: '10px' }}
            fullWidth
            variant="outlined"
            type="text"
            placeholder={__('search')}
            onChange={event => onSearch(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </HorizontalItems>
        {renderArtifacts(tabValue, setTabValue)}
      </ToolbarSection>
    </>
  );
};

const createQuery = ({ _id: user = null } = {}) => ({ filter: { user } });

const mapState = (state, { exhibitionId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    placingCase: getUIProperty(state, `exhibitions/${exhibitionId}/placingCase`),
    placingArtifact: getUIProperty(state, `exhibitions/${exhibitionId}/placingArtifact`),
    editingArtifactId: getUIProperty(state, 'artifacts/editingArtifactId'),
    editingArtifactType: getUIProperty(state, 'artifacts/editingArtifactType'),
    currentTab: getUIProperty(state, `exhibitions/${exhibitionId}/artifactTab`),
    artifacts: Object.values(getApiResource(state, 'artifacts', createQuery(currentUser))),
    ready: getApiStatus(state, 'artifacts', createQuery(currentUser)) === API_STATUS.IDLE,
    searchTerm: getFormProperty(state, 'artifacts', 'search'),
    showInFolders: getUIProperty(state, 'showInFolders'),
  };
};

const mapDispatch = (dispatch, { exhibitionId, currentUser }) => ({
  setShowInFolders: value => dispatch(setUIProperty('showInFolders', value)),
  onFetchArtifacts: () => dispatch(apiGET('artifacts', createQuery(currentUser))),
  onPlaceDisplayCase: displayCase =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingCase: displayCase,
        placingArtifact: undefined,
      }),
    ),
  onStopPlacing: () =>
    dispatch(
      setUIData(`exhibitions/${exhibitionId}`, {
        placingCase: undefined,
        placingArtifact: undefined,
      }),
    ),
  onTabChange: tab => dispatch(setUIProperty(`exhibitions/${exhibitionId}/artifactTab`, tab)),
  onSearch: searchTerm => dispatch(setFormProperty('artifacts', 'search', searchTerm)),
  onEditorClose: () => dispatch(setUIProperty('artifacts/editingArtifactId', undefined)),
  onUpdateArtifact: artifact =>
    utils.exhibition
      .exportArtifact(artifact)
      .then(a => dispatch(setUIProperty(`exhibitions/${exhibitionId}/updatingArtifact`, a))),
});

const lifecycleMap = {
  onDidMount: ({ onFetchArtifacts }) => onFetchArtifacts(),
};

const ExhibitionArtifactPanel = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionArtifactPanelView);

export default ExhibitionArtifactPanel;
