import utils from './index';

const DEFAULT_WIDTH = 2048;
const DEFAULT_HEIGHT = 2048;
const DEFAULT_QUALITY = 0.8;
const DEFAULT_TYPE = 'image/jpeg';

const createCanvasFromElement = ({ element, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) =>
  new Promise(resolve => {
    if (element && element.tagName.toLowerCase() === 'canvas') {
      resolve(element);
      return;
    }

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(element, 0, 0, canvas.width, canvas.height);

    resolve(canvas);
  });

const createCanvasFromText = ({ text = '', width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT }) =>
  new Promise(resolve => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const words = (text || '').trim().split(/ |\n/);
    const lineHeight = Math.min(
      Math.sqrt((canvas.height * canvas.width) / words.join('').length),
      canvas.height / 2,
    );
    const x = canvas.width / 2 - lineHeight / 4;
    let y = lineHeight;
    let line = '';

    ctx.font = `${lineHeight}px monospace`;
    ctx.textAlign = 'center';
    ctx.fillStyle = 'white';

    words.forEach(word => {
      if (line && ctx.measureText(`${line} ${word}`).width > canvas.width) {
        ctx.fillText(line, x, y, canvas.width);
        y += lineHeight;
        line = '';
      }
      line = `${line} ${word}`;
    });

    ctx.fillText(line, x, y, canvas.width);

    resolve(canvas);
  });

const createCanvas = ({ uri, element, text, width, height }) => {
  if (text) {
    return createCanvasFromText({ text, width, height });
  }

  if (element) {
    const elwidth = Math.min(element.offsetWidth, width || DEFAULT_WIDTH);
    const elheight = Math.min(element.offsetHeight, height || DEFAULT_HEIGHT);
    const mult = Math.min(elwidth / element.offsetWidth, elheight / element.offsetHeight);
    return createCanvasFromElement({
      element,
      width: element.offsetWidth * mult,
      height: element.offsetHeight * mult,
    });
  }

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const imwidth = Math.min(image.width, width || DEFAULT_WIDTH);
      const imheight = Math.min(image.height, height || DEFAULT_HEIGHT);
      const mult = Math.min(imwidth / image.width, imheight / image.height);
      resolve(
        createCanvasFromElement({
          element: image,
          width: image.width * mult,
          height: image.height * mult,
        }),
      );
    };
    image.onerror = err => reject(err);
    image.crossOrigin = 'anonymous';

    if (uri.includes('youtube') || uri.includes('youtu.be')) {
      image.src = `/api/youtube/${utils.youtube.parseYoutubeVideoID(uri)}`;
    } else {
      image.src = uri;
    }
  });
};

export const toBlob = ({ uri, element, text, width, height }) =>
  createCanvas({
    uri,
    element,
    text,
    width,
    height,
  }).then(canvas => new Promise(resolve => canvas.toBlob(blob => resolve(blob))));

export const toObjectURL = ({ uri, element, text, width, height }) =>
  toBlob({
    uri,
    element,
    text,
    width,
    height,
  }).then(blob => Promise.resolve(URL.createObjectURL(blob)));

export const toDataURL = ({ uri, element, text, quality, width, height, type }) =>
  createCanvas({
    uri,
    element,
    text,
    width,
    height,
  }).then(canvas => canvas.toDataURL(type || DEFAULT_TYPE, quality || DEFAULT_QUALITY));

export const toArrayBuffer = ({ uri, element, text, quality, width, height, type }) =>
  toBlob({
    uri,
    element,
    text,
    width,
    height,
  }).then(
    blob =>
      new Promise(resolve => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsArrayBuffer(blob);
      }),
  );
