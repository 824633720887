import React, { useState, useEffect, useLayoutEffect } from 'react';
import { __ } from 'artsteps2-common';

/** *** material ui components **** */
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
/* Generic styles */
import * as generic from '../../styles/GenericStyled';
import * as header from '../../styles/styledComponents/header';
import SearchBarWrapper from '../header/SearchBar';
import SideButtons from '../header/SideButtons';
import MobileHeader from '../header/MobileHeader';
/** ** Functional imports *** */
import logo from '../../styles/images/logo.png';
import logoSmall from '../../styles/images/logo_dark_small.png';
/* import styled components */
import ExhibitonViews from '../exhibitions/viewer/ExhibitionViews';
/** ****1 Previous header imports **** */
import {
  AUTH_STATUS,
  getAuthUser,
  getAuthStatus,
  getUIProperty,
  getLocationQuery,
  getLocation,
  getApiStatus,
  getApiResource,
  API_STATUS,
} from '../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { setUIProperty, apiGET, setLocation } from '../../actions';
import Link from '../generic/Link';
import Logo from '../privateSpaces/Logo';
import utils from '../../utils';
import CreatorViewButtons from '../header/CreatorViewButtons';
import Dialog from '../generic/Dialog';
import DropDown from '../header/DropDown';
import ExhibitionSteps from './ExhibitionSteps';
import useWindowSize from '../../hooks/windowSize';
import { MenuStyled, MenuItemStyled } from '../../styles/GenericStyled';
import colors from '../../styles/colors';
import Sectors from './Sectors';
import COLORS from '../../styles/colors';

/*
  Header will have switches in three sections for three different purposes:
  Purposes:
    1. Visitor user
    2. Logged in User
    3. Private spaces
    4. Editor mode

  Sections:
   1. Logo
   2. NavLinks
   2. Search bar
   3. User Options

*/

// const creatorSteps = ['Create Space', 'Add Content', 'Plan Tour', 'Enter Details'];

/* States that are needed here:
  Creator View,
  LoggedIn user View,
  Curator View,
  ExhibitionPreview,
  Contributed ?
*/

const currrentUserExhibition = (id, currentUser, exhibition) => {
  if (id === 'new') return true;
  else if (currentUser && exhibition && exhibition.user) {
    return (
      exhibition._id === window.location.pathname.split('/')[2] &&
      (currentUser._id === exhibition.user || currentUser._id === exhibition.user._id)
    );
  }
  return false;
};

export const RedesignedHeader = ({
  isPrivateSpace,
  space,
  currentUser = undefined,
  query = {},
  searchPage = '/explore',
  onToggleCreatorView = () => Promise.resolve(false),
  onToggleCuratorView = () => Promise.resolve(false),
  onToggleSwitchSubViewView = () => Promise.resolve(false),
  onRedirect = () => Promise.resolve(false),
  onToggleExhibitionPreview = () => Promise.resolve(false),
  onToggleNavbar = () => Promise.resolve(false),
  onClickedLink = () => Promise.resolve(false),
  onClickedPathLink = () => Promise.resolve(false),
  onCloseDialog = () => Promise.resolve(false),
  onOpenDialog = () => Promise.resolve(false),
  onExhibitionHasChanges,
  creatorView = true,
  curatorView = true,
  switchsubview = false,
  exhibitionPreview = false,
  navbarView = true,
  exhibitionId,
  usersExhibition,
  activeLink = '',
  activeLinkPath = '',
  dialogVisible = false,
  exhibition = { _id: null },
  hasChanges = false,
  categories = [],
  fakeCurrentUser,
}) => {
  const size = useWindowSize();
  const [navLinks, setLinks] = useState([]);
  const [privateSpaces, setPrivateSpaces] = useState([]);
  const [currentExhibition, setCurrentExhibition] = useState(null);

  const handleCreatorView = () => {
    onToggleCreatorView(!creatorView);
  };

  const handleSubView = view => {
    onToggleSwitchSubViewView(view);
  };

  const handleExhibitionPreview = () => {
    if (creatorView) onToggleExhibitionPreview(true);
    else onToggleExhibitionPreview(false);
  };

  // Initialize space settings
  const [showArtstepsRedirect, setShowArtstepsRedirect] = useState(true);
  const [showExhibitions, setShowExhibitions] = useState(true);

  useEffect(() => {
    if (space && space.settings) {
      setShowArtstepsRedirect(space.settings.showArtstepsRedirect); // should not forget to change from db nypsaesthetics hide artsteps link
      setShowExhibitions(space.settings.showExhibitions);
    }
  }, [space]);

  useEffect(() => {
    setPrivateSpaces(
      currentUser &&
        currentUser.spaceDomains &&
        currentUser.spaceDomains.length !== 0 &&
        currentUser.spaceDomains.map(userSpace => ({
          name: userSpace.subdomain,
          path: `https://${space.subdomain}.artsteps.com`,
        })),
    );

    const tmpLinks = [];
    if (showExhibitions) tmpLinks.push({ path: '/explore', name: 'Exhibitors' });
    if (currentUser)
      tmpLinks.push({
        path: `/profile/${currentUser._id}`,
        name: __('profile'),
      });

    setLinks(tmpLinks);

    // Render curator's toolbar
    if (Object.keys(exhibition).length !== 0) setCurrentExhibition(exhibition);

    if (window.location.href.includes('curate')) onToggleCuratorView(true);
    // Render toolbat of creator or visitor view and check if the exhibition that is clicked can be edited
    else if (
      exhibitionPreview ||
      (window.location.href.includes('view') &&
        currentUser &&
        exhibition &&
        exhibition.user &&
        exhibition.user._id === currentUser._id)
    ) {
      onToggleCreatorView(false).then(() => onToggleCuratorView(true));
      onToggleExhibitionPreview(true);
    }
    // Close curator's toolbar
    else {
      onToggleCuratorView(false);
      onToggleExhibitionPreview(false);
    }

    if (size.width >= 960) onToggleNavbar(true);
  }, [currentUser, exhibitionPreview, creatorView, showExhibitions]);

  return (
    <div>
      <Dialog
        type="warning"
        open={dialogVisible}
        title={__('exhibition_leave')}
        message={__('exhibition_leave_message')}
        onConfirm={() => {
          onCloseDialog();
          if (activeLinkPath === '/curate/new/1') window.location.pathname = activeLinkPath;
          onRedirect(activeLinkPath);
          onExhibitionHasChanges(false);
        }}
        onReject={e => {
          e.preventDefault();
          onCloseDialog();
        }}
      />
      <header.AppBarWrapper position="fixed" elevation={0}>
        <header.CustomToolBar>
          <header.ToolbarGrid
            container
            alignItems="center"
            justify="flex-start"
            wrap="nowrap"
            style={size.width <= 720 ? { width: '40px', minHeight: '38px' } : { width: '100%' }}
            navbarview={+navbarView}
          >
            <Box component={Grid} style={{ display: size.width <= 720 ? 'block' : 'none' }} item>
              <Link to="/" style={{ color: 'red' }}>
                <header.SmallLogo
                  onClick={() => onToggleExhibitionPreview(false)}
                  src={logoSmall}
                  alt="logo"
                />
              </Link>
            </Box>
            {size.width >= 720 && (
              <header.NavLinkGrid
                component={Grid}
                container
                item
                xl={!curatorView ? 4 : 6}
                lg={4}
                md={1}
                sm={1}
                alignItems="center"
                zeroMinWidth
                justify="flex-start"
                navbarview={+navbarView}
              >
                {/* Artsteps Link */}
                {isPrivateSpace && !curatorView && showArtstepsRedirect && (
                  <Box
                    display={{ xs: 'none', md: 'flex' }}
                    style={{ marginLeft: '10px', cursor: 'pointer' }}
                    onClick={() => window.location.replace('https://www.artsteps.com/')}
                  >
                    <ArrowBackIosIcon style={{ color: '#343a408c' }} />
                    <generic.Text variant="inherit" type="body1" mycolor="#343a408c">
                      Artsteps
                    </generic.Text>
                  </Box>
                )}

                <div style={{ marginLeft: '10px' }}>
                  <Link to="/">
                    {isPrivateSpace ? (
                      <Logo
                        space={space}
                        height="auto"
                        width="200px"
                        maxHeight="64px"
                        maxWidth="200px"
                      />
                    ) : (
                      <img src={logo} height="auto" width="200px" alt="logo" />
                    )}
                  </Link>
                </div>
                {/* NavLinks */}
                {navbarView && (
                  <>
                    <generic.HorizontalItems>
                      <generic.HorizontalPadding padding="0px 14px 0px 14px">
                        {navLinks.map((link, index) => (
                          <generic.Text
                            key={index}
                            type="body1"
                            mycolor="#343a40"
                            active={`${activeLink === link.name}`}
                            onClick={() => {
                              onClickedLink(link.name);
                            }}
                          >
                            <generic.HeaderLink mycolor="rgb(102 102 102)" to={link.path}>
                              {link.name}
                            </generic.HeaderLink>
                          </generic.Text>
                        ))}
                        {categories.length > 0 && <Sectors categories={categories} />}
                      </generic.HorizontalPadding>
                    </generic.HorizontalItems>
                  </>
                )}
              </header.NavLinkGrid>
            )}
            {size.width > 720 && (
              <header.NavLinkGrid
                component={Grid}
                item
                xl={!curatorView ? 4 : 6}
                lg={4}
                md={1}
                sm={1}
                alignItems="center"
                style={{ minHeight: 'unset' }}
              >
                <generic.Text
                  style={{ textAlign: 'center', height: '100%' }}
                  type="h4"
                  mycolor={`${COLORS.ourBlack}`}
                >
                  Welcome to AthEnsVtradE
                </generic.Text>
              </header.NavLinkGrid>
            )}
            {/* Account Options */}
            {size.width > 720 && (
              <SideButtons
                handleCreatorView={handleCreatorView}
                curatorView={curatorView}
                creatorView={creatorView}
                currentUser={currentUser}
                fakeCurrentUser={fakeCurrentUser}
                handleExhibitionPreview={handleExhibitionPreview}
                switchsubview={switchsubview}
                handleSubView={handleSubView}
                navbarView={navbarView}
                exhibitionId={exhibitionId}
                exhibitionPreview={exhibitionPreview}
              />
            )}
            {/* Mobile view */}
          </header.ToolbarGrid>
          {/* {Open Menu} */}
          {size.width > 720 && size.width < 960 && (
            <IconButton onClick={() => onToggleNavbar(!navbarView)}>
              <header.TransitMenuIcon transit={`${navbarView}`} />
            </IconButton>
          )}

          {size.width <= 720 && <MobileHeader />}
        </header.CustomToolBar>

        <Divider style={{ backgroundColor: 'rgba(0, 0, 0, 0.62)', width: '100%' }} />
      </header.AppBarWrapper>

      {curatorView &&
        currentUser &&
        size.width > 720 &&
        (currrentUserExhibition(exhibitionId, currentUser, exhibition) || creatorView) && (
          <header.AppBarWrapper position="fixed" stepper="true" elevation={0}>
            <header.CustomToolBar stepper="true">
              <header.ToolbarGrid
                container
                alignItems="center"
                justify="space-between"
                wrap="nowrap"
                navbarview={+navbarView}
              >
                {/* Exhibition Info */}
                {(currentExhibition !== null || exhibition) && exhibitionPreview && !creatorView && (
                  <header.ExhibitionInfo
                    component={Grid}
                    item
                    container
                    xs={7}
                    display={{ xs: 'flex' }}
                    direction="column"
                    justify="flex-start"
                  >
                    <Box component={Grid} item alignItems="center" display={{ xs: 'flex' }}>
                      <generic.Text component="span" mycolor="black" type="h7">
                        {exhibition.title && exhibition.title.length && exhibition.title.length > 50
                          ? `${exhibition.title.slice(0, 50)}\n${exhibition.title.slice(50, 100)}`
                          : exhibition.title}
                      </generic.Text>
                    </Box>
                    <Box
                      component={Grid}
                      container
                      item
                      alignItems="center"
                      display={{ xs: 'flex' }}
                      justify="space-between"
                      style={{ maxWidth: '200px' }}
                    >
                      <Box component={Grid} item alignItems="center" display={{ xs: 'flex' }}>
                        <VisibilityIcon color="disabled" />
                        <generic.Text
                          style={{ paddingLeft: '5px' }}
                          component="span"
                          mycolor="black"
                          type="body2"
                        >
                          <ExhibitonViews exhibitionId={exhibitionId} />
                        </generic.Text>
                      </Box>
                      <Box component={Grid} item alignItems="center" display={{ xs: 'flex' }}>
                        <AccessTimeIcon color="disabled" />
                        <generic.Text
                          style={{ paddingLeft: '5px' }}
                          component="span"
                          mycolor="black"
                          type="body2"
                        >
                          {exhibition.createdAt &&
                            utils.date.fromNow(new Date(exhibition.createdAt))}
                        </generic.Text>
                      </Box>
                    </Box>
                  </header.ExhibitionInfo>
                )}
                {/* Exhibition Steps */}
                {creatorView && curatorView && switchsubview && (
                  <ExhibitionSteps exhibitionId={exhibitionId} usersExhibition={usersExhibition} />
                )}
                <CreatorViewButtons
                  creatorView={creatorView}
                  handleCreatorView={handleCreatorView}
                  handleExhibitionPreview={handleExhibitionPreview}
                  handleSubView={handleSubView}
                  switchsubview={switchsubview}
                  exhibitionId={exhibitionId}
                  currentUser={currentUser}
                />
                {/* {Open Menu} */}
                {size.width > 720 && size.width <= 1280 && curatorView && !exhibitionPreview && (
                  <IconButton onClick={() => handleSubView(!switchsubview)}>
                    <header.TransitMenuIcon transit={`${switchsubview}`} />
                  </IconButton>
                )}
              </header.ToolbarGrid>
            </header.CustomToolBar>
          </header.AppBarWrapper>
        )}
    </div>
  );
};

const mapState = (state, { exhibitionId = 'new' }) => ({
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  space: getApiResource(state, `spaces/${window.location.hostname.split('.')[0]}`),
  ready:
    getAuthStatus(state) === AUTH_STATUS.IDLE &&
    getApiStatus(state, `spaces/${window.location.hostname.split('.')[0]}`) === API_STATUS.IDLE,
  currentUser: getAuthUser(state),
  fakeCurrentUser: getUIProperty(state, 'fakeCurrentUser'),
  menuExpanded: getUIProperty(state, 'menu/expanded'),
  creatorView: getUIProperty(state, 'creatorView'),
  curatorView: getUIProperty(state, 'curatorView'),
  switchsubview: getUIProperty(state, 'switchsubview'),
  navbarView: getUIProperty(state, 'navbarView'),
  usersExhibition: getApiResource(state, `exhibitionsUserProfile/${exhibitionId}`),
  exhibitionPreview: getUIProperty(state, 'exhibitionPreview'),
  query: getLocationQuery(state),
  location: getLocation(state),
  hasChanges: getUIProperty(state, `exhibitions/${exhibitionId}/hasChanges`),
  exhibition: window.location.href.includes('currentUser')
    ? getApiResource(state, `exhibitionsUserProfile/${exhibitionId}`)
    : getApiResource(state, `exhibitions/${exhibitionId}`),
  activeLink: getUIProperty(state, 'activeLink'),
  activeLinkPath: getUIProperty(state, 'activeLinkPath'),
  dialogVisible: getUIProperty(state, `exhibitions/${exhibitionId}/dialog`),
  sectors: getUIProperty(state, 'sectors'),
  categories: Object.values(getApiResource(state, 'categories')),
});

const mapDispatch = (dispatch, { exhibitionId = 'new', categories }) => ({
  onFetchUser: ({ currentUser }) => dispatch(apiGET(`users/${currentUser._id}`)),
  onToggleCreatorView: view => dispatch(setUIProperty('creatorView', view)),
  onToggleCuratorView: view => dispatch(setUIProperty('curatorView', view)),
  onToggleSwitchSubViewView: view => dispatch(setUIProperty('switchsubview', view)),
  onRedirect: location => dispatch(setLocation(location)),
  onToggleExhibitionPreview: view => dispatch(setUIProperty('exhibitionPreview', view)),
  onToggleNavbar: view => dispatch(setUIProperty(`navbarView`, view)),
  onClickedLink: link => dispatch(setUIProperty('activeLink', link)),
  onClickedPathLink: link => dispatch(setUIProperty('activeLinkPath', link)),
  onCloseDialog: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/dialog`, false)),
  onOpenDialog: () => dispatch(setUIProperty(`exhibitions/${exhibitionId}/dialog`, true)),
  onExhibitionHasChanges: hasChanges =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/hasChanges`, hasChanges)),
  onFetchCategories: () => dispatch(apiGET('categories')),
});

const lifecycleMap = {
  onDidMount: ({
    onFetchUser,
    currentUser,
    onToggleCreatorView,
    onFetchSectors,
    setSectors,
    onFetchCategories,
  }) => {
    onToggleCreatorView(true);
    onFetchCategories();

    if (currentUser) onFetchUser({ currentUser });
  },
};

const DefaultHeader = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(RedesignedHeader);

export default DefaultHeader;
