import React from 'react';
import { __ } from 'artsteps2-common';
import { oauthLogin, addMessage, setLocation } from '../../../actions';
import { AUTH_STATUS, getAuthStatus } from '../../../reducers';
import { compose, withState, withDispatch } from '../../../enhancers';
import googleLogo from '../../../styles/images/google-icon.png';

import { StyledButton, HorizontalItems } from '../../../styles/GenericStyled';

const logos = {
  googleLogo,
};
export const OAuthButtonView = ({
  service = '',
  redirectionURL = '',
  ready = true,
  setOpenLogin = () => Promise.resolve(false),
  onOAuthLogin = () => Promise.resolve({ response: { error: 'error' } }),
  onRedirect = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  text,
}) => (
  <StyledButton
    onClick={() =>
      onOAuthLogin().then(({ response }) => {
        if (response.error) {
          onAddMessage({ title: __(response.error), type: 'error' });
          return;
        }
        setOpenLogin(false);
      })
    }
    minwidth="150px"
    background="#F5F5F5"
    mycolor="black"
    type="secondary"
  >
    <HorizontalItems>
      <img style={{ width: '50px', height: '50px' }} src={logos.googleLogo} alt="" />
      {text}
    </HorizontalItems>
  </StyledButton>
);

const mapState = state => ({
  ready: getAuthStatus(state) === AUTH_STATUS.IDLE,
});

const mapDispatch = (dispatch, { service }) => ({
  onRedirect: page => dispatch(setLocation(page)),
  onOAuthLogin: () => dispatch(oauthLogin(service)),
  onAddMessage: message => dispatch(addMessage(message)),
});

const OAuthButton = compose(withState(mapState), withDispatch(mapDispatch))(OAuthButtonView);

export default OAuthButton;
