import React, { useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { addMessage, apiPOST, setUIProperty } from '../actions';
import { compose, withState, withDispatch, withLifecycle } from '../enhancers';
import { getAuthUser, getUIProperty } from '../reducers';
import * as PrivateComponent from '../styles/PrivateSpacesPageStyled';
import DefaultLayout from '../components/layouts/DefaultLayout';
import useWindowSize from '../hooks/windowSize';
import { BodyWithPadding, HorizontalItems, Text, VerticalItems } from '../styles/GenericStyled';
import Grid from '@material-ui/core/Grid';
import blueMan from '../styles/images/blueMan.png';
import PSpaperMobile from '../components/privateSpaces/PSpaperMobile';
import PSbanner from '../components/privateSpaces/PSbanner';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import logo from '../styles/images/logo.png';
import CloseIcon from '@material-ui/icons/Close';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactComponent as Send } from '../styles/images/send-24px.svg';
import Modal from '@material-ui/core/Modal';
import styled, { css } from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import videoPreview from '../styles/images/privateSpaces/virtual-events-video.mp4';
import {
  CarouselGrid,
  InfoStyled,
  MediaAreaMobile,
  PaperStyled,
  VideoArea,
  VideoAreaMobile,
} from '../styles/PrivateSpacesPageStyled';

const { getNames } = require('country-list');

const breakPoint = 1515;

const SectionTitle = ({ text }) => {
  const size = useWindowSize();

  return (
    <div style={{ marginBottom: size.width <= 1200 ? '0px' : '50px' }}>
      <Text
        type={size.width < 600 ? 'h6' : 'h4'}
        align="center"
        style={{ marginBottom: '5px' }}
        title="true"
      >
        {text}
      </Text>
      <hr style={{ margin: '0px' }} />
    </div>
  );
};

const VirtualEventsView = props => {
  useEffect(() => {
    document.title = `Virtual Events`;
  }, []);

  const size = useWindowSize();

  /*'Virtual Ecommerce',*/

  const PaperContainers = [
    {
      text: 'Ecommerce',
      description:
        'Boost your ROI and showcase your products in real-life dimensions and situations, while linking your e-shop.',
      source: blueMan,
      background: '#E7ECF7',
      id: '0',
    },
    {
      text: 'Trade Shows',
      description:
        'Connect with potential customers and vendors in an interactive virtual expo event.',
      source: blueMan,
      background: '#E4DEE8',
      id: '1',
    },
    {
      text: 'Marketing',
      description:
        'Fully immerse your business in a striking virtual experience with advanced marketing capabilities.',
      source: blueMan,
      background: '#C7EBE4',
      id: '2',
    },
  ].map(obj =>
    size.width < 1200 ? (
      <PSpaperMobile
        id={obj.id}
        subtext={obj.subtext}
        text={obj.text}
        key={obj.text}
        description={obj.description}
        source={obj.source}
        background={obj.background}
      />
    ) : (
      <PaperStyled elevation={5}>
        <BodyWithPadding padding="28px">
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Text style={{ marginBottom: '20px' }} align="center" type="h5">
              {obj.text}
            </Text>
            <Text align="center" type="body1">
              {obj.description}
            </Text>
          </div>
        </BodyWithPadding>
      </PaperStyled>
    ),
  );

  const [open, setOpen] = React.useState(false);
  const [countryInput, setCountryInput] = useState('');
  const [inputData, setInputData] = useState({
    username: '',
    email: '',
    country: '',
    company: '',
  });

  const handleClose = () => {
    setOpen(false);
    setInputData({
      username: '',
      email: '',
      country: '',
      company: '',
    });
  };

  const handleSubmit = e => {
    props
      .onCreateContactForm({
        username: '',
        email: '',
        country: '',
        company: '',
      })
      .then(({ response }) => {
        if (!response.error) {
          props.onAddMessage({ title: 'Your message has been sent', type: 'success' });
        } else {
          props.onAddMessage({ title: response.error, type: 'error' });
        }
      });
    handleClose();
  };

  const isDisabled = state => {
    let disabled = false;
    for (const [key, value] of Object.entries(state)) {
      if (key !== 'company' && value === '') disabled = true;
    }
    return disabled;
  };

  const handleChange = (event, option) => {
    let name;
    let value;
    if (option === null) {
      value = '';
      name = 'country';
    } else if (!option) {
      name = event.target.name ? event.target.name : event.target.getAttribute('name');
      value = event.target.value !== 0 ? event.target.value : event.target.getAttribute('value');
    } else {
      value = option;
      name = 'country';
    }
    inputData[name] = value;
    setInputData({ ...inputData });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const CloseButton = styled(IconButton)`
    position: absolute;
    right: 15px;
    top: 5px;
    display: none;
    ${props =>
      props.visible &&
      css`
        display: flex;
      `}
  `;

  const infos = [
    'Simulate the feeling of a real life event with rich visual surroundings',
    'Customizable exhibitor booths with personalized branding and design capabilities',
    'Interactive communication between exhibitors and customers who appear as avatars',
    'Event operations technical assistance',
  ];

  return (
    <DefaultLayout>
      <PSbanner
        title="Virtual Events"
        sub1="Host your own virtual events and showcase your business globally"
        sub2="Generate qualified leads with interactive virtual pavilions"
      />

      <PrivateComponent.Section id="scroll-p" mobile={size.width < breakPoint}>
        <SectionTitle text="Key Features" />
        {size.width < breakPoint ? (
          /*<CustomCarouselMobile media={media} infos={infos} />*/
          <Grid container justify="center" direction="column" wrap="wrap">
            <Grid container direction="column" item key={1} spacing={10}>
              <Grid item>
                <MediaAreaMobile>
                  <VideoAreaMobile autoPlay active loop muted="muted">
                    <source src={videoPreview} type="video/mp4" />
                  </VideoAreaMobile>
                </MediaAreaMobile>
                <Text
                  style={{ margin: '50px' }}
                  align="center"
                  type={`${size.width < 600 ? 'body1' : 'sub1'}`}
                >
                  Simulate the feeling of a real life event with rich visual surroundings
                </Text>
              </Grid>
            </Grid>

            <Grid container direction="column" item key={2} spacing={10}>
              <Grid item>
                <Text
                  style={{ margin: '50px' }}
                  align="center"
                  type={`${size.width < 600 ? 'body1' : 'sub1'}`}
                >
                  Customizable exhibitor booths with personalized branding and design capabilities
                </Text>
              </Grid>
            </Grid>

            <Grid container direction="column" item key={3} spacing={10}>
              <Grid item>
                <Text
                  style={{ margin: '50px' }}
                  align="center"
                  type={`${size.width < 600 ? 'body1' : 'sub1'}`}
                >
                  Interactive communication between exhibitors and customers who appear as avatars
                </Text>
              </Grid>
            </Grid>

            <Grid container direction="column" item key={4} spacing={10}>
              <Grid item>
                <Text
                  style={{ margin: '50px' }}
                  align="center"
                  type={`${size.width < 600 ? 'body1' : 'sub1'}`}
                >
                  Event operations technical assistance
                </Text>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <CarouselGrid container justify="space-around" wrap="wrap">
            <Grid item style={{ marginLeft: '100px', width: '100px' }} xs={4}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
                style={{ minHeight: '100%' }}
              >
                {infos.map((info, index) => (
                  <Grid style={{ width: '100%' }} key={index} item>
                    <InfoStyled active={1}>{info}</InfoStyled>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid style={{ position: 'relative' }} item xs={7}>
              <VideoArea active autoPlay loop>
                <source src={videoPreview} type="video/mp4" />
              </VideoArea>
            </Grid>
          </CarouselGrid>
        )}
      </PrivateComponent.Section>

      <PrivateComponent.Section mobile={size.width < 1200}>
        <SectionTitle text="Designed For" />
        <VerticalItems>
          <HorizontalItems style={{ flexDirection: size.width < 1200 ? 'column' : 'row' }}>
            {PaperContainers}
          </HorizontalItems>
        </VerticalItems>
      </PrivateComponent.Section>

      <PrivateComponent.Requestgrid
        container
        justify="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <PrivateComponent.CustomTypography variant="h4" header="true">
            Interested in creating{' '}
            <PrivateComponent.CustomTypography variant="inherit" span="true">
              {size.width <= 666 && size.width >= 444 ? <br /> : <span />}
              your own virtual event?
            </PrivateComponent.CustomTypography>
          </PrivateComponent.CustomTypography>
        </Grid>
        <Grid item>
          <PrivateComponent.CustomButton onClick={handleOpen} request="true">
            Contact us
            <PrivateComponent.PlacedArrowIcon open={true} />
          </PrivateComponent.CustomButton>
        </Grid>
      </PrivateComponent.Requestgrid>
      {/*Modal*/}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        style={{ overflow: 'auto' }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PrivateComponent.ModalGrid container direction="column" justify="center" spacing={2}>
            <Box component={Grid} item textAlign="center">
              <img alt="" src={logo} width="177px" height="59px" />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.CustomTypography variant="h4" modalhead="true">
                Request Details
              </PrivateComponent.CustomTypography>
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="username-input">
                Name
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="username-input"
                onChange={e => handleChange(e)}
                name="username"
                value={inputData.username}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="email-input">
                Email
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="email-input"
                onChange={handleChange}
                name="email"
                value={inputData.email}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="country-input">
                Country
              </PrivateComponent.InputsLabel>
              <PrivateComponent.CustomAutocomplete
                value={inputData.country}
                onChange={(e, value) => handleChange(e, value)}
                onInputChange={(event, newInputValue) => {
                  setCountryInput(newInputValue);
                }}
                inputValue={countryInput}
                autoComplete
                options={getNames()}
                clearOnEscape
                getOptionSelected={option => option}
                getOptionLabel={option => option}
                renderOption={option => <React.Fragment>{option}</React.Fragment>}
                renderInput={params => (
                  <PrivateComponent.CustomTextField
                    {...params}
                    placeholder="Select"
                    style={{ border: 0 }}
                    required
                  />
                )}
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="company-input">
                <PrivateComponent.CustomTypography company="true">
                  Company
                </PrivateComponent.CustomTypography>
                <PrivateComponent.CustomTypography optional="true">
                  (Optional)
                </PrivateComponent.CustomTypography>
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="company-input"
                onChange={handleChange}
                name="company"
                value={inputData.company}
              />
            </Box>
            <Box component={CloseButton} onClick={handleClose} visible={size.width < breakPoint}>
              <CloseIcon />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.SendButton disabled={isDisabled(inputData)} onClick={handleSubmit}>
                Send your Request
                <SvgIcon>
                  <Send />
                </SvgIcon>
              </PrivateComponent.SendButton>
            </Box>
          </PrivateComponent.ModalGrid>
        </Fade>
      </Modal>
    </DefaultLayout>
  );
};

const lifecycleMap = {
  onDidMount: () => {
    ReactPixel.track('ViewContent');
  },
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  requestOpen: getUIProperty('requestOpen'),
});
const mapDispatch = dispatch => ({
  setOpenRequest: status => dispatch(setUIProperty('requestOpen', status)),
  onCreateContactForm: form => dispatch(apiPOST('privatespaces', form)),
  onAddMessage: message => dispatch(addMessage(message, 'privatespaces')),
});

const VirtualEvents = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(VirtualEventsView);

export default VirtualEvents;
