import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import styled, { css } from 'styled-components';
import colors from '../colors';

export const IFDiv = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  height: ${props => props.height};
  background: ${colors.ourWhite};
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  position: absolute;
  z-index: 999;
`;

export const IFTopBar = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.ourGray};
  background: rgb(186, 186, 183);
  background: linear-gradient(180deg, rgba(186, 186, 183, 1) 41%, rgba(115, 110, 110, 1) 96%);
  padding: 5px;
`;

export const IFCloseIcon = styled(CloseIcon)`
  font-size: 32px;

  &:hover {
    color: ${colors.ourRed};
  }
`;

export const IFFullscreenIcon = styled(FullscreenIcon)`
  font-size: 32px;

  &:hover {
    color: ${colors.ourRed};
  }
`;

export const IFFullscreenExitIcon = styled(FullscreenExitIcon)`
  font-size: 32px;

  &:hover {
    color: ${colors.ourRed};
  }
`;
