import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { MenuStyled, MenuItemStyled, ExpandButton } from '../../styles/GenericStyled';
import Box from '@material-ui/core/Box';
import colors from '../../styles/colors';
import MyTooltip from '../../components/generic/MyToolTip';

const DropDownMenu = ({ children, iconclass }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const ref = React.useRef();

  const handleClick = event => {
    setAnchorEl(ref.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MenuItems =
    children instanceof Array ? (
      children.map(child => (
        <MenuItemStyled
          background={child?.props?.background}
          mycolor={child?.props?.mycolor}
          onClick={handleClose}
          disableScrollLock={true}
        >
          {child}
        </MenuItemStyled>
      ))
    ) : (
      <MenuItemStyled
        background={children?.props?.background}
        mycolor={children?.props?.mycolor}
        onClick={handleClose}
      >
        {children}
      </MenuItemStyled>
    );

  return (
    <>
      <MyTooltip placement="top" title="More">
        <Box
          component={ExpandButton}
          boxShadow={3}
          mycolor={colors.ourBlack}
          mycolorhover={colors.ourWhite}
          background={colors.ourWhite}
          onClick={handleClick}
          backgroundhover={colors.ourBlack}
        >
          <MoreVertIcon
            className={iconclass}
            ref={ref}
            aria-controls="simple-menu"
            aria-haspopup="true"
          />
        </Box>
      </MyTooltip>
      <MenuStyled
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {MenuItems}
      </MenuStyled>
    </>
  );
};

export default DropDownMenu;
