import React from 'react';
import Grid from '@material-ui/core/Grid';
import { getAuthUserRoles, getApiResource } from '../reducers';
import { withState, withDispatch, compose, withLifecycle } from '../enhancers';
import { apiGET } from '../actions';
import DefaultLayout from '../components/layouts/DefaultLayout';
import { Text, BodyWithPadding } from '../styles/GenericStyled';
import { FAQDiv, ItemsWithMargin } from '../styles/styledComponents/FAQStyled';
import AdminFaqControls from '../components/admin/AdminFaqControls';
import colors from '../styles/colors';
import useWindowSize from '../hooks/windowSize';
import isLink from '../reusableFunctions/isLink';

export const FAQView = ({ faqs, isAdmin }) => {
  const size = useWindowSize();

  const frequentlyAQ = faqs.map(faq => (
    <Grid item key={faq._id}>
      {isAdmin && <AdminFaqControls faq={faq} />}
      <FAQDiv mycolor={colors.ourRed} background={colors.ourGray} radius="0px 40px 0px 0px">
        <ItemsWithMargin>
          <Text type="h3" mycolor={colors.ourRed}>
            Q.
          </Text>{' '}
          <Text style={{ padding: '10px' }} type="h6">
            {faq.question}
          </Text>
        </ItemsWithMargin>
      </FAQDiv>
      <FAQDiv mycolor={colors.ourGreen} background="#f0fff0" radius="0px 0px 40px 0px">
        <ItemsWithMargin>
          <Text type="h3" mycolor={colors.ourGreen}>
            A.
          </Text>{' '}
          <Text style={{ padding: '10px' }} type="body1">
            {(faq.answer || '').split(/[\n\r]+/g).map(
              (line, idx) =>
                line && (
                  <span key={idx}>
                    {line.split(' ').map((word, id) =>
                      isLink(word) ? (
                        <a style={{ color: colors.ourBlue }} href={word} key={idx}>
                          {` ${word}`}
                        </a>
                      ) : (
                        ` ${word}`
                      ),
                    )}
                    <br />
                  </span>
                ),
            )}
          </Text>
        </ItemsWithMargin>
      </FAQDiv>
    </Grid>
  ));

  return (
    <DefaultLayout>
      <BodyWithPadding padding={`${size.width <= 590 ? '10px' : '20px 20vw 20px 20vw'}`}>
        <Text style={{ marginBottom: '20px' }} type="h3" align="center">
          {size.width <= 590 ? 'FAQ' : 'Frequently Asked Questions (FAQ)'}
        </Text>
        {faqs.length === 0 && isAdmin && (
          <>
            <AdminFaqControls isEmpty />
            <Text type="h6">No questions availiable at the moment</Text>
          </>
        )}
        <Grid container direction="column" spacing={5}>
          {frequentlyAQ}
        </Grid>
      </BodyWithPadding>
    </DefaultLayout>
  );
};

const EDITOR_ROLES = ['admin'];
const mapState = state => ({
  faqs: Object.values(getApiResource(state, 'faqs')),
  isAdmin: getAuthUserRoles(state).some(role => EDITOR_ROLES.includes(role)),
});

const mapDispatch = dispatch => {
  return {
    onFetchFaqs: () => dispatch(apiGET('faqs')),
  };
};

const lifecycleMap = {
  onDidMount: ({ onFetchFaqs }) => {
    onFetchFaqs();
  },
};

const FAQ = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(FAQView);

export default FAQ;
