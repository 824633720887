import ShareIcon from '@material-ui/icons/Share';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Box from '@material-ui/core/Box';
import React from 'react';
import { __ } from 'artsteps2-common';
import { compose, withState, withDispatch, withProps } from '../../../enhancers';
import { setUIData, addMessage } from '../../../actions';
import { getUIProperty } from '../../../reducers';
import ExhibitionSharing from './ExhibitionSharing';
import {
  Text,
  StyledButton,
  HorizontalItems,
  ExpandButton,
  BodyWithPadding,
} from '../../../styles/GenericStyled';
import { ShareDiv, StyledTextField } from './ExhibitionInfoStyled';
import OurModal from '../../../components/generic/OurModal';
import colors from '../../../styles/colors';
import MyToolTip from '../../generic/MyToolTip';

export const ExhibitionEmbedView = ({
  mobile,
  iconClass,
  exhibitionId,
  hostname = '',
  exhibition = { _id: null },
  width = 560,
  height = 315,
  onSetResolution = () => Promise.resolve(false),
  onAddMessage,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const copyEmbed = () => {
    /* Get the text field */
    const copyText = document.getElementById('embed-text');

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand('copy');

    onAddMessage({
      type: 'success',
      title: __('Copy'),
      description: 'Successfully copied embeded hmtl!',
    });

    /* Alert the copied text */
  };
  // <StyledButton type="secondary" background="white" mycolor="black" onClick={handleOpen}>
  return (
    <div>
      <MyToolTip placement="top" title="Share">
        <Box
          component={ExpandButton}
          boxShadow={3}
          mycolor={colors.ourBlack}
          mycolorhover={colors.ourWhite}
          background={colors.ourWhite}
          backgroundhover={colors.ourBlack}
          onClick={handleOpen}
        >
          <ShareIcon />
        </Box>
      </MyToolTip>

      <OurModal openModal={open} setOpenModal={setOpen} width="30%" height="70%" minWidth="350px">
        <BodyWithPadding padding="30px 30px 0px 30px">
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12}>
              <ShareDiv>
                <Text style={{ marginBottom: '30px' }} type="h5">
                  {__('share')} {__('exhibition')}
                </Text>
                <Text type="h7">{__('share_on_social_media')}</Text>
                <ExhibitionSharing exhibition={exhibition} />

                <HorizontalItems style={{ marginTop: '30px' }}>
                  <Text h6={1}>{__('embed_code')}</Text>
                  <HelpOutlineIcon style={{ marginLeft: '5px', color: 'gray' }} />
                </HorizontalItems>

                <div style={{ position: 'relative', marginBottom: '30px' }}>
                  <StyledTextField
                    id="embed-text"
                    multiline
                    fullWidth
                    rows={4}
                    variant="outlined"
                    value={`<iframe width="${width}" height="${height}" src="${hostname}/embed/${exhibitionId}/${width}/${height}" frameborder="0" allowfullscreen></iframe>`}
                    readOnly
                  />
                  <MyToolTip title="Copy" placement="top">
                    <Box
                      style={{ position: 'absolute', bottom: '5px', right: '7px' }}
                      component={ExpandButton}
                      boxShadow={3}
                      mycolor={colors.ourBlack}
                      mycolorhover={colors.ourWhite}
                      background={colors.ourWhite}
                      backgroundhover={colors.ourBlack}
                      onClick={copyEmbed}
                    >
                      <FileCopyIcon />
                    </Box>
                  </MyToolTip>
                </div>

                <HorizontalItems>
                  <Text h6={1}>{__('resolution')}</Text>
                  <HelpOutlineIcon style={{ marginLeft: '5px', color: 'gray' }} />
                </HorizontalItems>

                <FormControl variant="outlined" style={{ marginBottom: '55px' }}>
                  <Select
                    name="type"
                    className="ui dropdown"
                    value={`${width}x${height}`}
                    onChange={onSetResolution}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                  >
                    <MenuItem value="560x315">560x315</MenuItem>
                    <MenuItem value="640x360">640x360</MenuItem>
                    <MenuItem value="853x480">853x480</MenuItem>
                    <MenuItem value="1280x720">1280x720</MenuItem>
                  </Select>
                </FormControl>
                <StyledButton
                  style={{ marginLeft: 'auto' }}
                  onClick={handleClose}
                  type="secondary"
                  mycolor="white"
                  background="#12ad8f"
                >
                  OK
                </StyledButton>
              </ShareDiv>
            </Grid>
          </Grid>
        </BodyWithPadding>
      </OurModal>
    </div>
  );
};

const mapState = (state, { exhibitionId }) => ({
  displayOptions: getUIProperty(state, `exhibitions/${exhibitionId}/embedOptions`),
  width: getUIProperty(state, `exhibitions/${exhibitionId}/embedWidth`),
  height: getUIProperty(state, `exhibitions/${exhibitionId}/embedHeight`),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onSetResolution: ({ target: { value: resolution } }) => {
    const [embedWidth, embedHeight] = resolution.split('x').map(n => Number(n));
    dispatch(setUIData(`exhibitions/${exhibitionId}`, { embedWidth, embedHeight }));
  },
  onAddMessage: message => dispatch(addMessage(message)),
});

const propsMap = {
  hostname: `${window.location.protocol}//${window.location.host}`,
};

const ExhibitionEmbed = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withProps(propsMap),
)(ExhibitionEmbedView);

export default ExhibitionEmbed;
