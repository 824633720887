import React from 'react';
import { __ } from 'artsteps2-common';
import utils from '../../utils';
import ImageEditor from './forms/ImageEditor';
import VideoEditor from './forms/VideoEditor';
import ObjectEditor from './forms/ObjectEditor';
import TextEditor from './forms/TextEditor';
import Loader from '../generic/Loader';
import Dialog from '../generic/Dialog';
import { compose, withState, withDispatch, withLifecycle } from '../../enhancers';
import { API_STATUS, getApiStatus, getUIProperty, getForm, getAuthUser } from '../../reducers';
import {
  apiPOST,
  apiPATCH,
  apiGET,
  apiDELETE,
  addMessage,
  clearMessages,
  clearFormData,
  setUIProperty,
  setFormData,
} from '../../actions';

const SIZE_LIMIT = 8000000;

export const ArtifactEditorView = ({
  artifactId,
  currentUserId,
  form = { data: {} },
  saving = false,
  ready = true,
  removeDialogOpen = false,
  isNew = false,
  onUpsert = () => Promise.resolve({ response: { error: 'error' } }),
  onSave = () => Promise.resolve(false),
  onClose = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  onRemoveDialogOpen = () => Promise.resolve(false),
  onRemoveDialogClose = () => Promise.resolve(false),
  onRemove = () => Promise.resolve(false),
  onClearFormData = () => Promise.resolve(false),
}) => {
  const mapFormData = data => ({
    ...data,
    files:
      data.files &&
      data.files.map(file => ({
        bin: file.bin && file.bin.content && file.bin,
        uri: file.uri,
        fileType: file.type,
      })),
    covers:
      data.covers &&
      data.covers.map(cover => ({
        bin: cover.bin.content && cover.bin.content && cover.bin,
        uri: cover.uri,
      })),
    image:
      data.files &&
      data.files.length > 0 &&
      ((data.files[0].bin && data.files[0].bin.content) ||
        (data.files[0].uri && data.files[0].image))
        ? data.files[0].image
        : data.image,
  });

  const onSubmit = () => {
    const dataToSend = mapFormData(form.data);
    const size = new TextEncoder().encode(JSON.stringify(dataToSend)).length;
    if (size < SIZE_LIMIT) {
      return onUpsert(mapFormData(form.data)).then(({ response }) =>
        response.error
          ? onAddMessage({ title: __(response.error), type: 'error' })
          : onClearFormData().then(() => onSave(response)),
      );
    } else {
      onAddMessage({ title: __('size_general'), type: 'error' });
    }
  };

  return (
    <div className="artifact-info">
      <h2>
        {__(isNew ? 'add' : 'edit')}
        &nbsp;
        {__(form.data.type)}
      </h2>
      {form.data.type === 'image' && (
        <ImageEditor
          artifactId={artifactId}
          ready={ready}
          form={form}
          onClose={onClose}
          onRemove={isNew ? undefined : onRemoveDialogOpen}
          onSubmit={onSubmit}
        />
      )}
      {form.data.type === 'video' && (
        <VideoEditor
          artifactId={artifactId}
          form={form}
          onClose={onClose}
          onRemove={isNew ? undefined : onRemoveDialogOpen}
          onSubmit={onSubmit}
        />
      )}
      {form.data.type === 'text' && (
        <TextEditor
          form={form}
          onClose={onClose}
          onRemove={isNew ? undefined : onRemoveDialogOpen}
          onSubmit={onSubmit}
        />
      )}
      {form.data.type === 'object' && (
        <ObjectEditor
          artifactId={artifactId}
          form={form}
          onClose={onClose}
          onRemove={isNew ? undefined : onRemoveDialogOpen}
          onSubmit={onSubmit}
        />
      )}
      {saving && <Loader message={`${__('saving')} ${saving}`} />}
      <Dialog
        open={removeDialogOpen}
        title={__('artifact_deletion', form.data)}
        message={__('confirm_artifact_deletion', form.data)}
        type="warning"
        onReject={onRemoveDialogClose}
        onConfirm={() =>
          onRemoveDialogClose()
            .then(() => onRemove())
            .then(({ response: { error } = {} }) =>
              error ? onAddMessage({ type: 'error', title: __(error) }) : Promise.resolve(true),
            )
            .then(() => onClose())
        }
      />
    </div>
  );
};

const toFormName = artifactId => `artifact:${artifactId}`;
const createQuery = ({ _id: user } = {}) => ({ filter: { user } });

const mapState = (state, { artifactId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    ready:
      getApiStatus(state, `artifacts/${artifactId}`) === API_STATUS.IDLE &&
      getApiStatus(state, 'artifacts', createQuery(currentUser)) === API_STATUS.IDLE,
    form: getForm(state, toFormName(artifactId)),
    isNew: artifactId && artifactId.match(/^new/),
    removeDialogOpen: getUIProperty(state, `artifacts/${artifactId}/removeDialog`),
  };
};

const mapDispatch = (
  dispatch,
  { artifactId, type = 'image', currentUser, exhibitionId, form, isNew },
) => ({
  onAddMessage: message => dispatch(addMessage(message, 'artifacts')),
  onClearMessages: () => dispatch(clearMessages('artifacts')),
  onUpsert: data =>
    isNew
      ? dispatch(apiPOST('artifacts', data, createQuery(currentUser)))
      : dispatch(apiPATCH(`artifacts/${artifactId}`, data)),
  onClearFormData: () =>
    dispatch(clearFormData(toFormName(artifactId))).then(() =>
      dispatch(
        setFormData(toFormName(artifactId), {
          type,
          _id: isNew ? undefined : artifactId,
        }),
      ),
    ),
  onSetFormData: data => dispatch(setFormData(toFormName(artifactId), data)),
  onFetchArtifact: () =>
    isNew
      ? Promise.resolve({})
      : dispatch(
          apiGET(`artifacts/${artifactId}`, {
            include: type === 'text' && 'files.bin.content',
          }),
        ),
  onRemoveDialogOpen: () => dispatch(setUIProperty(`artifacts/${artifactId}/removeDialog`, true)),
  onRemoveDialogClose: () => dispatch(setUIProperty(`artifacts/${artifactId}/removeDialog`, false)),
  onRemove: () =>
    utils.exhibition
      .exportArtifact(form.data)
      .then(exportedArtifact =>
        dispatch(setUIProperty(`exhibitions/${exhibitionId}/removingArtifact`, exportedArtifact)),
      )
      .then(() => dispatch(apiDELETE(`artifacts/${artifactId}`))),
});

const onInitialization = ({ onClearFormData, onFetchArtifact, onSetFormData }) =>
  onClearFormData()
    .then(() => onFetchArtifact())
    .then(({ response: artifact }) => onSetFormData(artifact));

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: ({ artifactId }, props) =>
    artifactId !== props.artifactId && onInitialization(props),
};

const ArtifactEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ArtifactEditorView);

export default ArtifactEditor;
