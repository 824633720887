import React from 'react';
import { __ } from 'artsteps2-common';
import publicConfig from 'artsteps2-config/public.json';
import ArtifactInfoEditor from './ArtifactInfoEditor';

const IMAGE_WIDTH = publicConfig.artifact.video.imageWidth;
const IMAGE_HEIGHT = publicConfig.artifact.video.imageHeight;

export const VideoEditorView = ({
  form = { data: {} },
  ready = true,
  onSubmit,
  onRemove,
  onClose,
  artifactId,
}) => (
  <ArtifactInfoEditor
    artifactId={artifactId}
    form={form}
    ready={ready}
    onSubmit={onSubmit}
    onClose={onClose}
    onRemove={onRemove}
    dimensions={['width', 'height']}
    dimensionsInches={['width', 'height']}
    saveDisabled={
      !form.data.files ||
      !form.data.files.length > 0 ||
      (!form.data.files[0].bin && !form.data.files[0].uri) ||
      (!form.data.image && !form.data.files[0].image)
    }
    extraFields={[
      {
        files: {
          type: 'video',
          label: __('video'),
          required: true,
          multiple: false,
          asArray: true,
          preview: (file, idx) => `/api/artifacts/${form.data._id}/files/${idx}/bin`,
          thumbnail: {
            name: 'image',
            width: IMAGE_WIDTH,
            height: IMAGE_HEIGHT,
          },
        },
      },
    ]}
  />
);

const VideoEditor = VideoEditorView;

export default VideoEditor;
