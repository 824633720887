import React, { useState } from 'react';
import { __ } from 'artsteps2-common';
import BrushIcon from '@material-ui/icons/Brush';
import Box from '@material-ui/core/Box';
import { compose, withState, withDispatch } from '../../../../../enhancers';
import { setUIProperty } from '../../../../../actions';
import { getAuthUser, getUIProperty } from '../../../../../reducers';
import { HorizontalItems, ExpandButton } from '../../../../../styles/GenericStyled';
import { ColorSquare, TextureSquare, MaterialSquare } from './ExhibitionThemeStyled';
import colors from '../../../../../styles/colors';
import MyToolTip from '../../../../generic/MyToolTip';
import Dialog from '../../../../generic/Dialog';

const themeMaterials = [
  'ceilingMaterial',
  'wallCorniceMaterial',
  'interiorWallMaterial',
  'exteriorWallMaterial',
  'floorMaterial',
  'groundMaterial',
];

const convertValueToName = mat => {
  let name = mat.substring(0, 1).toUpperCase() + mat.substring(1);
  return name.match(/[A-Z][a-z]+/g).join(' ');
};

export const ThemeView = ({ setTheme, theme, title, selectedTheme }) => {
  const [displayDialog, setDisplayDialog] = useState(false);

  return theme ? (
    <HorizontalItems style={{ marginBottom: '8px', flexWrap: 'wrap' }}>
      <MyToolTip placement="top" title={`Use ${title} theme`}>
        <Box
          disabled={theme === selectedTheme}
          style={{ marginBottom: '8px' }}
          component={ExpandButton}
          boxShadow={3}
          mycolor={colors.ourBlack}
          mycolorhover={colors.ourWhite}
          background={colors.ourWhite}
          backgroundhover={colors.ourBlack}
          onClick={() => setDisplayDialog(true)}
        >
          <BrushIcon />
        </Box>
      </MyToolTip>
      {themeMaterials.map((mat, idx) => (
        <MyToolTip key={idx} placement="top" title={convertValueToName(mat)}>
          <MaterialSquare>
            {theme[mat].textureUrl && <TextureSquare src={theme[mat].textureUrl} />}
            <ColorSquare
              style={{ opacity: theme[mat].textureUrl ? 0.5 : 1 }}
              background={theme[mat].colorString}
            />
          </MaterialSquare>
        </MyToolTip>
      ))}

      <Dialog
        open={displayDialog}
        title="Irreversible Action"
        message="This action will paint your whole exhibition. Are you sure you want to apply the theme?"
        type="warning"
        onConfirm={() => {
          setTheme(theme);
          setDisplayDialog(false);
        }}
        onReject={() => {
          setDisplayDialog(false);
        }}
      />
    </HorizontalItems>
  ) : (
    <div></div>
  );
};

const mapState = (state, { exhibitionId }) => ({
  currentUser: getAuthUser(state),
  selectedTheme: getUIProperty(state, `exhibitions/${exhibitionId}/theme`),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  setTheme: theme => dispatch(setUIProperty(`exhibitions/${exhibitionId}/theme`, theme)),
});

const Theme = compose(withState(mapState), withDispatch(mapDispatch))(ThemeView);

export default Theme;
