import React from 'react';

import DefaultHeader from './RedesignedHeader';
// import DefaultHeader from './DefaultHeader';
import DefaultFooter from './RedesignedFooter';
import BannerRenderer from '../banners/BannerRenderer';
import ContributeModal from '../modals/ContributeModal';

export const DefaultLayoutPresenter = ({ children, exhibitionId, exhibition }) => (
  <div className="layout default">
    <DefaultHeader exhibitionId={exhibitionId} exhibition={exhibition} />
    {/* <DefaultHeader /> */}
    <main className="layout-body">{children}</main>
    <BannerRenderer />
    <DefaultFooter />
  </div>
);

const DefaultLayout = DefaultLayoutPresenter;

export default DefaultLayout;
