import React from 'react';
import { __ } from 'artsteps2-common';
import Uploader from './Uploader';
import PolySelect from './PolySelect';
import FlickrSelect from './FlickrSelect';
import MultiSelectInput from './MultiSelectInput';
import TagsInput from './TagsInput';

const FIELDS = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight'];

const FieldView = ({
  id = 'field',
  name = 'field',
  hint = '',
  type = 'text',
  value: rawValue,
  default: defaultValue,
  placeholder = '',
  label = '',
  readOnly = false,
  required = false,
  options = [],
  onChange: onRawValueChange = () => false,
  fromFormValue = value => value,
  toFormValue = value => value,
  artifactId = 'new',
  ...props
}) => {
  const onChange = event =>
    onRawValueChange(
      Object.assign(event, {
        target: {
          type: event.target.type,
          name: event.target.name,
          value: toFormValue(event.target.value, event, onRawValueChange),
          checked: toFormValue(event.target.checked, event, onRawValueChange),
        },
      }),
    );
  const value = fromFormValue(rawValue === undefined ? defaultValue : rawValue);
  const inputProps = {
    ...props,
    id,
    name,
    hint,
    onChange,
    readOnly,
    required,
    placeholder: placeholder || label || __(name),
  };
  if (typeof type === 'object') {
    return (
      <div className={`field ${required ? 'required' : ''}`}>
        {label === null ? null : <label htmlFor={id}>{label || __(name)}</label>}
        {hint === '' ? null : <span className="label-hint">({__(hint)})</span>}
        <div className={`ui ${FIELDS[Object.keys(type).length]} fields`}>
          {Object.keys(type).map(field => (
            <Field
              key={field}
              onChange={onChange}
              id={`${id}.${field}`}
              name={`${name}.${field}`}
              label={null}
              value={(value || {})[field]}
              {...type[field]}
            />
          ))}
        </div>
      </div>
    );
  }

  if (type === 'chips-input') {
    return <TagsInput label={label} hint={hint} artifactId={artifactId} />;
  }

  if (type === 'textarea') {
    return (
      <div className={`field ${required ? 'required' : ''}`}>
        {label === null ? null : <label htmlFor={id}>{label || __(name)}</label>}
        {hint === '' ? null : <span className="label-hint">({__(hint)})</span>}
        <textarea rows={3} className="ui input" value={value || ''} {...inputProps} />
      </div>
    );
  }

  if (type === 'select') {
    return (
      <div className={`field ${required ? 'required' : ''}`}>
        {label === null ? null : <label htmlFor={id}>{label || __(name)}</label>}
        {hint === '' ? null : <span className="label-hint">({__(hint)})</span>}
        <select className="ui dropdown" value={value || ''} {...inputProps}>
          {Object.keys(options).map(option => (
            <option key={option} value={option}>
              {options[option]}
            </option>
          ))}
        </select>
      </div>
    );
  }

  if (type === 'multiselect') {
    return (
      <div className={`field ${required ? 'required' : ''}`}>
        {label === null ? null : <label htmlFor={id}>{label || __(name)}</label>}
        {hint === '' ? null : <span className="label-hint">({__(hint)})</span>}
        <MultiSelectInput options={options} value={value || []} {...inputProps} />
      </div>
    );
  }

  if (type === 'image') {
    return <Uploader accept="image/*" type="image" label={label} value={value} {...inputProps} />;
  }

  if (type === 'audio') {
    return <Uploader accept="audio/*" type="audio" label={label} value={value} {...inputProps} />;
  }

  if (type === 'video') {
    return <Uploader accept="video/*" type="video" label={label} value={value} {...inputProps} />;
  }

  if (type === 'obj') {
    return <Uploader type="obj" label={label} value={value} {...inputProps} />;
  }

  if (type === 'checkbox') {
    return (
      <div className={`field ${required ? 'required' : ''}`}>
        <div className="ui checkbox">
          <input type="checkbox" checked={!!value} {...inputProps} />
          {label === null ? null : <label htmlFor={id}>{label || __(name)}</label>}
          {hint === '' ? null : <span className="label-hint">({__(hint)})</span>}
        </div>
      </div>
    );
  }

  if (type === 'toggle') {
    return (
      <div className={`field ${required ? 'required' : ''}`}>
        <div className="ui toggle checkbox">
          <input type="checkbox" checked={!!value} {...inputProps} />
          {label === null ? null : <label htmlFor={id}>{label || __(name)}</label>}
          {hint === '' ? null : <span className="label-hint">({__(hint)})</span>}
        </div>
      </div>
    );
  }

  if (type === 'poly') {
    return <PolySelect {...inputProps} value={value} label={label} required={required} />;
  }

  if (type === 'flickr') {
    return <FlickrSelect {...inputProps} value={value} label={label} required={required} />;
  }

  return (
    <div className={`field ${required ? 'required' : ''}`}>
      {label === null ? null : <label htmlFor={id}>{label || __(name)}</label>}
      {hint === '' ? null : <span className="label-hint">({__(hint)})</span>}
      <input className="ui input" type={type} value={value || ''} {...inputProps} />
    </div>
  );
};

const Field = FieldView;
export default Field;
