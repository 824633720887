import qs from 'qs';
import config from 'artsteps2-config/public.json';

import {
  authLogout,
  storeCredentials,
  getStoredCredentials,
  isTokenExpiring,
  authRenew,
  AUTH_NAMESPACE,
  AUTH_ACTION_RENEW,
} from './auth';

import {
  API_LIMIT,
  API_CACHE_TIMEOUT,
  AUTH_STATUS,
  getAuthStatus,
  getAuthToken,
  getAuthUser,
  getApiMeta,
  getApiResource,
  getUIProperty,
} from '../reducers';

const API_ACTION_REQUEST_SUFFIX = '_REQUEST';
const API_ACTION_SUCCESS_SUFFIX = '_SUCCESS';
const API_ACTION_FAILURE_SUFFIX = '_FAILURE';

export const API_NAMESPACE = 'api';
const API_ACTION_GET = 'GET';
export const API_ACTION_GET_REQUEST = `${API_ACTION_GET}${API_ACTION_REQUEST_SUFFIX}`;
export const API_ACTION_GET_SUCCESS = `${API_ACTION_GET}${API_ACTION_SUCCESS_SUFFIX}`;
export const API_ACTION_GET_FAILURE = `${API_ACTION_GET}${API_ACTION_FAILURE_SUFFIX}`;
const API_ACTION_HEAD = 'HEAD';
export const API_ACTION_HEAD_REQUEST = `${API_ACTION_HEAD}${API_ACTION_REQUEST_SUFFIX}`;
export const API_ACTION_HEAD_SUCCESS = `${API_ACTION_HEAD}${API_ACTION_SUCCESS_SUFFIX}`;
export const API_ACTION_HEAD_FAILURE = `${API_ACTION_HEAD}${API_ACTION_FAILURE_SUFFIX}`;
const API_ACTION_PATCH = 'PATCH';
export const API_ACTION_PATCH_REQUEST = `${API_ACTION_PATCH}${API_ACTION_REQUEST_SUFFIX}`;
export const API_ACTION_PATCH_SUCCESS = `${API_ACTION_PATCH}${API_ACTION_SUCCESS_SUFFIX}`;
export const API_ACTION_PATCH_FAILURE = `${API_ACTION_PATCH}${API_ACTION_FAILURE_SUFFIX}`;
const API_ACTION_POST = 'POST';
export const API_ACTION_POST_REQUEST = `${API_ACTION_POST}${API_ACTION_REQUEST_SUFFIX}`;
export const API_ACTION_POST_SUCCESS = `${API_ACTION_POST}${API_ACTION_SUCCESS_SUFFIX}`;
export const API_ACTION_POST_FAILURE = `${API_ACTION_POST}${API_ACTION_FAILURE_SUFFIX}`;
const API_ACTION_DELETE = 'DELETE';
export const API_ACTION_DELETE_REQUEST = `${API_ACTION_DELETE}${API_ACTION_REQUEST_SUFFIX}`;
export const API_ACTION_DELETE_SUCCESS = `${API_ACTION_DELETE}${API_ACTION_SUCCESS_SUFFIX}`;
export const API_ACTION_DELETE_FAILURE = `${API_ACTION_DELETE}${API_ACTION_FAILURE_SUFFIX}`;

export const API_META_HEADERS = ['x-total-count', 'x-jwt'];

export const API_URI = `${config.api.hostname}${config.api.path}`;

const parseHeaderKey = header =>
  header
    .substring(2)
    .toLowerCase()
    .replace(/-([a-z])/g, g => g[1].toUpperCase());

const parseHeaderValue = header => (isFinite(parseFloat(header)) ? parseFloat(header) : header);

const parseHeaderMeta = headers =>
  Array.from(headers.entries()).reduce(
    (meta, pair) => ({
      ...meta,
      ...(API_META_HEADERS.includes(pair[0])
        ? { [parseHeaderKey(pair[0])]: parseHeaderValue(pair[1]) }
        : {}),
    }),
    {},
  );

const createAPIAction = ({ action, path, query = {}, body, method, authToken }) => (
  dispatch,
  getState,
) => {
  const limit = ((query.page || {}).limit || API_LIMIT) + ((query.page || {}).offset || 0);

  const { fetchedAt: cachedAt = 0, limit: cachedLimit = API_LIMIT } = getApiMeta(
    getState(),
    path,
    query,
  );

  if (method === 'GET' && Date.now() - cachedAt < API_CACHE_TIMEOUT && cachedLimit >= limit) {
    return Promise.resolve({
      response: getApiResource(getState(), path, query),
    });
  }

  dispatch({ type: `${action}${API_ACTION_REQUEST_SUFFIX}`, query });

  const token = authToken || getAuthToken(getState());
  const uri = `${API_URI}/${path}?${qs.stringify(query)}`;
  const headers = new Headers({
    Accept: 'application/json',
    ...(token ? { Authorization: `bearer ${token}` } : {}),
    ...(body !== undefined ? { 'Content-Type': 'application/json; encoding=utf8' } : {}),
  });

  if (
    isTokenExpiring(token) &&
    getAuthStatus(getState()) !== AUTH_STATUS.REQUEST_REFRESH_AUTHENTICATION
  ) {
    dispatch(authRenew(getAuthUser(getState())));
  }

  return fetch(uri, { headers, body, method })
    .then(res => {
      if (res.status === 401) {
        dispatch(authLogout());
      }

      const meta = {
        ...parseHeaderMeta(res.headers),
        fetchedAt: Date.now(),
        limit,
      };

      return res
        .json()
        .catch(() => Promise.resolve({}))
        .then(response => {
          if (response.error) {
            throw new Error(response.error);
          }

          if (String(res.status)[0] !== '2') {
            throw new Error(res.statusText);
          }

          let authStorage = getStoredCredentials();
          const user = getAuthUser(getState());

          storeCredentials({ ...authStorage, user });

          if (!['HEAD', 'GET'].includes(method.toUpperCase()) && meta.jwt) {
            storeCredentials({ token: meta.jwt, type: 'bearer', user });
            dispatch({
              type: `${AUTH_NAMESPACE}/${AUTH_ACTION_RENEW}`,
              response: {
                token: meta.jwt,
                user,
              },
            });
          }
          return Promise.resolve(
            dispatch({
              type: `${action}${API_ACTION_SUCCESS_SUFFIX}`,
              response,
              meta,
              query,
            }),
          );
        });
    })
    .catch(error =>
      Promise.resolve(
        dispatch({
          type: `${action}${API_ACTION_FAILURE_SUFFIX}`,
          response: { error: error.message, stack: error.stack },
          query,
        }),
      ),
    );
};

const createActionPath = (path, action) => `${API_NAMESPACE}/${path.replace(/^\//, '')}/${action}`;

export const apiGET = (path, query, opts) => (dispatch, getState) => {
  let endpointPath = path;
  if (endpointPath === 'exhibitions' && getUIProperty(getState(), 'isPrivateSpace')) {
    endpointPath = 'exhibitionsPrivate';
  }

  return dispatch(
    createAPIAction({
      ...opts,
      action: createActionPath(path, API_ACTION_GET),
      payload: { query },
      method: 'GET',
      path: endpointPath,
      query,
    }),
  );
};

export const apiHEAD = (path, query, opts) =>
  createAPIAction({
    ...opts,
    action: createActionPath(path, API_ACTION_HEAD),
    payload: { query },
    method: 'HEAD',
    path,
    query,
  });

export const apiPATCH = (path, data, query, opts) =>
  createAPIAction({
    ...opts,
    action: createActionPath(path, API_ACTION_PATCH),
    method: 'PATCH',
    body: JSON.stringify(data),
    path,
    query,
  });

export const apiDELETE = (path, query, opts) =>
  createAPIAction({
    ...opts,
    action: createActionPath(path, API_ACTION_DELETE),
    method: 'DELETE',
    path,
    query,
  });

export const apiPOST = (path, data, query, opts) =>
  createAPIAction({
    ...opts,
    action: createActionPath(path, API_ACTION_POST),
    method: 'POST',
    body: JSON.stringify(data),
    path,
    query,
  });
