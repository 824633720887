import React, { useEffect, useState } from 'react';
import { __ } from 'artsteps2-common';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LockIcon from '@material-ui/icons/Lock';
import Skeleton from '@material-ui/lab/Skeleton';
import Dialog from '../../generic/Dialog';
import ExhibitionSettings from './ExhibitionSettings';
import ExhibitionBookMarks from '../viewer/ExhibitionBookMarks';
import utils from '../../../utils';
import {
  HiddenContent,
  MediaContainer,
  Thumbnail,
  ExhibitionInfo,
  ViewsAndCreated,
  Gradient,
  SkeletonCard,
} from './ExhibitionStyled';

import { compose, withState, withDispatch } from '../../../enhancers';
import { apiDELETE, setUIProperty, addMessage, setLocation } from '../../../actions';
import { API_KEYS, getAuthUser, getUIProperty, getAuthToken } from '../../../reducers';

import { LabelBox } from '../../../styles/GenericStyled';
import useWindowSize from '../../../hooks/windowSize';

export const ExhibitionPreviewView = ({
  exhibition = {},
  user: { profile = {}, _id: userId } = {},
  imgRefLocalState,
  isUsers,
  fetchImageFromExhibitionProfileUser = false,
  onRedirect = () => Promise.resolve(false),
  onAddMessage = () => Promise.resolve(false),
  onCloseDialog = () => Promise.resolve(false),
  onDeleteExhibition = () => Promise.resolve(false),
  dialogVisible = false,
  profilePage = '/profile',
  user,
  categoryList,
  ready,
  onSetShowDescription,
  viewPage,
}) => {
  useEffect(() => {
    onSetShowDescription();
  }, []);

  const size = useWindowSize();

  const redirect = e => {
    if (e.target.tagName === 'DIV' || e.target.tagName === 'B') {
      onRedirect(
        fetchImageFromExhibitionProfileUser
          ? `/view/${exhibition._id}?currentUser`
          : `/view/${exhibition._id}`,
      );
    }
  };

  const [imgHeight, setImgHeight] = useState('260px');

  useEffect(() => {
    // Caclulate height to maintain our aspect ratio
    setImgHeight(
      `${viewPage && size.width >= 1280 ? ((size.width * 0.166667) / 1.7714).toFixed(0) : 260}px`,
    );
  }, [size.width]);

  return (
    <>
      <Dialog
        type="warning"
        open={dialogVisible}
        title={__('exhibition_deletion')}
        message={__('confirm_exhibition_deletion', exhibition)}
        onConfirm={e => {
          onDeleteExhibition().then(
            ({ response }) =>
              response.error && onAddMessage({ type: 'error', title: __(response.error) }),
          );
        }}
        onReject={e => {
          e.preventDefault();
          onCloseDialog();
        }}
      />
      <Skeleton
        style={{
          display: `${!ready ? 'block' : 'none'}`,
          height: imgHeight,
          width: '420px',
        }}
        variant="rect"
        viewpage={viewPage ? 1 : 0}
      />

      <MediaContainer
        style={{
          display: `${ready ? 'block' : 'none'}`,
          height: imgHeight,
        }}
        onClick={redirect}
        id={`media-container-${exhibition._id}`}
        viewpage={viewPage ? 1 : 0}
      >
        <Gradient />
        <Thumbnail id="thumbnail" alt="" ref={imgRefLocalState} />

        <ExhibitionInfo>
          <HiddenContent id="hidden-content">
            {isUsers && <ExhibitionSettings exhibition={exhibition} />}
            {false && !isUsers && <ExhibitionBookMarks />}
          </HiddenContent>

          {/* <HiddenContent id="hidden-content">
            <ExhibitionDescription>{exhibition.description}</ExhibitionDescription>
          </HiddenContent> */}
          <ViewsAndCreated id="viewsETC">
            {!exhibition.published && (
              <span>
                <LockIcon />
                <b>{__('Private')}</b>
              </span>
            )}
            {exhibition.published && (
              <>
                <span>
                  <VisibilityIcon />
                  <b>{exhibition.visits ? exhibition.visits : 0}</b>
                </span>

                {exhibition.publishedAt && (size.width <= 1280 || size.width >= 1555) && (
                  <>
                    <span>
                      <AccessTimeIcon />
                      <b>{utils.date.fromNow(new Date(exhibition.publishedAt))}</b>
                    </span>
                  </>
                )}

                <span>
                  <FavoriteBorderIcon />
                  <b>{((exhibition.likes || {})[API_KEYS.META] || {}).totalCount || 0} </b>
                </span>
              </>
            )}

            {/* <HiddenContent
              id="hidden-content"
              style={{
                marginLeft: 'auto',
                marginRight: '10px',
              }}
            >
              <b>More</b>
              <ArrowForwardIcon onClick={redirect} />
            </HiddenContent> */}
          </ViewsAndCreated>
        </ExhibitionInfo>
      </MediaContainer>
    </>
  );
};

const mapState = (state, { exhibition = {} }) => ({
  currentUser: getAuthUser(state),
  dialogVisible: getUIProperty(state, `exhibitions/${exhibition._id}/dialog`),
  ready: getUIProperty(state, `exhibitions/${exhibition._id}/ready`),
  token: getAuthToken(state),
});
const mapDispatch = (dispatch, { exhibition = {} }) => ({
  onDeleteExhibition: () => dispatch(apiDELETE(`exhibitionsUserProfile/${exhibition._id}`)),
  onCloseDialog: () => dispatch(setUIProperty(`exhibitions/${exhibition._id}/dialog`, false)),
  onSetShowDescription: () => dispatch(setUIProperty('showDescription', true)),
  onAddMessage: message => dispatch(addMessage(message)),
  onRedirect: location => dispatch(setLocation(location)),
});

const ExhibitionPreview = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionPreviewView);
export default ExhibitionPreview;
