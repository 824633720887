import React from 'react';
import { apiPOST, apiHEAD } from '../../../actions';
import publicConfig from 'artsteps2-config/public.json';
import { getApiMeta, getApiResource, getAuthUser, getUIProperty } from '../../../reducers';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import axios from 'axios';

export const ExhibitionViewsView = ({ totalViews = 0 }) => (
  <React.Fragment>{totalViews}</React.Fragment>
);

const createQuery = exhibition => ({ filter: { exhibition } });

const mapState = (state, { exhibitionId }) => ({
  totalViews:
    (getApiMeta(state, 'views', createQuery(exhibitionId)).totalCount || 0) +
    (getApiResource(state, `exhibitions/${exhibitionId}`)._v1Views || 0),
  currentUser: getAuthUser(state) || getUIProperty(state, 'fakeCurrentUser'),
});

const mapDispatch = (dispatch, { exhibitionId }) => ({
  onFetchViews: () => dispatch(apiHEAD('views', createQuery(exhibitionId))),
  onAddView: () => dispatch(apiPOST('views', { exhibition: exhibitionId })),
  onWPVisit: visitData => dispatch(apiPOST(`wp/visit`, visitData)),
});

const lifecycleMap = {
  onDidMount: ({ onFetchViews, onAddView, exhibitionId, currentUser = {}, onWPVisit }) => {
    if (currentUser._id)
      onWPVisit({
        visitor: currentUser._id,
        exhibition: exhibitionId,
      });

    return onAddView().then(() => onFetchViews());
  },
};

const ExhibitionViews = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionViewsView);

export default ExhibitionViews;
