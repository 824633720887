import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components';

export const FaqEditorDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 80px 0px;

  & > div > span {
    margin-bottom: 20px;
  }
`;

export const FAQDiv = styled.div`
  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}

${props =>
  props.mycolor &&
  css`
    border-color: ${props.mycolor};
    border-style: solid;
    border-width: 0px 0px 0px 4px;
  `}



${props =>
  props.radius &&
  css`
    border-radius: ${props.radius};
  `}
`;

export const ItemsWithMargin = styled.div`
  & > * {
    margin-left: 25px;
  }
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

export const AdminButtonPanel = styled.div`
  margin-bottom: 10px;
  & > * {
    margin-right: 10px;
  }
  display: flex;
  align-items: center;
  vertical-align: middle;
`;

export const AdminButton = styled(Button)`
  font-family: 'Muli', Regular;
  color: white;
  background: black;

  ${props =>
    props.mycolor &&
    css`
      color: ${props.mycolor};
    `}
  }

  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}
  }
`;
