import React from 'react';
import { __ } from 'artsteps2-common';
import Loader from '../../generic/Loader';
import Dialog from '../../generic/Dialog';
import Form from '../../generic/forms/Form';
import { compose, withState, withDispatch, withLifecycle } from '../../../enhancers';
import { API_STATUS, getApiStatus, getForm, getAuthUser, getUIProperty } from '../../../reducers';
import {
  addMessage,
  clearMessages,
  apiGET,
  apiPOST,
  apiPATCH,
  apiDELETE,
  setFormData,
  setUIProperty,
  clearFormData,
} from '../../../actions';

const IMAGE_QUALITY = 0.8;
const IMAGE_HEIGHT = 128;
const IMAGE_WIDTH = 128;

const FILE_QUALITY = 0.8;
const FILE_WIDTH = 768;
const FILE_HEIGHT = 512;
const FILE_TYPE = 'image/png';

export const TextureEditorView = ({
  textureId,
  form = { data: {} },
  saving = false,
  ready = true,
  removeDialogOpen = false,
  onUpsert = () => Promise.resolve({ response: { error: 'error' } }),
  onSave = () => Promise.resolve(false),
  onClose,
  onAddMessage = () => Promise.resolve(false),
  onClearMessages = () => Promise.resolve(false),
  onRemoveDialogOpen = () => Promise.resolve(false),
  onRemoveDialogClose = () => Promise.resolve(false),
  onRemove = () => Promise.resolve({ response: { error: 'error' } }),
}) => {
  const mapFormData = data => ({
    ...data,
    file: data.file && {
      bin: data.file.bin && data.file.bin.content && data.file.bin,
      uri: data.file.uri,
    },
    image: (data.file && data.file.image) || data.image,
  });

  const onSubmit = () =>
    onUpsert(mapFormData(form.data)).then(({ response }) =>
      response.error
        ? onAddMessage({ title: __(response.error), type: 'warning' })
        : onClearMessages().then(() => onSave(response)),
    );

  return (
    <div className="texture-info">
      <h2>{__(textureId && textureId !== 'new' ? 'edit_texture' : 'add_texture')}</h2>
      <Form
        form={form}
        onSubmit={onSubmit}
        fields={[
          {
            file: {
              type: 'image',
              label: __('texture'),
              required: true,
              preview: () => textureId && `/api/textures/${textureId}/file/bin`,
              width: FILE_WIDTH,
              height: FILE_HEIGHT,
              quality: FILE_QUALITY,
              mimeType: FILE_TYPE,
              thumbnail: {
                name: 'image',
                width: IMAGE_WIDTH,
                height: IMAGE_HEIGHT,
                quality: IMAGE_QUALITY,
              },
            },
          },
          {
            title: { type: 'text', required: true, label: __('title') },
          },
        ]}
        buttons={[
          ...(textureId && textureId !== 'new'
            ? [
                {
                  colour: 'delete',
                  label: __('remove'),
                  icon: 'trash',
                  onClick: onRemoveDialogOpen,
                  type: 'button',
                },
              ]
            : []),
          {
            colour: 'secondary',
            label: __('save'),
            icon: 'save',
            disabled:
              !form.data.file ||
              (!form.data.file.bin && !form.data.file.uri) ||
              (!form.data.image && !form.data.file.image) ||
              !form.data.title,
          },
        ]}
      />
      {saving && <Loader message={`${__('saving')} ${form.data.title}`} />}
      {saving || ready || <Loader />}
      <Dialog
        open={removeDialogOpen}
        title={__('texture_deletion', form.data)}
        message={__('confirm_texture_deletion', form.data)}
        type="warning"
        onReject={onRemoveDialogClose}
        onConfirm={() =>
          onRemoveDialogClose()
            .then(() => onRemove())
            .then(
              ({ response: { error } = {} }) =>
                error && onAddMessage({ type: 'error', title: __(error) }),
            )
        }
      />
    </div>
  );
};

const createQuery = () => ({});

const mapState = (state, { textureId }) => {
  const currentUser = getAuthUser(state);
  return {
    currentUser,
    form: getForm(state, `texture:${textureId}`),
    ready:
      getApiStatus(state, `textures/${textureId}`) === API_STATUS.IDLE &&
      getApiStatus(state, 'textures', createQuery(currentUser)) === API_STATUS.IDLE,
    saving:
      getApiStatus(state, `textures/${textureId}`) === API_STATUS.PATCH ||
      getApiStatus(state, 'textures', createQuery(currentUser)) === API_STATUS.POST,
    removeDialogOpen: getUIProperty(state, `textures/${textureId}/removeDialog`),
  };
};

const mapDispatch = (dispatch, { currentUser, textureId }) => ({
  onAddMessage: message => dispatch(addMessage(message, 'textures')),
  onClearMessages: () => dispatch(clearMessages('textures')),
  onUpsert: data =>
    textureId && textureId !== 'new'
      ? dispatch(apiPATCH(`textures/${textureId}`, data))
      : dispatch(apiPOST('textures', data, createQuery(currentUser))),
  onClearFormData: () => dispatch(clearFormData(`texture:${textureId}`)),
  onSetFormData: data => dispatch(setFormData(`texture:${textureId}`, data)),
  onFetchTexture: () =>
    textureId && textureId !== 'new'
      ? dispatch(apiGET(`textures/${textureId}`))
      : Promise.resolve({}),
  onRemoveDialogOpen: () => dispatch(setUIProperty(`textures/${textureId}/removeDialog`, true)),
  onRemoveDialogClose: () => dispatch(setUIProperty(`textures/${textureId}/removeDialog`, false)),
  onRemove: () => dispatch(apiDELETE(`textures/${textureId}`)),
});

const onInitialization = ({ onClearFormData, onFetchTexture, onSetFormData }) =>
  onClearFormData()
    .then(() => onFetchTexture())
    .then(({ response: texture }) => onSetFormData(texture));

const lifecycleMap = {
  onDidMount: props => onInitialization(props),
  onDidUpdate: ({ textureId }, props) => textureId !== props.textureId && onInitialization(props),
};

const TextureEditor = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(TextureEditorView);

export default TextureEditor;
