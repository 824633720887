import { mergeDeepRight, path as pathAssoc, assocPath } from 'ramda';
import publicConfig from 'artsteps2-config/public.json';
import { v4 as uuidv4 } from 'uuid';
import { setCookie } from '../utils/cookies';

export const UI_NAMESPACE = 'ui';
export const UI_ACTION_SET = 'SET';
export const UI_ACTION_MERGE = 'MERGE';

const KEY_UISETTINGS = 'ui';

export const setUIData = (path, data, persist = false) => dispatch => {
  if (persist) {
    storeSettings(path, data, true);
  }
  return Promise.resolve(
    dispatch({
      type: `${UI_NAMESPACE}/${path}/${UI_ACTION_MERGE}`,
      data,
    }),
  );
};

export const setUIProperty = (path, data, persist = false) => dispatch => {
  if (persist) {
    storeSettings(path, data, false);
  }
  return Promise.resolve(
    dispatch({
      type: `${UI_NAMESPACE}/${path}/${UI_ACTION_SET}`,
      data,
    }),
  );
};

const initGoogleAnalytics = () => {
  window.gtag('js', new Date());
  window.gtag('config', publicConfig.googleAnalytics.trackingId);
  window.gtag('config', publicConfig.googleAnalytics.trackingId, { anonymize_ip: true });
};

export const restoreSettings = () => dispatch => {
  const settings = getStoredSettings() || {};

  // old cookie compatibility
  // if CookieConsent=true convert to new cookie else delete
  const cookieConsent = document.cookie.split(';').filter(c => c.includes('CookieConsent'));

  if (cookieConsent.length !== 0 && cookieConsent[0].split('=')[1] === 'true') {
    document.cookie = 'CookieConsent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    setCookie(
      'CookieScriptConsent',
      `{"action":"accept","categories":"[\\"performance\\",\\"targeting\\",\\"functionality\\"]","key":"${uuidv4()}"}`,
      30,
    );
  }

  if (cookieConsent.length !== 0 && cookieConsent[0].split('=')[1] === 'false') {
    document.cookie = 'CookieConsent= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  }

  // cookie script 3rd party
  // categories: performance, targeting, functionality
  const script = document.createElement('script');
  script.src = `//cookie-script.com/s/${publicConfig.cookieScript.jsId}.js`;
  script.type = 'text/javascript';
  script.charset = 'UTF-8';
  document.head.appendChild(script);

  const cookiescriptConsentCookie = document.cookie
    .split(';')
    .filter(c => c.includes('CookieScriptConsent'));

  if (cookiescriptConsentCookie.length !== 0) {
    try {
      const cookieScriptConsent = JSON.parse(cookiescriptConsentCookie[0].split('=')[1]);
      // performance consent
      if (
        cookieScriptConsent.action === 'accept' &&
        cookieScriptConsent.categories.includes('performance')
      ) {
        initGoogleAnalytics();
      }
    } catch (e) {
      initGoogleAnalytics();
    }
  } else {
    initGoogleAnalytics();
  }

  return Promise.all(Object.keys(settings).map(path => setUIData(path, settings[path])(dispatch)));
};

const getStoredSettings = () => {
  try {
    return JSON.parse(localStorage.getItem(KEY_UISETTINGS));
  } catch (ignore) {
    return null;
  }
};

const storeSettings = (path, settings = {}, merge = true) => {
  const currentItem = getStoredSettings() || {};
  const item = assocPath(
    path.split('/'),
    merge ? mergeDeepRight(pathAssoc(path.split('/'), currentItem), settings) : settings,
    currentItem,
  );
  try {
    localStorage.setItem(KEY_UISETTINGS, JSON.stringify(item));
    return getStoredSettings();
  } catch (ignore) {
    return null;
  }
};
