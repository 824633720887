import React from 'react';
import { __ } from 'artsteps2-common';
import publicConfig from 'artsteps2-config/public.json';
import ArtifactInfoEditor from './ArtifactInfoEditor';
import { withState, compose } from '../../../enhancers';
import { getUIProperty, getAuthUser } from '../../../reducers';

const FILE_WIDTH = publicConfig.artifact.image.fileWidth;
const FILE_HEIGHT = publicConfig.artifact.image.fileHeight;
const IMAGE_WIDTH = publicConfig.artifact.image.imageWidth;
const IMAGE_HEIGHT = publicConfig.artifact.image.imageHeight;
const IMAGE_QUALITY = publicConfig.artifact.image.quality;

const SUBSCRIBER_FILE_WIDTH = publicConfig.artifact.image.subscriberFileWidth;
const SUBSCRIBER_FILE_HEIGHT = publicConfig.artifact.image.subscriberFileHeight;
const SUBSCRIBER_IMAGE_QUALITY = publicConfig.artifact.image.subscriberQuality;

export const ImageEditorView = ({
  form = { data: {} },
  ready = true,
  onSubmit,
  onClose,
  onRemove,
  isPrivateSpace,
  currentUser,
  artifactId,
}) => (
  <ArtifactInfoEditor
    artifactId={artifactId}
    form={form}
    ready={ready}
    onSubmit={onSubmit}
    onClose={onClose}
    onRemove={onRemove}
    dimensions={['width', 'height']}
    dimensionsInches={['width', 'height']}
    saveDisabled={
      !form.data.files ||
      !form.data.files.length > 0 ||
      (!form.data.files[0].bin && !form.data.files[0].uri) ||
      (!form.data.image && !form.data.files[0].image)
    }
    extraFields={[
      {
        files: {
          type: 'image',
          label: __('image'),
          required: true,
          multiple: false,
          asArray: true,
          preview: (file, idx) =>
            form.data._id ? `/api/artifacts/${form.data._id}/files/${idx}/bin` : '',
          width:
            isPrivateSpace || (currentUser && currentUser.subscribe)
              ? SUBSCRIBER_FILE_WIDTH
              : FILE_WIDTH,
          height:
            isPrivateSpace || (currentUser && currentUser.subscribe)
              ? SUBSCRIBER_FILE_HEIGHT
              : FILE_HEIGHT,
          quality: isPrivateSpace ? SUBSCRIBER_IMAGE_QUALITY : IMAGE_QUALITY,
          mimeType: form.data.model && form.data.model.hasCanvas ? 'image/jpeg' : 'image/png',
          thumbnail: {
            name: 'image',
            mimeType: 'image/jpeg',
            width: IMAGE_WIDTH,
            height: IMAGE_HEIGHT,
          },
        },
        'model.hasCanvas': {
          type: 'toggle',
          default: true,
          label:
            form.data.model && form.data.model.hasCanvas
              ? __('non_transparent_image')
              : __('transparent_image'),
          hint:
            form.data.model && form.data.model.hasCanvas
              ? __('non_transparent_image_hint')
              : __('transparent_image_hint'),
        },
      },
    ]}
  />
);

const mapState = state => ({
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
  currentUser: getAuthUser(state),
});

const ImageEditor = compose(withState(mapState))(ImageEditorView);

export default ImageEditor;
