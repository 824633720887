import React, { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';
import colors from '../styles/colors';
import logo from '../styles/images/logo.png';
import { ReactComponent as Send } from '../styles/images/send-24px.svg';
import { apiPOST, addMessage, setUIProperty } from '../actions';
import { compose, withState, withDispatch, withLifecycle } from '../enhancers';
import { getAuthUser, getUIProperty } from '../reducers';
import * as PrivateComponent from '../styles/PrivateSpacesPageStyled';
import DefaultLayout from '../components/layouts/DefaultLayout';
import useWindowSize from '../hooks/windowSize';
import PSbanner from '../components/privateSpaces/PSbanner';
import { BodyWithPadding, HorizontalItems, Text, BigNumber } from '../styles/GenericStyled';
import image1 from '../styles/images/privateSpaces/3d-space-1.png';
import image2 from '../styles/images/privateSpaces/3d-space-2.png';
import image3 from '../styles/images/privateSpaces/3d-space-3.png';

const { getNames } = require('country-list');

const bannerText = [
  'Custom 3D Spaces',
  'Do you need something more than our prebuilt exhibition spaces?',
  'Our team of 3D artists, curators and designers are here to help your work stand out!',
];

const infos = [
  {
    text: 'Provide us with your flour-plan. It could be a skp, dwg or even a pdf file.',
    src: image1,
  },
  { text: 'Send us real images for lighting and matterials', src: image2 },
  { text: 'Your custom 3D space will be ready in your Artsteps account.', src: image3 },
];

const breakPoint = 1515;

const SectionTitle = ({ text, idx, imageSource }) => {
  const size = useWindowSize();
  const [direction, setDirection] = useState('row');
  useEffect(() => {
    if (size.width < 1280) setDirection('column');
    else setDirection(idx % 2 === 1 ? 'row' : 'row-reverse');
  }, [size.width, setDirection]);

  return (
    <Grid item style={{ marginBottom: size.width < 1280 ? '30px' : '' }}>
      <Grid container justify="space-evenly" alignItems="center" direction={direction} spacing={2}>
        <Grid item xs={12} lg={6}>
          <HorizontalItems style={{ flexDirection: `${size.width < 1280 ? 'column' : 'row'}` }}>
            <BigNumber
              style={{
                marginBottom: size.width < 1280 ? '20px' : '',
                marginRight: '10px',
                fontSize: size.width < 1280 ? '50px' : '70px',
              }}
            >
              {`${idx}.`}
            </BigNumber>
            <Text
              align={`${size.width < 1280 ? 'center' : 'left'}`}
              type={`${size.width < 600 ? 'h7' : 'h6'}`}
            >
              {text}
            </Text>
          </HorizontalItems>
        </Grid>
        <Grid item xs={12} lg={6}>
          <img alt="" width="100%" height="100%" style={{ maxHeight: '320px' }} src={imageSource} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const isDisabled = state => {
  let disabled = false;
  for (const [key, value] of Object.entries(state)) {
    if (key !== 'company' && value === '') disabled = true;
  }
  return disabled;
};

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 15px;
  top: 5px;
  display: none;
  ${props =>
    props.visible &&
    css`
      display: flex;
    `}
`;

const StyledMenu = withStyles({
  paper: {
    border: '3px solid #ffffff',
    backgroundColor: 'white',
    borderRadius: '5px',
    minWidth: '300px',
    width: '357px',
  },
  list: {
    padding: '2px 5px',
  },
})(props => <Menu {...props} />);

const initialState = {
  username: '',
  email: '',
  country: '',
  company: '',
  licence: '',
};

const licences = ['Commercial Licence', 'Academic Licence', 'Other/Not sure'];

const CustomSpace3DPageView = props => {
  const [icon, setIcon] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [inputData, setInputData] = useState({ ...initialState });
  const [countryInput, setCountryInput] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const sections = infos.map((info, idx) => (
    <SectionTitle key={idx} text={info.text} imageSource={info.src} idx={idx + 1} />
  ));

  useEffect(() => {
    document.title = `Evea | Custom 3D Space`;
  }, []);

  const size = useWindowSize();

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setInputData({ ...initialState });
  };

  const handleSubmit = e => {
    props.onCreateContactForm({ ...inputData }).then(({ response }) => {
      if (!response.error) {
        props.onAddMessage({ title: 'Your message has been sent', type: 'success' });
      } else {
        props.onAddMessage({ title: response.error, type: 'error' });
      }
    });
    handleClose();
  };

  const handleChange = (event, option) => {
    let name;
    let value;
    if (option === null) {
      value = '';
      name = 'country';
    } else if (!option) {
      name = event.target.name ? event.target.name : event.target.getAttribute('name');
      value = event.target.value !== 0 ? event.target.value : event.target.getAttribute('value');
    } else {
      value = option;
      name = 'country';
    }
    inputData[name] = value;
    setInputData({ ...inputData });
  };

  return (
    <DefaultLayout>
      <PSbanner title={bannerText[0]} sub1={bannerText[1]} sub2={bannerText[2]} />
      <BodyWithPadding padding="4%">
        <Text type={size.width < 600 ? 'h6' : 'h4'} align="center">
          {' '}
          How we can help you
        </Text>
        <Text
          type={size.width < 600 ? 'body2' : 'sub2'}
          align="center"
          style={{ marginBottom: '35px' }}
        >
          We design custom 3D interior and exterior spaces ready to use in artsteps which provide a
          truly immersive and digital experience to your audience.
        </Text>
        <Grid
          item
          container
          style={{ margin: 'auto' }}
          xs={8}
          justify="space-between"
          alignItems="center"
          spacing={2}
        >
          {sections}
        </Grid>
      </BodyWithPadding>
      <PrivateComponent.Requestgrid
        container
        justify="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Text type="h5" style={{ fontWeight: 'unset' }} mycolor="white">
            Are you interested about having a <b>Custom 3D Space?</b>
          </Text>
        </Grid>
        <Grid item>
          <PrivateComponent.CustomButton
            onClick={handleOpen}
            onMouseEnter={() => setIcon(true)}
            onMouseLeave={() => setIcon(false)}
            request="true"
          >
            Get a quote
            <PrivateComponent.PlacedArrowIcon open={icon} />
          </PrivateComponent.CustomButton>
        </Grid>
      </PrivateComponent.Requestgrid>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        style={{ overflow: 'auto' }}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <PrivateComponent.ModalGrid container direction="column" justify="center" spacing={2}>
            <Box component={Grid} item textAlign="center">
              <img alt="" src={logo} width="177px" height="59px" />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.CustomTypography variant="h4" modalhead="true">
                Request Details
              </PrivateComponent.CustomTypography>
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="username-input">
                Name
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="username-input"
                onChange={e => handleChange(e)}
                name="username"
                value={inputData.username}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="email-input">
                Email
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="email-input"
                onChange={handleChange}
                name="email"
                value={inputData.email}
                required
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="country-input">
                Country
              </PrivateComponent.InputsLabel>
              <PrivateComponent.CustomAutocomplete
                value={inputData.country}
                onChange={(e, value) => handleChange(e, value)}
                onInputChange={(event, newInputValue) => {
                  setCountryInput(newInputValue);
                }}
                inputValue={countryInput}
                autoComplete
                options={getNames()}
                clearOnEscape
                getOptionSelected={option => option}
                getOptionLabel={option => option}
                renderOption={option => <React.Fragment>{option}</React.Fragment>}
                renderInput={params => (
                  <PrivateComponent.CustomTextField
                    {...params}
                    placeholder="Select"
                    style={{ border: 0 }}
                    required
                  />
                )}
              />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.InputsLabel htmlFor="company-input">
                <PrivateComponent.CustomTypography company="true">
                  Company
                </PrivateComponent.CustomTypography>
                <PrivateComponent.CustomTypography optional="true">
                  (Optional)
                </PrivateComponent.CustomTypography>
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="company-input"
                onChange={handleChange}
                name="company"
                value={inputData.company}
              />
            </Box>
            <Box component={Grid} item textAlign="center" style={{ position: 'relative' }}>
              <PrivateComponent.InputsLabel htmlFor="licence-input">
                Licence
              </PrivateComponent.InputsLabel>
              <PrivateComponent.InputsModal
                id="licence-input"
                aria-controls="licence-input"
                aria-haspopup="true"
                name="licence"
                value={inputData.licence}
                placeholder="Select"
                readOnly
                onClick={handleMenu}
                required
              />
              {openMenu ? <PrivateComponent.UpArrow /> : <PrivateComponent.DownArrow />}
              <StyledMenu
                id="licence-input"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                keepMounted
                open={openMenu}
                onClose={handleCloseMenu}
              >
                {licences.map((licence, index) => (
                  <PrivateComponent.DropDownOptions
                    key={`space${index}`}
                    value={licence}
                    name="licence"
                    onClick={e => {
                      handleChange(e);
                      handleCloseMenu();
                    }}
                  >
                    {licence}
                  </PrivateComponent.DropDownOptions>
                ))}
              </StyledMenu>
            </Box>
            <Box
              component={CloseButton}
              onClick={handleClose}
              visible={size.width < breakPoint ? 'true' : 'false'}
            >
              <CloseIcon />
            </Box>
            <Box component={Grid} item textAlign="center">
              <PrivateComponent.SendButton disabled={isDisabled(inputData)} onClick={handleSubmit}>
                Send your Request
                <SvgIcon>
                  <Send />
                </SvgIcon>
              </PrivateComponent.SendButton>
            </Box>
          </PrivateComponent.ModalGrid>
        </Fade>
      </Modal>
    </DefaultLayout>
  );
};

const lifecycleMap = {
  onDidMount: () => {
    ReactPixel.track('ViewContent');
  },
};

const mapState = state => ({
  currentUser: getAuthUser(state),
  requestOpen: getUIProperty('requestOpen'),
});
const mapDispatch = dispatch => ({
  onCreateContactForm: form => dispatch(apiPOST('privatespaces', form)),
  onAddMessage: message => dispatch(addMessage(message, 'privatespaces')),
  setOpenRequest: status => dispatch(setUIProperty('requestOpen', status)),
});

const CustomSpace3DPage = compose(
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(CustomSpace3DPageView);

export default CustomSpace3DPage;
