import React from 'react';
import { __ } from 'artsteps2-common';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import utils from '../../../utils';
import ExhibitionPreview from './ExhibitionPreview';
import defaultLogo from '../../../styles/images/templates/CreateYourOwn.png';
import Link from '../../generic/Link';
import { ExhibitionCard } from './ExhibitionStyled';

import { HorizontalItems, Text } from '../../../styles/GenericStyled';
import {
  compose,
  withState,
  withDispatch,
  withLifecycle,
  withLocalState,
} from '../../../enhancers';
import { apiGET, apiDELETE, setUIProperty, addMessage } from '../../../actions';
import { getApiResource, getAuthUser, getUIProperty, getAuthToken } from '../../../reducers';

export const ExhibitionView = ({
  exhibition = {},
  ready,
  userId,
  currentUser,
  controls = false,
  usernames = false,
  fetchImageFromExhibitionProfileUser = false,
  imgRefLocalState,
  profilePage = '/profile',
  user,
  categoryListMapped,
  viewPage,
}) => {
  const isUsers = controls && currentUser._id === ((exhibition.user || {})._id || exhibition.user); // The exhibiton is made by the user
  const categoryList = [];

  if (exhibition.categories) {
    for (let i = 0; i < exhibition.categories.length; i += 1) {
      for (let j = 0; j < categoryListMapped.length; j += 1) {
        categoryListMapped[j][exhibition.categories[i]] &&
          categoryList.push(categoryListMapped[j][exhibition.categories[i]]);
      }
    }
  }
  return (
    <ExhibitionCard elevation={0} viewpage={viewPage ? 1 : 0}>
      <CardActionArea style={{ height: '100%' }}>
        <ExhibitionPreview
          ready={ready}
          usernames={usernames}
          isUsers={isUsers}
          user={user}
          userId={(exhibition.user || {})._id || exhibition.user}
          fetchImageFromExhibitionProfileUser={fetchImageFromExhibitionProfileUser}
          exhibition={exhibition}
          categoryList={categoryList}
          imgRefLocalState={imgRefLocalState}
          controls={controls}
          currentUser={currentUser}
          viewPage={viewPage}
        />
        <CardActions style={{ width: '100%', maxWidth: '420px' }}>
          <Text type="h6" title={exhibition.title}>
            {exhibition.title}
          </Text>
        </CardActions>
      </CardActionArea>
    </ExhibitionCard>
  );
};

const mapState = (state, { exhibition = {} }) => ({
  currentUser: getAuthUser(state),
  user: getApiResource(state, `users/${exhibition.user && exhibition.user._id}`), // In the profile view the user isnt an object
  dialogVisible: getUIProperty(state, `exhibitions/${exhibition._id}/dialog`),
  token: getAuthToken(state),
  isPrivateSpace: getUIProperty(state, 'isPrivateSpace'),
});

const mapDispatch = (dispatch, { exhibition = {}, currentUser, follows = [] }) => ({
  onFetchUser: () => dispatch(apiGET(`users/${exhibition.user._id}`)),
  onDeleteExhibition: () => dispatch(apiDELETE(`exhibitionsUserProfile/${exhibition._id}`)),
  onOpenDialog: () => dispatch(setUIProperty(`exhibitions/${exhibition._id}/dialog`, true)),
  onSetExhibitionReady: value =>
    dispatch(setUIProperty(`exhibitions/${exhibition._id}/ready`, value)),
  onCloseDialog: () => dispatch(setUIProperty(`exhibitions/${exhibition._id}/dialog`, false)),
  onAddMessage: message => dispatch(addMessage(message)),
});

const lifecycleMap = {
  onDidMount: ({ exhibition = {}, onFetchUser }) => {
    exhibition.user && exhibition.user._id && onFetchUser();
  },
  onDidUpdate: (props, { exhibition = {}, onFetchUser }) =>
    exhibition.user && exhibition.user._id !== props.exhibition.user._id && onFetchUser(),
  onWillMount: ({
    exhibition,
    token,
    setImgRefLocalState,
    isPrivateSpace,
    fetchImageFromExhibitionProfileUser,
    onSetExhibitionReady,
  }) => {
    const imgRef = React.createRef();
    setImgRefLocalState(imgRef);
    let url = utils.exhibition.getExhibitionImage(exhibition, isPrivateSpace);
    if (fetchImageFromExhibitionProfileUser && isPrivateSpace) {
      url = url.replace('exhibitionsPrivate', 'isPrivateSpace');
    }
    if (fetchImageFromExhibitionProfileUser && !isPrivateSpace) {
      url = url.replace('exhibitions', 'exhibitionsUserProfile');
    }

    setTimeout(() => {
      if (imgRef.current) {
        imgRef.current.src = url;
        imgRef.current.onload = () => onSetExhibitionReady(true);
        imgRef.current.onerror = () => {
          if (imgRef.current) imgRef.current.src = defaultLogo;
        };
      }
    }, 0);
  },
};
const Exhibition = compose(
  withLocalState('imgRefLocalState', 'setImgRefLocalState', null),
  withState(mapState),
  withDispatch(mapDispatch),
  withLifecycle(lifecycleMap),
)(ExhibitionView);
export default Exhibition;
