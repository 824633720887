import React from 'react';
import { __ } from 'artsteps2-common';
import { compose, withState, withDispatch } from '../../enhancers';
import { setLocation } from '../../actions';
import { API_KEYS, getAuthUser } from '../../reducers';
import Pager from '../generic/lists/Pager';
import ExhibitionList from '../exhibitions/lists/ExhibitionList';
import UserCommentList from '../comments/lists/UserCommentList';
import FollowList from '../follows/lists/FollowList';

const LIMIT = 12;

const UserPanelView = ({
  userId,
  currentUser,
  tab = 'exhibitions',
  openTab = Promise.resolve(false),
}) => (
  <div
    style={{
      paddingRight: '20px',
      paddingTop: '80px',
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    }}
    className="user-panel"
  >
    <div
      className="ui secondary pointing menu stackable"
      style={{ align: 'center', marginLeft: 'auto', marginRight: 'auto' }}
    >
      <button
        className={`anchor item ${tab === 'exhibitions' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('exhibitions')}
        onClick={() => openTab('exhibitions')}
        style={{
          align: 'center',
          marginLeft: 'auto',
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor:
            tab === 'exhibitions' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        Exhibitors
      </button>
      <button
        className={`anchor item ${tab === 'liked' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('liked')}
        onClick={() => openTab('liked')}
        style={{
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor: tab === 'liked' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('liked')}
      </button>
      <button
        className={`anchor item ${tab === 'comments' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('comments')}
        onClick={() => openTab('comments')}
        style={{
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor: tab === 'comments' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('comments')}
      </button>
      <button
        className={`anchor item ${tab === 'followers' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('followers')}
        onClick={() => openTab('followers')}
        style={{
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor:
            tab === 'followers' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('followers')}
      </button>
      <button
        className={`anchor item ${tab === 'following' ? 'active' : null}`}
        onKeyPress={event => event.keyCode === 13 && openTab('following')}
        onClick={() => openTab('following')}
        style={{
          align: 'center',
          marginRight: 'auto',
          font: 'normal normal bold 18px/23px Muli',
          backgroundColor:
            tab === 'following' ? 'rgba(243, 243, 243, 1)' : 'rgba(255, 255, 255, 0)',
        }}
      >
        {__('following')}
      </button>
    </div>
    {tab === 'exhibitions' && (
      <div className="tab-body">
        <ExhibitionList
          wide={false}
          filter={{ user: userId }}
          limit={LIMIT}
          include={[
            'description',
            'categories',
            `views.${API_KEYS.META}.totalCount`,
            `likes.${API_KEYS.META}.totalCount`,
            'visits',
            'rating',
            '_v1Rating',
          ]}
          populate="user"
          controls={currentUser && userId === currentUser._id}
          currentUser={currentUser}
          userId={userId}
        />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection={
            currentUser && userId === currentUser._id ? 'exhibitionsUserProfile' : 'exhibitions'
          }
          query={{ filter: { user: userId } }}
        />
      </div>
    )}
    {tab === 'liked' && (
      <div className="tab-body">
        <ExhibitionList
          include={[
            'description',
            'categories',
            `views.${API_KEYS.META}.totalCount`,
            `likes.${API_KEYS.META}.totalCount`,
            'visits',
            'rating',
            '_v1Rating',
          ]}
          usernames
          wide={false}
          filter={{ followers: userId }}
          limit={LIMIT}
          populate="user"
          controls={currentUser && userId === currentUser._id}
        />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="exhibitions"
          query={{ filter: { followers: userId }, populate: 'user' }}
        />
      </div>
    )}
    {tab === 'comments' && (
      <div className="tab-body">
        <UserCommentList userId={userId} />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="comments"
          query={{ filter: { user: userId }, populate: 'user' }}
        />
      </div>
    )}
    {tab === 'followers' && (
      <div className="tab-body">
        <FollowList userId={userId} />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="follows"
          query={{ filter: { user: userId }, populate: 'follower' }}
        />
      </div>
    )}
    {tab === 'following' && (
      <div className="tab-body">
        <FollowList followerId={userId} />
        <Pager
          pageSize={LIMIT}
          labelMore={__('see_more')}
          collection="follows"
          query={{ filter: { follower: userId }, populate: 'user' }}
        />
      </div>
    )}
  </div>
);

const mapState = state => ({
  currentUser: getAuthUser(state),
});

const mapDispatch = (dispatch, { profilePath }) => ({
  openTab: tab => dispatch(setLocation(`${profilePath}/${tab}`)),
});

const UserPanel = compose(withState(mapState), withDispatch(mapDispatch))(UserPanelView);

export default UserPanel;
