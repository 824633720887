import React from 'react';

import DefaultLayout from '../components/layouts/DefaultLayout';
import ExhibitionEditFrame from '../components/exhibitions/editor/ExhibitionEditFrame';
import ExhibitionMeta from '../components/exhibitions/viewer/ExhibitionMeta';

const ExhibitionCuratePage = ({ exhibitionId, step }) => (
  <DefaultLayout exhibitionId={exhibitionId}>
    <ExhibitionMeta exhibitionId={exhibitionId} />
    <ExhibitionEditFrame step={Number(step)} exhibitionId={exhibitionId} />
  </DefaultLayout>
);

export default ExhibitionCuratePage;
