import React, { useEffect } from 'react';
import { __ } from 'artsteps2-common';
import i18nextInstance from 'artsteps2-common';
import Grid from '@material-ui/core/Grid';
import { getUIProperty, getApiResource } from '../../reducers';
import { compose, withState, withDispatch } from '../../enhancers';
import { setUIProperty } from '../../actions';
import * as header from '../../styles/styledComponents/header';
import * as generic from '../../styles/GenericStyled';
import { Link } from 'react-router-dom';

const creatorSteps = path =>
  [
    {
      title: __('define'),
      text: __('your_space'),
      path,
    },
    {
      title: __('design_your_space'),
      text: __('your_space'),
      path,
    },
    {
      title: __('add_and_place'),
      text: __('your_artifacts'),
      path,
    },
    {
      title: __('plan'),
      text: __('guided_tour'),
      path,
    },
    {
      title: __('provide'),
      text: __('public_information'),
      path,
    },
  ].filter((step, idx) => localStorage.getItem('isKlimentini') || !(idx === 0 || idx === 3));

const ExhibitionStepsView = ({
  exhibitionId,
  hasChanges,
  onExhibitionExport,
  currentStep = 1,
  selectedTemplate = '',
}) => {
  useEffect(() => {
    if (hasChanges) onExhibitionExport();
  }, [currentStep]);
  return (
    <header.CustomMadeTabs value={currentStep - 1} aria-label="simple tabs example">
      {creatorSteps(exhibitionId).map((link, index) => (
        <header.BreadcrumpTab
          language={i18nextInstance.language}
          component={Link}
          key={`${index}breadcrumps`}
          selected={currentStep - 1}
          label={
            <Grid container direction="column">
              <header.BreadcrumpSublabel>
                <generic.Text type="h7">{`${index + 1}. ${link.title}`}</generic.Text>
              </header.BreadcrumpSublabel>
              <header.BreadcrumpSublabel text="true">
                <generic.Text type="body2">{link.text}</generic.Text>
              </header.BreadcrumpSublabel>
            </Grid>
          }
          to={`/curate/${link.path}/${index + 1}`}
        />
      ))}
    </header.CustomMadeTabs>
  );
};

const mapState = (state, { exhibitionId = 'new' }) => ({
  usersExhibition: getApiResource(state, `exhibitionsUserProfile/${exhibitionId}`),
  currentStep: getUIProperty(state, `exhibitions/${exhibitionId}/currentStep`),
  selectedTemplate: getUIProperty(state, `exhibitions/${exhibitionId}/selectedTemplate`),
  hasChanges: getUIProperty(state, `exhibitions/${exhibitionId}/hasChanges`),
});

const mapDispatch = (dispatch, { exhibitionId = 'new' }) => ({
  onExhibitionExport: () =>
    dispatch(setUIProperty(`exhibitions/${exhibitionId}/exportingAt`, Date.now())),
});

const ExhibitionSteps = compose(
  withState(mapState),
  withDispatch(mapDispatch),
)(ExhibitionStepsView);

export default ExhibitionSteps;
