import React from 'react';
import MetaTags from '../components/layouts/MetaTags';
import DefaultLayout from '../components/layouts/DefaultLayout';
import Chatbot from '../components/generic/Chatbot';

const ChatbotPage = () => (
  <DefaultLayout>
    <MetaTags />
    <div className="ui main container">
      <Chatbot />
    </div>
  </DefaultLayout>
);

export default ChatbotPage;
